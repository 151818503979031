import {SvgIconProps} from '@material-ui/core';
import {CropType} from 'modules/crops/models/CropType';
import React from 'react';
import BarleyIcon from './BarleyIcon';
import CornIcon from './CornIcon';
import DefaultCropIcon from './DefaultIcon';
import LucerneIcon from './LucerneIcon';
import RapeIcon from './RapeIcon';
import SoyIcon from './SoyIcon';
import SunflowerIcon from './SunflowerIcon';
import TomatoIcon from './TomatoIcon';
import VineIcon from './VineIcon';
import WheatIcon from './WheatIcon';

interface CropIconProps extends SvgIconProps {
  crop: CropType | null;
}

/**
 * Switcher for crop icon depending on icon type
 * @param props The crop type
 */
const CropIcon = (props: CropIconProps) => {
  if (props.crop) {
    switch (props.crop) {
      case CropType.BARLEY:
        return (
          <BarleyIcon  {...props} />
        );
      case CropType.CORN:
        return (
          <CornIcon {...props} />
        );
      case CropType.LUCERNE:
        return (
          <LucerneIcon {...props} />
        );
      case CropType.RAPE:
        return (
          <RapeIcon {...props} />
        );
      case CropType.SOY:
        return (
          <SoyIcon {...props} />
        );
      case CropType.SUNFLOWER:
        return (
          <SunflowerIcon {...props} />
        );
      case CropType.TOMATO:
        return (
          <TomatoIcon {...props} />
        );
      case CropType.VINE:
        return (
          <VineIcon {...props} />
        );
      case CropType.WHEAT:
        return (
          <WheatIcon {...props} />
        );
    }
  }
  return (
    <DefaultCropIcon {...props} />
  );
};

export default CropIcon;
