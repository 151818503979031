import Subscription from "../models/Subscription";
import {SelectedSubscriptionState} from "../models/SubscriptionsState";
import WagoSubscription from "../models/WagoSubscription";

export const LOAD_SUBSCRIPTIONS_LISTING = 'LOAD_SUBSCRIPTIONS_LISTING';
export interface LoadSubscriptionsListingAction {
    type: typeof LOAD_SUBSCRIPTIONS_LISTING
}

export const LOAD_SUBSCRIPTIONS_LISTING_SUCCESS = 'LOAD_SUBSCRIPTIONS_LISTING_SUCCESS';
export interface LoadSubscriptionsListingSuccessAction {
    type: typeof LOAD_SUBSCRIPTIONS_LISTING_SUCCESS
    payload: {
        data: {[key: string]: Subscription} | null,
        wagoData: {[key: string]: WagoSubscription} | null
    }
}

export const LOAD_INVOICES_INFO = 'LOAD_INVOICES_INFO';
export interface LoadInvoicesInfoAction {
    type: typeof LOAD_INVOICES_INFO,
    payload: string
}

export const LOAD_INVOICES_INFO_SUCCESS = 'LOAD_INVOICES_INFO_SUCCESS';
export interface LoadInvoicesSuccessAction {
    type: typeof LOAD_INVOICES_INFO_SUCCESS,
    payload: SelectedSubscriptionState
}

export type SubscriptionsActionTypes = LoadSubscriptionsListingAction | LoadSubscriptionsListingSuccessAction | LoadInvoicesInfoAction | LoadInvoicesSuccessAction;

export const actions = {
    loadSubscriptions: (): LoadSubscriptionsListingAction => {
        return {
            type: LOAD_SUBSCRIPTIONS_LISTING
        }
    },
    loadSubscriptionsSuccess: (subscriptions: {[key: string]: Subscription}, wagoSubscriptions: {[key: number]: WagoSubscription}): LoadSubscriptionsListingSuccessAction => {
        return {
            type: LOAD_SUBSCRIPTIONS_LISTING_SUCCESS,
            payload: {
                data: subscriptions,
                wagoData: wagoSubscriptions,
            }
        }
    },
    loadInvoicesInfo: (id: string): LoadInvoicesInfoAction => {
        return {
            type: LOAD_INVOICES_INFO,
            payload: id
        }
    },
    loadInvoicesInfoSuccess: (invoices: SelectedSubscriptionState): LoadInvoicesSuccessAction => {
        return {
            type: LOAD_INVOICES_INFO_SUCCESS,
            payload: invoices
        }
    }

}