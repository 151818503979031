import {Theme} from '@material-ui/core';
import {HydroStressLevel} from 'modules/waterBalance/models/HydroStressLevel';
import {getHydroStressLevelColor} from 'modules/waterBalance/services/WaterBalanceService';
import {LayerProps} from 'react-map-gl'

export const ZOOM_SWITCH = 13.75;

const stressLevelColorChoice = function (theme: Theme): mapboxgl.Expression {
    return [
        'case',
        ['==', ['get', 'hydro_stress_level'], HydroStressLevel.HIGH],
        getHydroStressLevelColor(HydroStressLevel.HIGH, theme),
        ['==', ['get', 'hydro_stress_level'], HydroStressLevel.MEDIUM],
        getHydroStressLevelColor(HydroStressLevel.MEDIUM, theme),
        ['==', ['get', 'hydro_stress_level'], HydroStressLevel.LOW],
        getHydroStressLevelColor(HydroStressLevel.LOW, theme),
        getHydroStressLevelColor(HydroStressLevel.UNDEFINED, theme)
    ];
};

/**
 * Generate map polygon style using theme colors
 * For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
 * @param theme The app theme
 */
export const generateFieldMapPolygonStyle = function (theme: Theme): LayerProps {
    return {
        id: 'polygons',
        type: 'fill',
        paint: {
            'fill-color': stressLevelColorChoice(theme),
            'fill-opacity': [
                'case',
                ['==', ['get', 'hovered'], true],
                1,
                ['==', ['get', 'selected'], true],
                1,
                0.5
            ],
            'fill-outline-color': theme.palette.primary.main,
        },
        minzoom: ZOOM_SWITCH
    };
}

/**
 * Generate map bbox style using theme colors
 * @param theme The app theme
 */
export const generateFieldMapBboxStyle = function (theme: Theme): LayerProps {
    return {
        id: 'bbox',
        type: 'line',
        paint: {
            'line-color': theme.palette.background.default,
        }
    };
}

/**
 * Generate map polygon style using theme colors
 * For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
 * @param theme The app theme
 */
export const generateImportFieldsPolygonStyle = function (theme: Theme): LayerProps {
    return {
        id: 'polygons',
        type: 'fill',
        paint: {
            'fill-color': theme.palette.primary.main,
            'fill-opacity': 1,
            'fill-outline-color': theme.palette.primary.main,
        },
        minzoom: ZOOM_SWITCH
    };
}

/**
 * Generate map symbol style using theme colors
 * @param theme The app theme
 */
export const generateFieldMapSymbolStyle = function (theme: Theme): LayerProps {
    return {
        id: 'points',
        type: 'symbol',
        paint: {
            'icon-color': stressLevelColorChoice(theme),
        },
        layout: {
            'icon-image': 'map-pin',
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
        },
        maxzoom: ZOOM_SWITCH
    }
};

/**
 * Generate map symbol style using theme colors
 * @param theme The app theme
 */
export const generateImportFieldSymbolStyle = function (theme: Theme): LayerProps {
    return {
        id: 'points',
        type: 'symbol',
        paint: {
            'icon-color': theme.palette.primary.main,
        },
        layout: {
            'icon-image': 'map-pin',
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
        },
        maxzoom: ZOOM_SWITCH
    }
};
