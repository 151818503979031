import {call, put, takeEvery, all, delay} from 'redux-saga/effects';
import {
  LOAD_SOILS_LISTING,
  ADD_NEW_SOIL,
  DELETE_SOIL,
  MODIFY_SOIL,
  AddNewSoilAction,
  DeleteSoilAction,
  ModifySoilAction,
} from '../actions/SoilsActions';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from 'shared/actions/Common';
import api, {wagoApiUrl} from 'shared/services/ApiConfig';
import SoilModel from '../models/SoilModel';
import actions from '../actions/SoilsActions';
import {timeoutDuration} from 'shared/configs/AppConst';
import {computeUsableReserve} from '../services/usableReserveService';

function getSoilsListingRequest() {
  const soilsUrl = `${wagoApiUrl}/soils`;
  return api.get(soilsUrl);
}

function* loadSoilsListing() {
  try {
    yield put(fetchStart("loadSoilsListing"));
    const res = yield call(getSoilsListingRequest);
    const soilsListing = res.data as SoilModel[];
    const soilsMap: {[key: string]: SoilModel} = {};
    soilsListing.forEach((value) => {
      //temp no present in api response delete when this values will be correctly treated or when api will support this values
      value.depth = value.depth ? value.depth : '60';
      value.pebble_perc = value.pebble_perc ? value.pebble_perc : '0.1'; // doubt of saving format ? 0.1 or 10 (%)
      //temp
      computeUsableReserve(value);
      if (value.soil_id !== undefined) {
        soilsMap[value.soil_id] = value;
      }
    });
    yield put(actions.loadSoilsListingSuccessAction(soilsMap));
    yield put(fetchSuccess("loadSoilsListing"));
  } catch (error) {
    yield put(fetchError("loadSoilsListing", error.message));
  }
}

function addNewSoilRequest(newSoil: SoilModel) {
  const soilUrl = `${wagoApiUrl}/soils`;
  return api.post(soilUrl, newSoil);
}

function* addNewSoil(action: AddNewSoilAction) {
  try {
    yield put(fetchStart("addNewSoil"));
    const newSoil: SoilModel = action.payload;
    yield call(addNewSoilRequest, newSoil);
    yield put(fetchSuccess("addNewSoil"));

    yield call(reloadSoilsListingAfterDelay);
  } catch (error) {
    yield put(fetchError("addNewSoil", error.message));
  }
}

function deleteSoilRequest(selectedSoil: number) {
  const soilUrl = `${wagoApiUrl}/soils/${selectedSoil}`;
  return api.delete(soilUrl);
}

function* deleteSoil(action: DeleteSoilAction) {
  try {
    yield put(fetchStart("deleteSoil"));
    yield call(deleteSoilRequest, action.payload);
    yield put(fetchSuccess("deleteSoil"));

    yield call(reloadSoilsListingAfterDelay);
  } catch (error) {
    yield put(fetchError("deleteSoil", error.message));
  }
}

function modifySoilRequest(modifiedSoil: SoilModel) {
  const selectedSoil: number | undefined = modifiedSoil.soil_id;
  const soilUrl = `${wagoApiUrl}/soils/${selectedSoil}`;
  return api.put(soilUrl, modifiedSoil);
}

function* modifySoil(action: ModifySoilAction) {
  try {
    yield put(fetchStart("modifySoil"));
    yield call(modifySoilRequest, action.payload);
    yield put(fetchSuccess("modifySoil"));

    yield call(reloadSoilsListingAfterDelay);
  } catch (error) {
    yield put(fetchError("modifySoil", error.message));
  }
}

function* reloadSoilsListingAfterDelay(delayMultiplier: number = 1) {
  // Wait for some times
  yield delay(timeoutDuration * delayMultiplier);

  //reload crops
  yield put(actions.loadSoilsListing());
}

export function* soilsSagas() {
  yield all([
    takeEvery(LOAD_SOILS_LISTING, loadSoilsListing),
    takeEvery(ADD_NEW_SOIL, addNewSoil),
    takeEvery(DELETE_SOIL, deleteSoil),
    takeEvery(MODIFY_SOIL, modifySoil),
  ]);
}