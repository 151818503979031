import IntlMessages from '@crema/utility/IntlMessages';
import {Box, Typography} from '@material-ui/core';
import React from 'react';
import useStyles from './Informations.style';
import ArrowSoilsIcon from 'shared/components/Icons/ArrowSoils';



export default function Subscriptions() {
    const classes = useStyles();
    return (
        <Box display='flex' flexDirection='column' height="100%" width="100%">
            <Typography variant='h4' className={classes.dialogSubtitle}>
                <IntlMessages id='informations.subscriptions.title' />
            </Typography>
            <Box display='flex' justifyContent='space-between' alignItems='center' flexGrow={1}>
                <Box display='flex' flexDirection='column' alignItems='center' width={'40%'}>
                    <Typography className={[classes.textBold, classes.subscriptionText].join(' ')} >
                        <IntlMessages id='informations.subscriptions.text.free_trial' />
                    </Typography>
                    <Typography className={[classes.textBold, classes.subscriptionText].join(' ')} >
                        <IntlMessages id='informations.subscriptions.text.processus' />
                    </Typography>
                    <Typography variant='subtitle1' className={[classes.textBold, classes.subscriptionText].join(' ')}>
                        <IntlMessages id='informations.subscriptions.text.start_with' />
                    </Typography>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='center' flexGrow={1} className={classes.positionRelative}>
                    <Box display='flex' width={'50%'} className={classes.arrowAndText}>
                        <IntlMessages id="informations.subscriptions.our_subscriptions" />
                        <ArrowSoilsIcon
                            color='primary'
                            fontSize='large'
                            classes={{fontSizeLarge: classes.svgArrowMedium}}
                        />
                    </Box>
                    {/** TODO: Do a screenshot, image not good */}
                    <img
                        src={require('../../../assets/images/subscriptions/subscriptionOverview.jpg')}
                        style={{
                            width: '450px'
                        }}
                        alt="subscriptions"
                    />

                </Box>
            </Box>
        </Box>
    )
}