import {
  SoilsActionTypes,
  LOAD_SOILS_LISTING,
  LOAD_SOILS_LISTING_SUCCESS,
} from '../actions/SoilsActions';
import SoilState from '../models/SoilsState';
import {Reducer} from 'redux';
import SoilsState from '../models/SoilsState';

const initialState: SoilState = {
  data: {},
};

const soilsReducers: Reducer<SoilState, SoilsActionTypes> = (
  state: SoilsState = initialState,
  action: SoilsActionTypes,
) => {
  switch (action.type) {
    case LOAD_SOILS_LISTING:
      return {
        ...state,
      };
    case LOAD_SOILS_LISTING_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default soilsReducers;
