import React, {ReactNode, useContext, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {setInitialPath} from 'shared/actions/Setting';
import {matchRoutes} from 'react-router-config';
import AppContext from '../../@crema/utility/AppContext';
import {useAuthToken} from './AppHooks';
import Loader from '../components/Loader';
import {AppState} from '../store';
import AppContextPropsType from '../models/AppContextPropsType';
import {initialUrl} from 'shared/configs/AppConst';

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {routes} = useContext<AppContextPropsType>(AppContext);

  const [token, loading] = useAuthToken();
  const {initialPath} = useSelector<AppState, AppState['settings']>(
    ({settings}) => settings,
  );
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  useEffect(() => {
    function setInitPath() {
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/forget-password',
        ].indexOf(pathname) === -1
      ) {
        dispatch(setInitialPath(pathname+search));
      }
    }

    setInitPath();
  }, [dispatch, initialPath, loading, pathname, token, search]);

  useEffect(() => {
    if (!loading) {
      if (!token && currentRoute.auth && currentRoute.auth.length >= 1) {
        history.push('/signin');
      } else if (
        (pathname === '/signin' ||
          pathname === '/signup' ||
          pathname === '/confirm-signup' ||
          pathname === '/reset-password' ||
          pathname === '/forget-password') &&
        token
      ) {
        // @ts-ignore
        if (pathname === '/') {
          history.push(initialUrl);
        }
        // @ts-ignore
        else if (initialPath !== '/signin') {
          history.push(initialPath);
        } else {
          history.push(initialUrl);
        }
      }
    }
  }, [token, loading, pathname, initialPath, currentRoute.auth, history, search]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;
