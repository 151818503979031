import {
  SysIrrigationTypes,
  LOAD_SYS_IRRIGATION,
  LOAD_SYS_IRRIGATION_SUCCESS
} from '../actions/SysIrrigationActions';
import SysIrrigationState from '../models/SysIrrigationState';
import {Reducer} from 'redux';

const initialState: SysIrrigationState = {
  data: {},
};

const sysIrrigationReducers: Reducer<SysIrrigationState, SysIrrigationTypes> = (
  state: SysIrrigationState = initialState,
  action: SysIrrigationTypes,
) => {
  switch (action.type) {
    case LOAD_SYS_IRRIGATION:
      return {
        ...state,
      };
    case LOAD_SYS_IRRIGATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default sysIrrigationReducers;