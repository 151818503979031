import log from 'shared/services/LogService';
import Field from '../models/Field';
import FieldsState from '../models/FieldsState';

const SQUARE_METER_TO_HA = 0.0001;

export const replaceFieldId = function (url: string, fieldId: number): string {
    return url.replace(/(.*\/fields)\/?(\d*)?(\/.*)?/, `$1/${fieldId}$3`);
}

export const removeDuplicates = function (noDuplicates: FieldsState['campaigns']) {
    return noDuplicates = noDuplicates?.filter((campaign, index: any, self: any[]) => self.findIndex((t) => t.campaign_id === campaign.campaign_id) === index) as FieldsState['campaigns'];
}

/**
 * Compute the area in Ha
 * (Floor to int)
 * @param selectedFields
 * @returns 
 */
export const computeAreaHa = (field: Field, floor: boolean): number => {
    if (field.area) {
        let areaHa = field.area * SQUARE_METER_TO_HA;
        if (floor) {
            areaHa = Math.floor(areaHa);
        }
        if (areaHa === 0) {
            log.debug(`areaHa forced to 1 beacause floor areaHA return 0 with areaHa of ${field.area * SQUARE_METER_TO_HA}`)
            return 1
        } else {
            return areaHa;
        }
    } else {
        log.error(`Field ${field.field_id} does not have a filled area`);
    }
    return 0;
}

/**
 * Compute the total area in Ha
 * (Floor to int)
 * @param selectedFields
 * @returns 
 */
export const computeTotalAreaHa = (selectedFields: Field[]): number => {
    let count: number = 0
    selectedFields.forEach(field => {
        count += computeAreaHa(field, false);
    })
    count = Math.floor(count);
    if (count === 0 && selectedFields.length) {
        count = 1
    }
    return count;
}