import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import IntlMessages from 'shared/components/IntlMessages';
import {Card, Box, CardProps, Typography} from '@material-ui/core';
import {HydroStressLevel} from '../models/HydroStressLevel';
import {getHydroStressLevelColor, getHydroStressLevelMessageId} from '../services/WaterBalanceService';


const useStyles = makeStyles((theme) => ({
    levelColor: {
        height: 10,
        width: 30,
        marginRight: theme.spacing(2)
    }
}));

interface HydroStressLegendProps extends CardProps { }

/**
 * Component with the NDVI legend gradient and card around it
 * @param props 
 */
const HydroStressLegend: React.FC<HydroStressLegendProps> = (props) => {

    const theme = useTheme();
    const styles = useStyles();

    const levelValues = [HydroStressLevel.HIGH, HydroStressLevel.MEDIUM, HydroStressLevel.LOW, HydroStressLevel.UNDEFINED];
    return (
        <Card {...props}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center">
                <h5><IntlMessages id="waterBalance.SWC2Risk.label" /></h5>
                <Box pt={2} width={1} display="flex" flexDirection="row" alignItems="center">
                    {
                        levelValues.map(levelValue => {
                            return (
                                <Box flex={1} display="flex" flexDirection="row" alignItems="center" key={levelValue}>
                                    <div className={styles.levelColor} style={{background: getHydroStressLevelColor(levelValue, theme)}} />
                                    <Typography variant="caption"><IntlMessages id={getHydroStressLevelMessageId(levelValue)} /></Typography>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </Card>
    );
}

export default HydroStressLegend;
