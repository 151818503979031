import CropModel from '../models/CropModel';

export const LOAD_CROPS_LISTING = 'LOAD_CROPS_LISTING';
export interface LoadCropsListingAction {
  type: typeof LOAD_CROPS_LISTING;
}

export const LOAD_CROPS_LISTING_SUCCESS = 'LOAD_CROPS_LISTING_SUCCESS';
export interface LoadCropsListingSuccessAction {
  type: typeof LOAD_CROPS_LISTING_SUCCESS;
  payload: {[key: string]: CropModel};
}

export const ADD_NEW_CROP = 'ADD_NEW_CROP';
export interface AddNewCropAction {
  type: typeof ADD_NEW_CROP;
  payload: CropModel;
}

export const DELETE_CROP = 'DELETE_CROP';
export interface DeleteCropAction {
  type: typeof DELETE_CROP;
  payload: number;
}

export const MODIFY_CROP = 'MODIFY_CROP';
export interface ModifyCropAction {
  type: typeof MODIFY_CROP;
  payload: CropModel;
}

export type CropsActionTypes =
  | LoadCropsListingAction
  | LoadCropsListingSuccessAction
  | AddNewCropAction
  | DeleteCropAction
  | ModifyCropAction;

export const actions = {
  loadCropsListing: (): LoadCropsListingAction => {
    return {
      type: LOAD_CROPS_LISTING,
    };
  },

  //TO DO: get only the id_agriculteur: null | user 's Crops
  loadCropsListingSuccessAction: (crops: {
    [key: string]: CropModel;
  }): LoadCropsListingSuccessAction => {
    return {
      type: LOAD_CROPS_LISTING_SUCCESS,
      payload: crops,
    };
  },

  addNewCrop: (newCrop: CropModel): AddNewCropAction => {
    return {
      type: ADD_NEW_CROP,
      payload: newCrop,
    };
  },

  deleteCrop: (selectedCrop: number): DeleteCropAction => {
    return {
      type: DELETE_CROP,
      payload: selectedCrop,
    };
  },

  modifyCrop: (modifiedCrop: CropModel): ModifyCropAction => {
    return {
      type: MODIFY_CROP,
      payload: modifiedCrop,
    };
  },
};

export default actions;
