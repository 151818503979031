import {makeStyles} from '@material-ui/core';
import {CremaTheme} from 'shared/models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
    return {
        flexColumnStretch: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
        },
        selectedButton: {
            border: `solid 3px ${theme.palette.primary.main}`
        },
        unSelectedButton: {
            border: `solid 1px ${theme.palette.grey[100]}`
        },
        buttonBase: {
            borderRadius: '14px',
            width: '200px',
            minHeight: '225px',
            margin: theme.spacing(8)
        },
        cardHeader: {
            borderRadius: '10px 10px 0 0',
            padding: theme.spacing(7, 0, 7, 0),
            backgroundColor: theme.palette.primary.main,
        },
        productName: {
            color: theme.palette.common.white,
            fontWeight: 'bolder'
        },
        productPrice: {
            fontWeight: 'bolder',
            color: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        cardContent: {
            borderRadius: '0 0 10px 10px',
            flexGrow: 1,
            padding: theme.spacing(2, 1, 2, 1),
            backgroundColor: theme.palette.grey[100],
            justifyContent: 'flex-end',
        },
        productStep: {
            height: "100%",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        productCardContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'stretch',
            flexWrap: 'wrap'
        },
        productCard: {
            borderRadius: '10px',
            fontWeight: 'bold',
            backgroundColor: theme.palette.grey[100],
            padding: theme.spacing(3, 3, 3, 3),
            minWidth: '100px',
            minHeight: '100px'
        },
        cardElement: {
            padding: theme.spacing(2, 0, 2, 0)
        },
        maxQuantity: {
            fontWeight: 'bold'
        },
        iconButton: {
            padding: theme.spacing(0, 0, 0, 1)
        },
        tooltip: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey[100]}`,
            borderRadius: '10px',
        },
        tooltipTable: {
            width: '170px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        tooltipList: {
            width: '100%'
        },
        tooltipTitle: {
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'flex-start'
        },
        tooltipTitleTypo: {
            color: theme.palette.primary.main,
            fontSize: '12px',
            fontWeight: 'bold'
        },
        tooltipListItem: {
            display: 'flex',
            width: '100%',
            padding: 0
        },
        tooltipListItemText: {
            width: '50%',
            textAlign: 'center'
        },
        tooltipBoldTypo: {
            fontSize: '12px',
            fontWeight: 'bold',
        },
        primaryColor: {
            color: theme.palette.primary.main
        },
        blackColor: {
            color: '#000000'
        },
        tooltipUnderlineTypo: {
            fontSize: '10px',
            textDecoration: 'underline'
        },
        disabled: {
            filter: 'contrast(0.5)'
        }

    }
})

export default useStyles