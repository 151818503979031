import {Theme} from "@material-ui/core";
import log from "shared/services/LogService";
import {AuthUser} from "modules/auth/models/AuthUser";
import {HydroStressLevel} from "../models/HydroStressLevel";
import {NdviHomogeneityLevel} from "../models/NdviHomogeneityLevel";


export const DEFAULT_SWC2_THRESHOLD_HIGH = 0.9;
export const DEFAULT_SWC2_THRESHOLD_MEDIUM = 0.8;

export const DEFAULT_NDVI_STD_THRESHOLD_HETEROGENEOUS = 0.05;
export const DEFAULT_NDVI_STD_THRESHOLD_HIGHLY_HETEROGENEOUS = 0.1;

/**
 * Get the risk level given the swc2 value
 * @param swc2 the swc2 value
 * @param user the user info with the thresholds
 * @returns The risk level (enum)
 */
export function getHydroStressLevel(rawc: string | null, user: AuthUser | null): HydroStressLevel {

    if (rawc && rawc !== "") {
        const rawcNum = parseFloat(rawc);
        if (!isNaN(rawcNum)) {
            let threshold_high = DEFAULT_SWC2_THRESHOLD_HIGH;
            let threshold_medium = DEFAULT_SWC2_THRESHOLD_MEDIUM;
            if (user) {
                if (user.threshold_high) {
                    threshold_high = user.threshold_high;
                }
                if (user.threshold_medium) {
                    threshold_medium = user.threshold_medium;
                }
            }
            if (rawcNum >= threshold_high) {
                return HydroStressLevel.HIGH;
            } else if (rawcNum >= threshold_medium) {
                return HydroStressLevel.MEDIUM;
            } else {
                return HydroStressLevel.LOW;
            }
        } else {
            log.error(`Invalid RAWC value: ${rawc}`);
        }
    }
    return HydroStressLevel.UNDEFINED;
}

/**
 * Get the id of the localized message for the risk level
 * @param rawc the rawc value
 * @param user the user info with the thresholds
 * @returns The id of the localized message for the risk level
 */
export function getHydroStressMessageId(rawc: string | null, user: AuthUser | null): string {

    const riskLevel = getHydroStressLevel(rawc, user);
    return getHydroStressLevelMessageId(riskLevel);
};

/**
 * Get the id of the localized message for the risk level
 * @param stressLevel the risk level
 * @returns The id of the localized message for the risk level
 */
export function getHydroStressLevelMessageId(stressLevel: HydroStressLevel): string {
    switch (stressLevel) {
        case HydroStressLevel.HIGH:
            return 'waterBalance.SWC2HighRisk.label';
        case HydroStressLevel.MEDIUM:
            return 'waterBalance.SWC2MediumRisk.label';
        case HydroStressLevel.LOW:
            return 'waterBalance.SWC2LowRisk.label';
        default:
            return 'common.unknown';
    }
}

export function getHydroStressLevelColor(stressLevel: HydroStressLevel, theme: Theme): string {

    switch (stressLevel) {
        case HydroStressLevel.HIGH:
            return theme.palette.error.main;
        case HydroStressLevel.MEDIUM:
            return theme.palette.warning.main;
        case HydroStressLevel.LOW:
            return theme.palette.success.main;
        default:
            return theme.palette.grey[400];
    }
};



/**
 * Get the homogeneity level given the ndvi standard deviation value
 * @param ndviStd the ndvi standard deviation value
 * @returns The homogeneity level (enum)
 */
export function getNdviHomogeneityLevel(ndviStd: string): NdviHomogeneityLevel {

    if (ndviStd && ndviStd !== "") {
        const ndviStdNum = parseFloat(ndviStd);
        if (!isNaN(ndviStdNum)) {
            if (ndviStdNum <= DEFAULT_NDVI_STD_THRESHOLD_HETEROGENEOUS) {
                return NdviHomogeneityLevel.HOMOGENEOUS;
            } else if (ndviStdNum <= DEFAULT_NDVI_STD_THRESHOLD_HIGHLY_HETEROGENEOUS) {
                return NdviHomogeneityLevel.HETEROGENEOUS;
            } else {
                return NdviHomogeneityLevel.HIGHLY_HETEROGENEOUS;
            }
        }

    }
    return NdviHomogeneityLevel.UNDEFINED;
}

/**
 * Get the id of the localized message for the ndvi homogeneity level
 * @param ndviStd the ndvi standard deviation value
 * @returns The id of the localized message for the ndvi homogeneity level
 */
export function getNdviHomogeneityMessageId(ndviStd: string): string {

    const homogeneityLevel = getNdviHomogeneityLevel(ndviStd);
    switch (homogeneityLevel) {
        case NdviHomogeneityLevel.HIGHLY_HETEROGENEOUS:
            return 'waterBalance.NdviHighlyHeterogeneous.label';
        case NdviHomogeneityLevel.HETEROGENEOUS:
            return 'waterBalance.NdviHeterogeneous.label';
        case NdviHomogeneityLevel.HOMOGENEOUS:
            return 'waterBalance.NdviHomogeneous.label';
        default:
            return 'common.notAvailable';
    }
};