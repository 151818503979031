import {Box, Typography} from '@material-ui/core';
import React from 'react';
import IntlMessages from 'shared/components/IntlMessages';
import useStepperStyles from './StepperStyle';

interface StepTitleProps {
    activeStep: number;
}
export default function StepTitle({activeStep}: StepTitleProps) {
    const classes = useStepperStyles()

    const stepText = [
        {
            title: "subscriptions.stepper.step_one.title",
            description: "subscriptions.stepper.step_one.description"
        }, {
            title: "subscriptions.stepper.step_two.title",
            description: "subscriptions.stepper.step_two.description"
        }, {
            title: "subscriptions.stepper.step_three.title",
            description: "subscriptions.stepper.step_three.description"
        }, {
            title: "subscriptions.stepper.step_four.title",
            description: 'subscriptions.stepper.step_four.description'
        }
    ]


    return (
        <Box className={classes.stepContentTitle}>
            <Typography variant='h6' className={classes.stepTitle}>
                {`${activeStep+1}. `}
                <IntlMessages id={stepText[activeStep].title} />
            </Typography>
            {stepText[activeStep].description ?
                <Typography variant='subtitle1'>
                    <IntlMessages id={stepText[activeStep].description} />
                </Typography>
                : ""}
        </Box>
    )
}