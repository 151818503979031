import React, {useContext} from 'react';
import {IntlProvider} from 'react-intl';

import AppLocale from '../localization';
import PropTypes from 'prop-types';
import AppContext from '../../@crema/utility/AppContext';
import AppContextPropsType from '../models/AppContextPropsType';
import IntlGlobalProvider from './IntlGlobalProvider';

const LocaleProvider = (props: any) => {
  const {locale} = useContext<AppContextPropsType>(AppContext);
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      defaultLocale='fr-FR'
      messages={currentAppLocale.messages}>
      <IntlGlobalProvider>
        {props.children}
      </IntlGlobalProvider>
    </IntlProvider>
  );
};

export default LocaleProvider;

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
