import MaterialTable, {MTableBodyRow, MTableCell, MTableHeader} from 'material-table';
import Product from 'modules/products/models/Product';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store';
import {getFieldListQueryString, getInvoiceQueryString, STRIPE_METADATA_} from '../configs/constant';
import Subscription from '../models/Subscription';
import * as FirebaseFirestore from '@firebase/firestore-types';
import moment from 'moment';
import {Box, Button, IconButton, Typography} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {useHistory} from 'react-router';
import {actions as SubscriptionsActions} from "../actions/SubscriptionsActions";
import useStepperStyles from './StepperStyle';
import {useIntl} from 'react-intl';
import {SubscriptionsStatus} from '../models/SubscriptionStatus';
import {STRIPE_METADATA_REF} from 'modules/products/configs/constant';
import {FUNCTION_LOCATION} from 'shared/configs/AppConst';
import firebase from 'firebase';
import {fetchStart} from 'shared/actions';


export default function SubscriptionsTable() {

    const {data} = useSelector<AppState, AppState['subscriptions']>(({subscriptions}) => subscriptions)
    const {products} = useSelector<AppState, AppState>((products) => products)

    const [currentSubscriptions, setCurrentSubscriptions] = useState<Subscription[]>([])
    const [pastSubscriptions, setPastSubscriptions] = useState<Subscription[]>([])
    const [subscriptionsToDisplay, setSubscriptionToDisplay] = useState<Subscription[]>([])

    const dispatch = useDispatch();

    const classes = useStepperStyles()
    const {messages} = useIntl();



    const history = useHistory();

    useEffect(() => {
        if (data) {
            const {currentSubscriptionsList, lastSubscriptionsList} = subscriptionList(data)
            setCurrentSubscriptions(currentSubscriptionsList)
            setPastSubscriptions(lastSubscriptionsList)
            setSubscriptionToDisplay(currentSubscriptionsList)
        }
    }, [data])

    const subscriptionList = (data: {[key: string]: Subscription}) => {
        const currentSubscriptionsList: Subscription[] = []
        const lastSubscriptionsList: Subscription[] = []
        Object.values(data).forEach(subscription => {
            if (subscription.status === SubscriptionsStatus.ACTIVE) {
                currentSubscriptionsList.push(subscription)
            } else if (subscription.status === SubscriptionsStatus.PAST_DUE) {
                lastSubscriptionsList.push(subscription)
            }
        })
        return {currentSubscriptionsList, lastSubscriptionsList}
    }

    const fieldsCount = (data?: string) => {
        if (data) {
            const fieldList = JSON.parse(data)
            return fieldList.length as number
        } else {
            return 0
        }
    }

    const getProduct = (ref: string) => {
        const {data} = products
        if (data) {
            const product = Object.values(data).find(product => product[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`] === ref)
            return product
        } else {
            log.error(`Problem while getting products data`)
        }
    }

    const getPrice = (product: Product, priceRef: string) => {
        const price = product.prices?.find(price => price[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`] === priceRef)
        return price
    }

    const productName = (ref: string) => {
        const {data} = products
        if (data) {
            const product = Object.values(data).find(product => product[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`] === ref)
            if (product) {
                return product.name
            } else {
                log.error(`Cannot retrieve product with ref ${ref}`)
                return null
            }
        } else {
            log.error(`Problem while getting Products data`)
            return null
        }

    }

    const duration = (priceRef: string, productRef: string) => {
        const product = getProduct(productRef);
        if (product) {
            const price = getPrice(product, priceRef)
            if (price) {
                const interval_count = price.interval_count
                const interval = price.interval
                return (
                    <>
                        {`${interval_count} `}
                        <IntlMessages id={`subscriptions.stepper.price.interval.${interval}`} />
                    </>
                )
            } else {
                log.error(`Cannot retrieve price with ref ${priceRef}`)
                return null
            }
        } else {
            log.error(`Cannot retrieve product with ref ${productRef}`)
            return null
        }
    }

    const displayDate = (date: FirebaseFirestore.Timestamp) => {
        return moment(date.toDate()).format('DD/MM/YYYY')
    }

    const handleStripePortal = async function () {
        dispatch(fetchStart("handleStripePortal"))
        // Call billing portal function
        const functionRef = firebase.app()
            .functions(FUNCTION_LOCATION)
            .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        const {data} = await functionRef({returnUrl: window.location.origin});
        window.location.assign(data.url);
    }

    return (
        <Box display='flex' flexDirection='column'>
            <Box pb={2} display='flex' justifyContent="strecht">
                <Button className={[classes.buttonNavigation, subscriptionsToDisplay === currentSubscriptions ? classes.selected : ""].join(' ')} onClick={() => setSubscriptionToDisplay(currentSubscriptions)}>
                    <IntlMessages id='subscriptions.table.button.current_subscriptions' />
                </Button>
                <Button className={[classes.buttonNavigation, subscriptionsToDisplay === pastSubscriptions ? classes.selected : ""].join(' ')} onClick={() => setSubscriptionToDisplay(pastSubscriptions)}>
                    <IntlMessages id='subscriptions.table.button.past_subscriptions' />
                </Button>
            </Box>
            <MaterialTable
                data={subscriptionsToDisplay as Subscription[]}
                columns={[
                    {
                        title: <IntlMessages id="subscriptions.table.field" />,
                        render: (data) => {
                            if (data.metadata && data.metadata.field_ids) {
                                return (
                                    <Box display='flex' alignItems='center' justifyContent='center'>
                                        <Typography className={classes.bold}>
                                            {fieldsCount(data.metadata.field_ids)}
                                        </Typography>
                                        <IconButton onClick={() => {
                                            history.push({pathname: history.location.pathname, search: getFieldListQueryString(data.metadata.field_ids)})
                                        }}>
                                            <VisibilityIcon color='primary' />
                                        </IconButton>
                                    </Box>
                                )
                            } else {
                                return (
                                    <IntlMessages id="common.na" />
                                )
                            }
                        },
                        align: 'center',
                        sorting: true,
                        defaultSort: 'asc',
                        customSort: (a, b) => {
                            return fieldsCount(a.metadata.field_ids) - fieldsCount(b.metadata.field_ids)
                        }

                    },
                    {
                        title: <IntlMessages id="subscriptions.stepper.step_two.area_list.title" />,
                        render: (data) => {
                            return data.quantity
                        },
                        align: 'center',
                        sorting: true,
                        defaultSort: 'asc',
                        customSort: (a, b) => {
                            return a.quantity - b.quantity
                        }
                    },
                    {
                        title: <IntlMessages id="subscriptions.pagination" />,
                        render: (data) => {
                            if (data.metadata.product_ref && productName(data.metadata.product_ref)) {
                                return (
                                    <Typography className={classes.bold}>
                                        {productName(data.metadata.product_ref)}
                                    </Typography>
                                )
                            } else {
                                return (
                                    <IntlMessages id="common.na" />
                                )
                            }
                        },
                        align: 'center',
                    },
                    {
                        title: <IntlMessages id="subscription.table.duration" />,
                        render: (data) => {
                            if (
                                data.metadata.price_ref
                                && data.metadata.product_ref
                                && duration(data.metadata.price_ref, data.metadata.product_ref)
                            ) {
                                return (
                                    <Typography className={classes.bold}>
                                        {duration(data.metadata.price_ref, data.metadata.product_ref)}
                                    </Typography>
                                )
                            } else {
                                return (
                                    <IntlMessages id="common.na" />
                                )
                            }
                        },
                        align: 'center',
                    },
                    {
                        title: <IntlMessages id="subscription.table.start_date" />,
                        render: (data) => {
                            return (
                                <Typography className={classes.bold}>
                                    {displayDate(data.current_period_start)}
                                </Typography>
                            )
                        },
                        align: 'center',
                        sorting: true,
                        defaultSort: 'asc',
                        customSort: (a: any, b: any) => {
                            return a.current_period_start - b.current_period_start
                        }

                    },
                    {
                        title: <IntlMessages id="subscription.table.end_date" />,
                        render: (data) => {
                            return (
                                <Typography className={classes.bold}>
                                    {displayDate(data.current_period_end)}
                                </Typography>
                            )
                        },
                        align: 'center',
                        sorting: true,
                        defaultSort: 'asc',
                        customSort: (a: any, b: any) => {
                            return a.current_period_end - b.current_period_end
                        }

                    },
                    {
                        title: <IntlMessages id="subscription.table.invoice" />,
                        render: (data) => {
                            return (
                                <IconButton onClick={() => {
                                    dispatch(SubscriptionsActions.loadInvoicesInfo(data.id))
                                    history.push({pathname: history.location.pathname, search: getInvoiceQueryString(data.id)})
                                }}>
                                    <DescriptionIcon color='primary' />
                                </IconButton>
                            )
                        },
                        align: 'center',
                    },
                    {
                        title: "",
                        render: (data) => {
                            return (
                                <Typography onClick={handleStripePortal} className={classes.bold} color='primary' style={{textDecoration: 'underline', cursor: 'pointer'}}>
                                    <IntlMessages id="subscription.table.detail" />
                                </Typography>
                            )
                        },
                        align: 'center',
                    },
                ]}
                components={{
                    Header: (props) => (
                        <MTableHeader {...props} classes={{header: classes.headerStyle}} />
                    ),
                    Row: (props) => (
                        <MTableBodyRow
                            {...props}
                            classes={{root: props.index % 2 ? classes.rowStyle : classes.bold}}
                        />
                    ),
                    Cell: (props) => (
                        <MTableCell {...props} classes={{root: classes.cellStyle}} />
                    ),
                }}
                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    toolbar: false,
                    sorting: true
                }}
                localization={{
                    header: {
                        actions: '',
                    },
                    pagination: {
                        labelRowsSelect: messages['subscriptions.pagination'] as string,
                        firstTooltip: messages['table.pagination.firstTooltip'] as string,
                        lastTooltip: messages['table.pagination.lastTooltip'] as string,
                        previousTooltip: messages[
                            'table.pagination.previousTooltip'
                        ] as string,
                        nextTooltip: messages['table.pagination.nextTooltip'] as string,
                        labelDisplayedRows: messages[
                            'table.pagination.labelDisplayedRows'
                        ] as string,
                    },
                }}

            />
        </Box>
    )
}