import CheckoutSession, {BillingAddressCollectionMode} from "../models/CheckoutSession";

export const baseCheckoutSession: CheckoutSession = {
    allow_promotion_codes: true,
    line_items: [],
    payment_method_types: ["card"],
    success_url: "",
    cancel_url: "",
    metadata: {
        campaign_id: "",
        field_ids: "",
        product_ref: "",
        price_ref: "",
    },
    billing_address_collection: BillingAddressCollectionMode.AUTO,
}