import {LayerProps} from 'react-map-gl'
import {ZOOM_SWITCH} from './FieldMapStyles';

// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
const ndviMapStyle: LayerProps = {
    id: 'overlay',
    type: 'raster',
    paint: {
        'raster-opacity': 0.85
    },
    minzoom: ZOOM_SWITCH
};
export default ndviMapStyle;
