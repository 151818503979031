import React from 'react';

export const soilsRouteConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/soils',
        component: React.lazy(() => import('./components/Soils')),
      },
    ],
  },
];
