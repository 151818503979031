import {ClickAwayListener, IconButton, Tooltip} from '@material-ui/core';
import React, {useState} from 'react';
import QuestionIcon from 'shared/components/Icons/QuestionIcon';
import useStyles from './ProductStyle';

interface TooltipProps {
    table: JSX.Element
}
export default function PriceTooltip({table}: TooltipProps) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
            classes={{tooltip: classes.tooltip}}
            onClose={handleTooltipClose}
            title={table}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={'bottom-start'}
            >
                <IconButton onClick={handleTooltipOpen} className={classes.iconButton}>
                    <QuestionIcon fontSize='inherit' color='primary' />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    )
}