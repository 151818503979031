import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

import AppLayout from './shared/components/layout/AppLayout';
import ContentView from './shared/components/layout/ContentView';
import useStyles from './shared/jss/common/common.style';
import {AppState} from './shared/store';
import {CremaTheme} from './shared/models/AppContextPropsType';
import InfoView from './shared/components/InfoView';
import BG from './assets/images/auth-background.jpg';
import authActions from 'modules/auth/actions/AuthActions';
import productsActions from 'modules/products/actions/ProductsActions';

const useStyle = makeStyles((theme: CremaTheme) => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: theme.palette.primary.main,
    background: `url(${BG}) no-repeat center center`,
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {
      padding: 20,
    },
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

interface CremaLayoutProps { }

const CremaLayout: React.FC<CremaLayoutProps> = () => {
  useStyles();
  const dispatch = useDispatch();

  const {user, rolesConfig} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const {data} = useSelector<AppState, AppState['products']>((state) => state.products);

  useEffect(() => {
    // Load roles config if not loaded yet
    if (!rolesConfig) {
      dispatch(authActions.loadRolesConfig());
    }
  }, [rolesConfig])

  useEffect(() => {
    // Load products
    if (!data) {
      dispatch(productsActions.loadProductListing());
    }
  }, [data])

  const classes = useStyle();
  return (
    <>
      {user ? (
        <AppLayout />
      ) : (
        <Box className={classes.appAuth}>
          <ContentView />
          <InfoView />
        </Box>
      )}
    </>
  );
};

export default React.memo(CremaLayout);
