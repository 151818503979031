import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../../shared/store';
import {useHistory} from 'react-router-dom';
import Campaign from '../models/Campaign';
import {selectedCampaignParam} from '../configs/CampaignsRoutePaths';
import {selectedCampaignFieldsPath} from 'modules/fields/configs/FieldsRoutePaths';


export const getCurrentCampaign = function (campaigns: Campaign[]) {
    let currentYear: string = new Date().getFullYear().toString();
    let currentCampaign: number = 0;
    campaigns?.map(element => {
        if (element.campaign_end.includes(currentYear)) currentCampaign = element.campaign_id
    })
    return currentCampaign
}

interface CampaignsRedirectProps { }

/**
 * Component which redirect to the current campaign
 * @param props 
 */
const CampaignsRedirect: React.FC<CampaignsRedirectProps> = (props) => {

    const history = useHistory();
    const {data} = useSelector<AppState, AppState['campaigns']>(
        (state) => state.campaigns,
    );

    // Auto redirecte to current campaign
    useEffect(() => {
        if (data && Object.values(data).length > 0) {
            const currentCampaignId = getCurrentCampaign(Object.values(data));
            const currentCampaignFieldsPath = selectedCampaignFieldsPath.replace(`:${selectedCampaignParam}`, currentCampaignId.toString());
            history.push(currentCampaignFieldsPath);
        }
    }, [data, history]);

    return (
        <></>
    );
}


export default CampaignsRedirect;