import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
//import {CremaTheme} from 'types/AppContextPropsType';
import {
  Fonts,
  FooterType,
  LayoutType,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from './AppEnums';
import {languageData} from 'shared/configs/LanguageSwitcherConfig';
import {auth} from 'shared/services/ReduxSagaFirebase';
import log from 'shared/services/LogService';

const breakpoints = createBreakpoints({});
const cardRadius = ThemeStyleRadius.STANDARD;
const primaryColor = '#94c11f';
const theme: any = {
  spacing: 4,
  direction: 'ltr', //ltr, rtl
  palette: {
    type: ThemeMode.LIGHT,
    background: {
      paper: '#FFFFFF',
      default: '#F4F7FE',
    },
    primary: {
      main: primaryColor,
      contrastText: '#fff',
    },
    secondary: {
      main: '#019a9a',
      contrastText: '#fff',
    },
    warning: {
      main: '#eba900',
      contrastText: '#fff',
    },
    error: {
      main: '#ff4f27',
      light: 'rgba(255, 8, 1, 0.1)',
      contrastText: '#fff',
    },

    sidebar: {
      bgColor: '#313541',
      textColor: '#808183',
    },
    common: {
      white: '#fff',
      black: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.38)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    chart: {
      gdd: '#e0ce24',
      ndvi: '#48a81e',
      rain: '#5c85be',
      irrigation: '#58f2ee',
      de: '#2ec587',
      raw: '#eba900',
      taw: '#000000',
      etc: '#ff4f27',
      hydro_stress: 'rgba(255, 8, 1, 0.7)',
      et0: primaryColor,
    },
  },
  status: {
    danger: 'orange',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  typography: {
    fontFamily: [Fonts.REGULAR, 'sans-serif'].join(','),
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 36,
      },
      h2: {
        fontSize: 30,
      },
      h3: {
        fontSize: 24,
      },
      h4: {
        fontSize: 22,
      },
      h5: {
        fontSize: 18,
      },
      h6: {
        fontSize: 16,
      },
      subtitle1: {
        fontSize: 18,
      },
      subtitle2: {
        fontSize: 20,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      caption: {
        fontSize: 10,
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: cardRadius,
      },
    },
    MuiCardLg: {
      root: {
        borderRadius:
          cardRadius === ThemeStyleRadius.STANDARD
            ? ThemeStyleRadius.STANDARD
            : ThemeStyleRadius.MODERN + 20,
      },
    },
    MuiCard: {
      root: {
        borderRadius: cardRadius,
        // boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: cardRadius,
        // boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
        [breakpoints.down('md')]: {
          paddingTop: '8px !important',
          paddingBottom: '8px !important',
        },
      },
    },
    MuiCheckbox: {
      root: {},
      colorSecondary: {
        color: primaryColor,
        '&$checked': {
          color: primaryColor,
        },
      },
    },
  },
};

// Use the navigator language to find the default config
const userLang = navigator.language;
//console.log(`Navigator language ${userLang}`);
let locale = userLang.split('-')[0];
let foundLocale = languageData.find((val) => val.locale.startsWith(locale));
// Fallback to english
if (!foundLocale) {
  locale = 'en-US';
  foundLocale = languageData.find((val) => val.locale === locale);
}
// Last fallback: first one
if (!foundLocale && languageData.length > 0) {
  foundLocale = languageData[0];
}

if (foundLocale) {
  auth.languageCode = foundLocale.locale;
  log.debug(`Define language ${JSON.stringify(foundLocale)}`);
}

const defaultConfig: any = {
  theme: theme,
  themeStyle: ThemeStyle.STANDARD,
  themeMode: ThemeMode.DARK,
  navStyle: NavStyle.HEADER_USER_MINI,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  rtAnim: RouteTransition.NONE,
  footer: false,
  locale: foundLocale,
  rtlLocale: ['ar'],
};
export default defaultConfig;
