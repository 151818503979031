import React from 'react';
import {SvgIcon} from '@material-ui/core';
import {ReactComponent as LogoIcon} from '../../assets/images/logo.svg';

//interface AppLogoProps extends ElementProps {}

const AppLogo = (props: any) => {
  return (
    <SvgIcon {...props} component={LogoIcon} viewBox="0 0 115 32" />
  );
};

export default AppLogo;
