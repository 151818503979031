import {makeStyles} from '@material-ui/core';
import {CremaTheme} from 'shared/models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    dialogPaper: {
      minHeight: '76vh',
      maxHeight: '76vh',
    },
    welcomeContainer: {
      textAlign: 'center',
      justifyContent: 'space-evenly',
    },
    welcomeContentContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    buttonDotsContainer: {
      position: 'absolute',
      alignSelf: 'center',
    },
    dots: {
      textAlign: 'center',
      margin: theme.spacing(2),
    },
    dialogSubtitle: {
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    overviewImg: {
      alignSelf: 'center',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '410px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '510px',
      },
      [theme.breakpoints.up('md')]: {
        width: '600px',
      },
    },
    boxTextOverview1: {
      position: 'absolute',
      left: '1em',
      [theme.breakpoints.up('xs')]: {
        width: '17em',
        top: '77%',
      },
      [theme.breakpoints.up('md')]: {
        width: '10em',
        top: '57%',
      },
    },
    arrowOverview1: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      top: '58%',
      left: '7em',
    },

    boxTextOverview2: {
      position: 'absolute',
      left: '1em',
      [theme.breakpoints.up('xs')]: {
        width: '19em',
        bottom: '9%',
      },
      [theme.breakpoints.up('md')]: {
        width: '19em',
        bottom: '5%',
      },
    },
    arrowOverview2: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      bottom: '10%',
      left: '5em',
    },

    boxTextOverview3: {
      position: 'absolute',
      right: '1em',
      [theme.breakpoints.up('xs')]: {
        width: '18em',
        top: '14%',
      },
      [theme.breakpoints.up('md')]: {
        width: '9em',
        top: '34%',
      },
    },
    arrowOverview3: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      top: '26%',
      right: '6em',
    },
    boxTextOverview4: {
      position: 'absolute',
      right: '1em',
      [theme.breakpoints.up('xs')]: {
        width: '10em',
        bottom: '12%',
      },
      [theme.breakpoints.up('md')]: {
        width: '9em',
        top: '67%',
      },
    },
    arrowOverview4: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      bottom: '22%',
      right: '10em',
    },
    boxTextOverview5: {
      position: 'absolute',
      left: '1em',
      [theme.breakpoints.up('xs')]: {
        width: '14em',
        top: '16%',
      },
      [theme.breakpoints.up('md')]: {
        width: '9em',
        top: '33%',
      },
    },
    arrowOverview5: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      top: '36%',
      left: '7em',
    },

    fieldsDetailsContainer: {
      [theme.breakpoints.up('md')]: {
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    fieldsDetailsImg: {
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(10),
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '410px',
      },
      [theme.breakpoints.up('md')]: {
        margin: '1.5em auto auto 3em',
        width: '550px',
      },
    },
    boxTextFieldsDetails1: {
      [theme.breakpoints.up('xs')]: {
        paddingTop: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        width: '13em',
        position: 'absolute',
        top: '72%',
        left: '1em',
      },
    },
    arrowFieldsDetails1: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      bottom: '25%',
      left: '1em',
    },
    boxTextFieldsDetails2: {
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '95%',
        top: '411px',
      },
      [theme.breakpoints.up('sm')]: {
        top: '436px',
      },
      [theme.breakpoints.up('md')]: {
        width: '260px',
        position: 'absolute',
        right: '2em',
        top: '15%',
      },
    },
    soilsAndCropsContainer: {
      [theme.breakpoints.up('md')]: {
        height: '100%',
        width: '100%',
      },
    },
    soilsAndCropsImgContainer: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginTop: '10%',
      },
    },
    soilsAndCropsImg: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        width: '400px',
      },
    },
    soilsAndCropsTextBox1: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        width: '17em',
        marginTop: theme.spacing(4),
        flexDirection: 'row-reverse',
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        position: 'absolute',
        width: '19em',
        top: '16%',
        left: '13em',
      },
    },
    soilsAndCropsTextBox2: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        width: '15em',
        position: 'absolute',
        top: '15%',
        right: '11em',
      },
    },
    cropsDetails: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        display: 'inline-block',
        position: 'absolute',
        width: '21em',
        top: '60%',
        left: '2em',
      },
    },
    arrowCropsDetails: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'block',
        position: 'absolute',
        left: '21em',
        top: '0',
      },
    },
    soilsDetails: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        width: '23em',
        top: '62%',
        right: '2em',
      },
    },
    arrowSoilsDetails: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        transform: 'rotate(180deg)',
        position: 'absolute',
        right: '23em',
        bottom: '65%',
      },
    },
    rotateArrow180: {
      [theme.breakpoints.up('md')]: {
        transform: 'rotate(180deg)',
      },
    },
    revertArrow: {
      transform: 'scaleX(-1)',
    },
    textBold: {
      fontWeight: 'bold',
    },
    svgArrowMedium: {
      fontSize: '5em',
    },
    svgArrowLarge: {
      fontSize: '7em',
    },
    arrowAndText: {
      position: 'absolute',
      top: '-22%'
    },
    positionRelative: {
      position: 'relative'
    },
    subscriptionText: {
      textAlign: 'center',
      paddingBottom: theme.spacing(5)
    }
  };
});
export default useStyles;
