import React from 'react';
import SatelliteIcon from '@material-ui/icons/Satellite';
import {indexPath, selectedCampaignParam} from '../../campaigns/configs/CampaignsRoutePaths';
import {selectedCampaignFieldsPath} from '../configs/FieldsRoutePaths';
import HeaderButton from '../../../shared/components/layout/HeaderButton';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store/index';

interface FieldsHeaderButtonProps {
  /**
   * Always show title in the button
   * Used for mobile display (collapsed header menu)
   */
  alwaysShowTitle?: boolean;
}

/**
 * Button to access user fields located in the header bar
 * @param props 
 */
const FieldsHeaderButton: React.FC<FieldsHeaderButtonProps> = (props) => {

  const {selectedCampaign} = useSelector<AppState, AppState['fields']>(
    ({fields}) => fields,
  );

  let targetPath = indexPath;
  if (selectedCampaign) {
    targetPath = selectedCampaignFieldsPath.replace(`:${selectedCampaignParam}`, selectedCampaign.toString());
  }

  return (
    <>
      <HeaderButton path={targetPath} titleId="fields.headerButton.title" icon={<SatelliteIcon />} alwaysShowTitle={props.alwaysShowTitle} />
    </>
  );
};

export default FieldsHeaderButton;
