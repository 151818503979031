import React, {useContext} from 'react';
import Welcome from './Welcome';
import Overview from './Overview';
import FieldsDetails from './FieldsDetails';
import SoilsAndCrops from './SoilsAndCrops';
import {Box, Button, Typography, useTheme} from '@material-ui/core';
import {InfoDialogChoice} from 'shared/configs/AppEnums';
import IntlMessages from 'shared/components/IntlMessages';
import Subscriptions from './Subscriptions';
import log from 'shared/services/LogService';
import AppContextPropsType from 'shared/models/AppContextPropsType';
import {AppContext} from '@crema';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';

interface InformationsProps {
  /**
   * to manage which part of the information dialog is shown
   */
  selectedInfo: string;
  /**
   * to manage the closing of the information dialog
   */
  handleModal: () => void;
  /**
   * state of the information dialog open/close
   */
  open: boolean;
}

/**
 * Component to display the totality or different part of the information dialog
 * Contains a button to navigate between the different part of the dialog
 */
const Informations: React.FC<InformationsProps> = ({
  selectedInfo,
  handleModal,
  open,
}) => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState<number>(0);
  React.useEffect(() => {
    switch (selectedInfo) {
      case InfoDialogChoice.NONE:
        setActiveStep(0);
        break;
      case InfoDialogChoice.FILTERS:
        setActiveStep(1);
        break;
      case InfoDialogChoice.TABS:
        setActiveStep(2);
        break;
      case InfoDialogChoice.SOILS_AND_CROPS:
        setActiveStep(3);
        break;
      case InfoDialogChoice.SUBSCRIPTIONS:
        setActiveStep(4);
        break;
    }
  }, [selectedInfo]);

  const pageShown = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return <Welcome />;
      case 1:
        return <Overview />;
      case 2:
        return <FieldsDetails />;
      case 3:
        return <SoilsAndCrops />;
      case 4:
        return <Subscriptions />;
    }
  };

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setActiveStep(activeStep + 1);
  }

  const {locale} = useContext<AppContextPropsType>(AppContext);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const lang = locale.locale.split("-")[0];

  //https://wago.terranis.fr/documentation/user_manual_${role}_{lang}.pdf
  const documentationTemplateUrl = process.env.REACT_APP_WAGO_DOCUMENTATION_PREFIX_URL;
  let documentationUrl: string | undefined = undefined;
  if (documentationTemplateUrl && user && user.role && lang) {
    log.debug(`Role: ${user.role}, Lang: ${lang}, template ${documentationTemplateUrl}`);
    documentationUrl = `${documentationTemplateUrl}_${user.role}_${lang}.pdf`;
    log.debug(`Documentation url: ${documentationUrl}`);
  }

  return (
    <>
      <Box width={1} height={1} display='flex' flexDirection='column'>
        <Box
          height={1}
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          marginBottom='8px'>
          {pageShown(activeStep)}
          {documentationUrl && <Box mb={4}>
            <Button
              component="button"
              style={{width: 280}}
              data-test='common-documentation-button'
              variant="outlined"
              color='primary'
              onClick={() => {window.open(documentationUrl, "_blank")}}>
              <IntlMessages id='common.documentation.button_title' />
            </Button>
          </Box>}
          {selectedInfo === InfoDialogChoice.NONE ? (
            activeStep !== 4 ? (
              <Button
                style={{width: 280}}
                data-test='info-next-button'
                variant='contained'
                color='primary'
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  handleClick(event)
                }>
                <IntlMessages id='informations.next' />
              </Button>
            ) : (
              <Button
                style={{width: 280}}
                data-test='info-close-button'
                variant='contained'
                color='primary'
                onClick={handleModal}>
                <IntlMessages id='informations.close' />
              </Button>
            )
          ) : (
            ''
          )}
        </Box>
      </Box>
      {/* Display version number set in gitlab ci from tags */}
      <Box
        position='absolute'
        bottom={theme.spacing(4)}
        right={theme.spacing(4)}>
        <Typography variant='caption'>
          {process.env.REACT_APP_VERSION
            ? process.env.REACT_APP_VERSION
            : 'Version dev'}
        </Typography>
      </Box>
    </>
  );
};

export default Informations;
