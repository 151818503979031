import SoilModel from '../models/SoilModel';

export const LOAD_SOILS_LISTING = 'LOAD_SOILS_LISTING';
export interface LoadSoilsListingAction {
  type: typeof LOAD_SOILS_LISTING;
}

export const LOAD_SOILS_LISTING_SUCCESS = 'LOAD_SOILS_LISTING_SUCCESS';
export interface LoadSoilsListingSuccessAction {
  type: typeof LOAD_SOILS_LISTING_SUCCESS;
  payload: {[key: string]: SoilModel};
}

export const ADD_NEW_SOIL = 'ADD_NEW_SOIL';
export interface AddNewSoilAction {
  type: typeof ADD_NEW_SOIL;
  payload: SoilModel;
}

export const DELETE_SOIL = 'DELETE_SOIL';
export interface DeleteSoilAction {
  type: typeof DELETE_SOIL;
  payload: number;
}

export const MODIFY_SOIL = 'MODIFY_SOIL';
export interface ModifySoilAction {
  type: typeof MODIFY_SOIL;
  payload: SoilModel;
}

export type SoilsActionTypes =
  | LoadSoilsListingAction
  | LoadSoilsListingSuccessAction
  | AddNewSoilAction
  | DeleteSoilAction
  | ModifySoilAction;

export const actions = {
  loadSoilsListing: (): LoadSoilsListingAction => {
    return {
      type: LOAD_SOILS_LISTING,
    };
  },

  //TO DO: get only the id_agriculteur: null | user 's soils
  loadSoilsListingSuccessAction: (soils: {
    [key: string]: SoilModel;
  }): LoadSoilsListingSuccessAction => {
    return {
      type: LOAD_SOILS_LISTING_SUCCESS,
      payload: soils,
    };
  },

  addNewSoil: (newSoil: SoilModel): AddNewSoilAction => {
    return {
      type: ADD_NEW_SOIL,
      payload: newSoil,
    };
  },

  deleteSoil: (selectedSoil: number): DeleteSoilAction => {
    return {
      type: DELETE_SOIL,
      payload: selectedSoil,
    };
  },

  modifySoil: (modifiedSoil: SoilModel): ModifySoilAction => {
    return {
      type: MODIFY_SOIL,
      payload: modifiedSoil,
    };
  },
};

export default actions;
