import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';
import {ReactComponent as ArrowCrops} from 'assets/images/icons/arrowCrops.svg';

const ArrowCropsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={ArrowCrops}
      viewBox='0 0 94 61'
    />
  );
};

export default ArrowCropsIcon;
