import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {Source, Layer, PointerEvent} from 'react-map-gl';
import {
    generateFieldMapPolygonStyle,
    generateFieldMapSymbolStyle,
    generateFieldMapBboxStyle,
    ZOOM_SWITCH,
} from '../configs/FieldMapStyles';
import ndviMapStyle from '../configs/NdviMapStyle';
import {AppState} from 'shared/store';
import {mapToken} from '../../../shared/configs/AppConst';
import {Feature, FeatureCollection} from 'geojson';
import fieldsActions from '../actions/FieldsActions';
import {useHistory, useLocation} from 'react-router-dom';
import {Box, Card, Typography} from '@material-ui/core';
import useStyles from './FieldsMap.style';
import NdviSelector from '../../waterBalance/components/NdviSelector';
import {useTheme} from '@material-ui/core/styles';
import WaterBalanceModel from 'modules/waterBalance/models/WaterBalanceModel';
import MapPosition from '../models/MapPosition';
import {replaceFieldId} from '../services/FieldsService';
import useDimensions from 'react-use-dimensions';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import Geocoder from 'react-map-gl-geocoder';
import {useIntl} from 'react-intl';
import bboxPolygon from '@turf/bbox-polygon';
import clsx from 'clsx';
import NdviLegend from 'modules/waterBalance/components/NdviLegend';
import HydroStressLegend from 'modules/waterBalance/components/HydroStressLegend';
import {Dispatch} from 'redux';
import log from 'loglevel';
import useMapIcons from 'shared/hooks/useMapIcons';
import FieldsMapTooltip from './FieldsMapTooltip';
import Field from '../models/Field';

const geoViewport = require('@mapbox/geo-viewport');

/**
 * Zoom level offset use to add margin around the bbox
 */
export const ZOOM_LEVEL_MARGIN_OFFSET = 1;

/**
 * 
 * @param zoomBbox 
 */
const zoomToBbox = function (dispatch: Dispatch, zoomBbox: number[], width: number, height: number) {
    // Get the viewport from the all the features or the filtered features
    log.debug(`Size: ${width} x ${height}`);
    const viewport = geoViewport.viewport(zoomBbox, [width, height]);
    log.debug(`Target view port: ${JSON.stringify(viewport)}`);
    if (viewport && viewport.zoom) {


        dispatch(
            fieldsActions.setMapPosition({
                latitude: viewport.center[1],
                longitude: viewport.center[0],
                zoom: (viewport.zoom - ZOOM_LEVEL_MARGIN_OFFSET),
            }),
        );
        dispatch(fieldsActions.setZoomToSelected(false));
    }
}

interface MapNdvi {
    coordinates: number[][];
    url: string;
    id: string;
    style: any;
}

interface FieldsMapProps {
    /**
     * If false, the geocoder component to look for a place is not displayed
     */
    geocoder?: boolean;
}

/**
 * Components to display fields map with markers, polygons, ndvi raster depending on scale.
 * It includes map overlay component such as:
 * - Geocoder
 * - Legend
 * - Date selection
 * - Tooltip window
 * @param props 
 */
const FieldsMap: React.FC<FieldsMapProps> = (props) => {
    const dispatch: Dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const theme = useTheme();
    const classes = useStyles();
    const location = useLocation();
    const [ref, {width, height}] = useDimensions();

    const [hoveredState, setHoveredState] = useState<{
        featureId: number | null;
        x: number;
        y: number;
    }>({
        featureId: null,
        x: 0,
        y: 0,
    });
    const [mapFeatures, setMapFeatures] = useState<{
        fieldsCentroidGeojson?: FeatureCollection;
        fieldsGeojson?: FeatureCollection;
        ndvis?: MapNdvi[];
        bboxFeature?: Feature;
    }>({});
    const [targetZoomBbox, setTargetZoomBbox] = useState<number[] | null>(null);

    // Use auth state to get the thresholds
    const {user} = useSelector<AppState, AppState['auth']>((state) => state.auth);

    // Use the fields state to retrieve the list of fields and display the polygons
    const {
        data,
        selectedFieldId,
        selectedCampaign,
        zoomToSelected,
        mapPosition,
        bbox,
        filteredFields,
        filteredFieldsBbox,
        mapDisplayNdvi,
    } = useSelector<AppState, AppState['fields']>(({fields}) => fields);
    // Use the water balance state to display the right ndvi image
    const {data: waterBalanceData, selectedNdviDate} = useSelector<AppState, AppState['waterBalance']>(
        (state) => state.waterBalance,
    );

    const mapRef = useRef<ReactMapGL>(null);
    useMapIcons(mapRef);

    useEffect(() => {
        // Build the geojson with fields and MapNdvi
        const fieldsGeojson: FeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        const fieldsCentroidGeojson: FeatureCollection = {
            type: 'FeatureCollection',
            features: [],
        };
        let bboxFeature: Feature | undefined = undefined;
        let ndvis: MapNdvi[] = [];
        let targetZoomBbox: number[] | null = null;
        let selectedField: Field | null = null;
        if (data && selectedCampaign) {
            const fieldsById = data[selectedCampaign];
            if (fieldsById) {
                if (selectedFieldId) {
                    selectedField = fieldsById[selectedFieldId];
                }
                const fields = Object.values(fieldsById);
                fields.forEach((field) => {
                    // Add the feature only if no filter or included in the filter
                    if (
                        field &&
                        (!filteredFields || filteredFields.includes(field.field_id))
                    ) {
                        const hovered = field.field_id === hoveredState.featureId;
                        const selected = field.field_id === selectedFieldId;

                        if (field.centroid) {
                            const centroidFeature: Feature = {
                                type: 'Feature',
                                id: field.field_id,
                                geometry: {
                                    type: 'Point',
                                    coordinates: field.centroid,
                                },
                                properties: {
                                    hovered: hovered,
                                    selected: selected,
                                    hydro_stress_level: field.hydro_stress_level,
                                },
                            };
                            fieldsCentroidGeojson.features.push(centroidFeature);
                        }

                        if (field.geojson_geometry) {
                            const feature: Feature = {
                                type: 'Feature',
                                id: field.field_id,
                                geometry: field.geojson_geometry,
                                properties: {
                                    hovered: hovered,
                                    selected: selected,
                                    hydro_stress_level: field.hydro_stress_level,
                                },
                            };
                            fieldsGeojson.features.push(feature);

                            let ndviCoordinates: number[][] | null = null;
                            let ndviPic: string | null = null;
                            // If the field is selected or we have an overview map with NDVI display
                            if (
                                (selected || (mapDisplayNdvi && !selectedFieldId)) &&
                                field.ndvi
                            ) {
                                ndviPic = field.ndvi.ndvi_pic;
                                if (field.ndvi_coordinates) {
                                    ndviCoordinates = field.ndvi_coordinates;
                                }
                            }
                            if (selected) {
                                // update ndvi pic depending on selected date
                                if (selectedNdviDate) {
                                    const foundWaterBalanceModel:
                                        | WaterBalanceModel
                                        | undefined = waterBalanceData.ndvi.find(
                                            (waterBalance: WaterBalanceModel) => {
                                                return (
                                                    waterBalance.wb_date ===
                                                    selectedNdviDate
                                                );
                                            },
                                        );
                                    if (foundWaterBalanceModel && foundWaterBalanceModel.ndvi_pic) {
                                        ndviPic = foundWaterBalanceModel.ndvi_pic;
                                        if (foundWaterBalanceModel.ndvi_pic_bbox) {
                                            try {
                                                const ndvi_bbox = JSON.parse(foundWaterBalanceModel.ndvi_pic_bbox);
                                                // Start from the top left corner and clockwise to have correct image display
                                                ndviCoordinates = [
                                                    [ndvi_bbox[0], ndvi_bbox[3]],
                                                    [ndvi_bbox[2], ndvi_bbox[3]],
                                                    [ndvi_bbox[2], ndvi_bbox[1]],
                                                    [ndvi_bbox[0], ndvi_bbox[1]],
                                                ];
                                            } catch (error) {
                                                log.error(`Error while parsing ndvi_pic_bbox for field ${field.field_id} and water balance ${foundWaterBalanceModel.wb_date}`);
                                            }
                                        }
                                    }
                                }
                            }

                            if (ndviCoordinates && ndviPic) {
                                let id = `overlay_${field.field_id}`;
                                if (selected) {
                                    id = `overlay_selected`;
                                }
                                const ndviMapStyleClone = {...ndviMapStyle};
                                ndviMapStyleClone.id = id;

                                ndvis.push({
                                    url: ndviPic,
                                    coordinates: ndviCoordinates,
                                    id: id,
                                    style: ndviMapStyleClone,
                                });
                            }
                        }
                    }
                });
            }

            // When a field is selected, zoom on it
            if (fieldsGeojson.features.length > 0) {
                let zoomBbox: number[] = bbox;
                if (filteredFieldsBbox) {
                    zoomBbox = filteredFieldsBbox;
                }
                if (zoomBbox && zoomBbox.length === 4) {
                    bboxFeature = bboxPolygon(zoomBbox);
                }

                if (zoomToSelected) {
                    log.debug(`Zoom to selected`);
                    if (selectedField && selectedField.bbox) {
                        targetZoomBbox = selectedField.bbox;
                        log.debug(`Selected field bbox: ${selectedField.bbox}`);
                    } else if (bboxFeature) {
                        // Zoom using the viewport from the all the features or the filtered features
                        targetZoomBbox = zoomBbox;
                    }
                    setTargetZoomBbox(targetZoomBbox);
                } else {
                    setTargetZoomBbox(null);
                }
            }

            log.debug(`Refreshing map features (${fieldsGeojson.features.length})`);
            setMapFeatures({
                fieldsCentroidGeojson,
                fieldsGeojson,
                bboxFeature,
                ndvis
            })
        }
    }, [data, selectedCampaign, zoomToSelected, selectedFieldId, filteredFields, hoveredState, mapDisplayNdvi, selectedNdviDate, waterBalanceData.ndvi]);

    useEffect(() => {
        if (targetZoomBbox && width && height) {
            log.debug(`Zoom to targetZoomBbox: ${targetZoomBbox}`);
            zoomToBbox(dispatch, targetZoomBbox, width, height);
        }
    }, [targetZoomBbox, width, height, dispatch]);

    /**
     *
     * @param viewport Dispatch redux event when viewport is changed
     */
    const onViewportChange = (viewport: any) => {
        const {width, height, ...mapPosition} = viewport;
        dispatch(fieldsActions.setMapPosition(mapPosition as MapPosition));
    };

    /**
     * Handle click on feature on map: dispatch an event to select another field
     * @param event
     */
    const onClick = (event: PointerEvent) => {
        const {features} = event;

        if (features && features.length > 0) {
            const firstClickedFeature = features[0];
            history.push(replaceFieldId(location.pathname, firstClickedFeature.id));
        }
    };

    /**
     *
     * @param event Handle mouse hover the feature on the map
     */
    const onHover = (event: PointerEvent) => {
        const {features} = event;

        if (features && features.length > 0) {
            const firstClickedFeature = features[0];

            setHoveredState({
                featureId: firstClickedFeature.id,
                x: event.srcEvent.offsetX,
                y: event.srcEvent.offsetY,
            });
        } else {
            if (hoveredState.featureId != null) {
                setHoveredState({
                    featureId: null,
                    x: event.srcEvent.offsetX,
                    y: event.srcEvent.offsetY,
                });
            }
        }
    };


    /**
     * Handle click on geocoder place
     * @param viewport The selected location viewport
     */
    const handleGeocoderViewportChange = (viewport: MapPosition) => {
        dispatch(fieldsActions.setMapPosition(viewport));
    };

    const fieldMapPolygonStyle = generateFieldMapPolygonStyle(theme);
    const fieldMapSymbolStyle = generateFieldMapSymbolStyle(theme);
    const fieldMapBboxStyle = generateFieldMapBboxStyle(theme);
    return (
        <Card data-test="fields-map" className={clsx(classes.card)} ref={ref}>
            <ReactMapGL
                mapboxApiAccessToken={mapToken}
                width='100%'
                height='100%'
                ref={mapRef}
                {...mapPosition}
                mapStyle='mapbox://styles/mapbox/satellite-streets-v11'
                onViewportChange={onViewportChange}
                onClick={onClick}
                onHover={onHover}>
                {mapFeatures.fieldsCentroidGeojson && <Source type='geojson' data={mapFeatures.fieldsCentroidGeojson}>
                    <Layer {...fieldMapSymbolStyle} />
                </Source>}

                {mapFeatures.fieldsGeojson &&
                    <Source type='geojson' data={mapFeatures.fieldsGeojson}>
                        <Layer {...fieldMapPolygonStyle} />
                    </Source>}

                {mapFeatures.ndvis && mapFeatures.ndvis.map((ndvi) => {
                    return (
                        <Source key={ndvi.id} type='image' url={ndvi.url} coordinates={ndvi.coordinates}>
                            <Layer {...ndvi.style} />
                        </Source>
                    );
                })}

                {mapFeatures.bboxFeature ? (
                    <Source type='geojson' data={mapFeatures.bboxFeature}>
                        <Layer {...fieldMapBboxStyle} />
                    </Source>
                ) : null}
                {
                    // TODO: change country "language={}"
                }

                {props.geocoder ? (
                    <Geocoder
                        mapRef={mapRef}
                        onViewportChange={handleGeocoderViewportChange}
                        mapboxApiAccessToken={mapToken}
                        position='top-left'
                        placeholder={intl.formatMessage({id: 'fields.map.searchLabel'})}
                    />
                ) : null}
                {
                    // Legend depending on display mode choice and zoom level
                    (mapDisplayNdvi && mapPosition.zoom > ZOOM_SWITCH) ||
                        selectedFieldId ? (
                        <NdviLegend className={classes.fieldsMapLegend} />
                    ) : (
                        <HydroStressLegend className={classes.fieldsMapLegend} />
                    )
                }

                {
                    // Airbus copyright
                    ((mapDisplayNdvi && mapPosition.zoom > ZOOM_SWITCH) ||
                        selectedFieldId) &&
                    <Box className={classes.fieldsMapCopyright} display="flex" flexDirection="column" alignItems="flex-end">
                        <img
                            width="50%"
                            src={require('../../../assets/images/logo_airbus.png')}
                            alt='Airbus'
                        />
                        <Typography variant="caption" style={{color: "white"}}>© Airbus DS</Typography>
                    </Box>
                }

                {selectedFieldId ? (
                    <Card className={classes.fieldsMapNdviSelector}>
                        <NdviSelector />
                    </Card>
                ) : null}

                {(data && hoveredState.featureId && selectedCampaign) &&
                    <FieldsMapTooltip field={data[selectedCampaign][hoveredState.featureId]} position={hoveredState} user={user} />}
            </ReactMapGL>
        </Card>
    );
};

export default FieldsMap;
