import SysIrrigation from '../models/SysIrrigation';

export const LOAD_SYS_IRRIGATION = 'LOAD_SYS_IRRIGATION';
export interface LoadSysIrrigationAction {
  type: typeof LOAD_SYS_IRRIGATION;
}

export const LOAD_SYS_IRRIGATION_SUCCESS = 'LOAD_SYS_IRRIGATION_SUCCESS';
export interface LoadSysIrrigationSuccessAction {
  type: typeof LOAD_SYS_IRRIGATION_SUCCESS;
  payload: {[key: string]: SysIrrigation}
}

export type SysIrrigationTypes = 
  | LoadSysIrrigationAction
  | LoadSysIrrigationSuccessAction

// Actions Creator
export const actions = {
  loadSysIrrigation: (): LoadSysIrrigationAction => {
    return {
      type: LOAD_SYS_IRRIGATION
    };
  },

  loadSysIrrigationSucces: (sysIrrigations: {[key: string]: SysIrrigation}) => {
    return {
      type: LOAD_SYS_IRRIGATION_SUCCESS,
      payload: sysIrrigations
    }
  }
};

export default actions;