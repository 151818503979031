import {ModifyUserInfoModel} from "../models/ModifyUserInfoModel";

export const defaultUserInfo: ModifyUserInfoModel = {
    lastname: '',
    firstname: '',
    email: '',
    phone: '',
    organism: '',
    taxNumber: '',
    postal_code: '',
    address: '',
    city: '',
    country: '',
}
