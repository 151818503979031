import {
  CropsActionTypes,
  LOAD_CROPS_LISTING,
  LOAD_CROPS_LISTING_SUCCESS,
} from '../actions/CropsActions';
import {Reducer} from 'redux';
import CropsState from '../models/CropsState';

const initialState: CropsState = {
  data: {}
};

const cropsReducers: Reducer<CropsState, CropsActionTypes> = (state: CropsState = initialState, action: CropsActionTypes) => {
  switch (action.type) {
    case LOAD_CROPS_LISTING:
      return {
        ...state,
      };
    case LOAD_CROPS_LISTING_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default cropsReducers;
