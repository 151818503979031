import WaterBalanceState from '../models/WaterBalanceState';
import NewDataModel from '../models/NewDataModel';
import DeleteDataModel from '../models/DeleteDataModel';
import ModifyDataModel from '../models/ModifyDataModel';

export const LOAD_WATER_BALANCE = 'LOAD_WATER_BALANCE';
export interface LoadWaterBalanceAction {
  type: typeof LOAD_WATER_BALANCE;
  payload: {
    fieldId: number;
    rotationId: number;
    forceCompute?:boolean;
  };
}

export const LOAD_WATER_BALANCE_SUCCESS = 'LOAD_WATER_BALANCE_SUCCESS';
export interface LoadWaterBalanceSuccessAction {
  type: typeof LOAD_WATER_BALANCE_SUCCESS;
  payload: WaterBalanceState;
}

export const SET_SELECTED_NDVI_DATE = 'SET_SELECTED_NDVI_DATE';
export interface SetSelectedNdviDateAction {
  type: typeof SET_SELECTED_NDVI_DATE;
  payload: string;
}

export const CLEAR_WATER_BALANCE_DATA = 'CLEAR_WATER_BALANCE_DATA';
export interface ClearWaterBalanceDataAction {
  type: typeof CLEAR_WATER_BALANCE_DATA;
}

export const ADD_IRRIGATION = 'ADD_IRRIGATION';
export interface AddIrrigationAction {
  type: typeof ADD_IRRIGATION;
  payload: NewDataModel;
}

export const DELETE_IRRIGATION = 'DELETE_IRRIGATION';
export interface DeleteIrrigationAction {
  type: typeof DELETE_IRRIGATION;
  payload: DeleteDataModel;
}

export const MODIFY_IRRIGATION = 'MODIFY_IRRIGATION';
export interface ModifyIrrigationAction {
  type: typeof MODIFY_IRRIGATION;
  payload: ModifyDataModel;
}

export const ADD_RAIN = 'ADD_RAIN';
export interface AddRainAction {
  type: typeof ADD_RAIN;
  payload: NewDataModel;
}

export const DELETE_RAIN = 'DELETE_RAIN';
export interface DeleteRainAction {
  type: typeof DELETE_RAIN;
  payload: DeleteDataModel;
}

export const MODIFY_RAIN = 'MODIFY_RAIN';
export interface ModifyRainAction {
  type: typeof MODIFY_RAIN;
  payload: ModifyDataModel;
}

export type WaterBalanceActionTypes =
  | LoadWaterBalanceAction
  | LoadWaterBalanceSuccessAction
  | SetSelectedNdviDateAction
  | ClearWaterBalanceDataAction
  | AddIrrigationAction
  | DeleteIrrigationAction
  | ModifyIrrigationAction
  | AddRainAction
  | DeleteRainAction
  | ModifyRainAction;

export const actions = {
  loadWaterBalance: (
    fieldId: number,
    rotationId: number,
    forceCompute?:boolean
  ): LoadWaterBalanceAction => {
    return {
      type: LOAD_WATER_BALANCE,
      payload: {fieldId, rotationId, forceCompute},
    };
  },

  loadWaterBalanceSuccessAction: (
    payload: WaterBalanceState,
  ): LoadWaterBalanceSuccessAction => {
    return {
      type: LOAD_WATER_BALANCE_SUCCESS,
      payload: payload,
    };
  },

  setSelectedNdviDate: (date: string): SetSelectedNdviDateAction => {
    return {
      type: SET_SELECTED_NDVI_DATE,
      payload: date,
    };
  },

  clearWaterBalanceData: (): ClearWaterBalanceDataAction => {
    return {
      type: CLEAR_WATER_BALANCE_DATA,
    };
  },

  addIrrigation: (newIrrigation: NewDataModel): AddIrrigationAction => {
    return {
      type: ADD_IRRIGATION,
      payload: newIrrigation,
    };
  },

  deleteIrrigation: (
    selectedIrrigation: DeleteDataModel,
  ): DeleteIrrigationAction => {
    return {
      type: DELETE_IRRIGATION,
      payload: selectedIrrigation,
    };
  },

  modifyIrrigation: (
    modifiedIrrigation: ModifyDataModel,
  ): ModifyIrrigationAction => {
    return {
      type: MODIFY_IRRIGATION,
      payload: modifiedIrrigation,
    };
  },

  addRain: (newRain: NewDataModel): AddRainAction => {
    return {
      type: ADD_RAIN,
      payload: newRain,
    };
  },

  deleteRain: (
    selectedRain: DeleteDataModel,
  ): DeleteRainAction => {
    return {
      type: DELETE_RAIN,
      payload: selectedRain,
    };
  },

  modifyRain: (
    modifiedRain: ModifyDataModel,
  ): ModifyRainAction => {
    return {
      type: MODIFY_RAIN,
      payload: modifiedRain,
    };
  },
};

export default actions;
