import {Box, Typography} from '@material-ui/core';
import React from 'react';
import IntlMessages from 'shared/components/IntlMessages';



export default function HelperTextFreeTrialExist() {
    return (
        <Box display='flex' flexDirection='column' alignItems='center' width={'80%'}>
            <Typography variant='subtitle1' style={{fontWeight: 'bold'}} color='error'>
                <IntlMessages id="subscription.price.disable.title" />
            </Typography>
            <Typography style={{textAlign: 'center'}}>
                <IntlMessages id="subscription.price.disable.already_freeTrial_subscription_reason" />
            </Typography>
        </Box>
    )
}