import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogProps,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IntlMessages from 'shared/components/IntlMessages';
import {CremaTheme} from 'shared/models/AppContextPropsType';
import {Fonts} from 'shared/configs/AppEnums';
import Loader from './Loader';
import {useSelector} from 'react-redux';
import {AppState} from '../store/index';

const useStyles = makeStyles((theme: CremaTheme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  title: {
    fontFamily: [Fonts.BOLD, 'sans-serif'].join(','),
    paddingRight: theme.spacing(2)
  },
}));

/**
 * props for the base dialog component
 */
interface BaseDialogProps extends DialogProps {
  /**
   * The react intl id for the localized title
   */
  titleMessageId: string;

  /**
   * the react intl id for the localized
   * If defined, a button is added
   */
  buttonMessageId?: string;
  
  /*
   *  
   */
  handleValidate?: any;
}

/**
 * The base dialog allowing to share default style:
 * - localized title
 * - close button, etc.
 * @param props
 */
const BaseDialog: React.FC<BaseDialogProps> = (props) => {
  const classes = useStyles();

  const {loading} = useSelector<AppState, AppState['common']>(
    ({common}) => common,
  );

  const handleClose = () => {
    if (props.onClose) {
      props.onClose({}, 'escapeKeyDown');
    }
  };

  const {titleMessageId, buttonMessageId, handleValidate, ...otherProps} = props;

  return (
    <Dialog {...otherProps}>
      {loading && <Loader />}
      <DialogTitle disableTypography>
        <Typography variant='h5' classes={{h5: classes.title}}>
          <IntlMessages id={titleMessageId} />
        </Typography>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      {buttonMessageId ? (
        <DialogActions>
          <Button color='primary' variant={handleValidate ? 'contained':'text'} onClick={handleValidate ? handleValidate : handleClose}>
            <IntlMessages id={buttonMessageId} />
          </Button>
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

export default BaseDialog;
