import {AppContext} from '@crema';
import {Box, ButtonBase, Typography} from '@material-ui/core';
import React, {useContext} from 'react';
import IntlMessages from 'shared/components/IntlMessages';
import AppContextPropsType from 'shared/models/AppContextPropsType';
import log from 'shared/services/LogService';
import {Interval} from '../models/IntervalEnum';
import Price from '../models/Price';
import Product from '../models/Product';
// import {currencySymbol} from '../services/currencySymbol';
import PriceTooltip from './PriceTooltip';
import useStyles from './ProductStyle';
import TablePrice from './TablePrice';
import {FREE_TRIAL_PRODUCT_REF, STRIPE_METADATA_} from '../../subscriptions/configs/constant';
import {AREA_UNIT, STRIPE_METADATA_DESCRIPTION_PREFIX} from '../configs/constant';
import {priceToDisplay} from '../services/currencySymbol';
import {computeLowerPrice, computePrice, reducePriceToOneMonth} from '../services/computedLowerPrice';
import HelperTextQuantityDisable from './HelperTextQuantityDisable';
import {useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import WagoSubscription from 'modules/subscriptions/models/WagoSubscription';
import HelperTextFreeTrialExist from './HelperTextFreeTrialExist';

export enum OfferCardType {
    PRICE = 'price',
    PRODUCT = 'product'
}

interface OfferCardProps {
    totalArea: number;
    item: Price | Product;
    selected?: boolean;
    type: OfferCardType;
    setSelected: any;
    productNameSelected?: string;
}

/**
 * Display a card for a stripe product or price offer
 * @param param0 D
 * @returns 
 */
export default function OfferCard({item, selected, type, setSelected, productNameSelected, totalArea}: OfferCardProps) {

    const classes = useStyles();
    const {locale} = useContext<AppContextPropsType>(AppContext);
    log.debug(locale)

    const {wagoData} = useSelector<AppState, AppState['subscriptions']>(({subscriptions}) => subscriptions)

    const ButtonClassSelection = () => {
        if (selected) {
            return classes.selectedButton
        } else {
            return classes.unSelectedButton
        }
    }

    let itemToDisplay: Price | Product = item;
    let maxQuantity = item.maxQuantity;
    let minQuantity = item.minQuantity;
    if (type === OfferCardType.PRODUCT) {
        const productItem = itemToDisplay as Product;
        // Only one price, display directly the price
        if (productItem.prices?.length === 1) {
            type = OfferCardType.PRICE;
            productNameSelected = productItem.name;
            itemToDisplay = productItem.prices[0];
            log.info(`Only one price for product ${productItem.ref}, using it`, itemToDisplay)
            // Adapt min/max quantity if redefined at the price level
            if (itemToDisplay.maxQuantity) {
                maxQuantity = itemToDisplay.maxQuantity;
            }
            if (itemToDisplay.minQuantity) {
                minQuantity = itemToDisplay.minQuantity;
            }
        }
    }


    const productName = (itemToDisplay: Product) => {
        return itemToDisplay.name
    }

    const priceName = (itemToDisplay: Price) => {
        const interval_count = itemToDisplay.interval_count ? itemToDisplay.interval_count : 1
        const priceName = `${productNameSelected} ${interval_count} `;
        return (
            <>
                {priceName} <IntlMessages id={`subscriptions.stepper.price.interval.${itemToDisplay.interval}`} />
            </>
        )
    }
    /**
     * Define the lower price to display for a product (prices and tiers list comparison)
     * if all prices are 0 => display free or display the first lower price different from 0
     * @param itemToDisplay 
     * @returns 
     */
    const productFirstPriceToDisplay = (itemToDisplay: Product, totalArea: number) => {
        const prices = itemToDisplay.prices

        if (prices && prices.length) {
            const lowerPrice = computeLowerPrice(itemToDisplay, totalArea);
            if (lowerPrice) {
                // Price per month for the whole surface
                const pricePerMonth = priceToDisplay(lowerPrice, itemToDisplay.currency);
                // Price per Ha
                const reducedPrice = lowerPrice / totalArea;
                const pricePerMonthPerHa = priceToDisplay(reducedPrice, itemToDisplay.currency);
                return (
                    <>
                        <IntlMessages id="subscriptions.stepper.price.from_price" />
                        <Box>
                            {` ${pricePerMonth} /`}
                            <IntlMessages id="subscriptions.stepper.price.interval.month" />
                        </Box>
                        <Typography>
                            <IntlMessages id="subscriptions.stepper.price.comparison" />
                            {` ${pricePerMonthPerHa}/${AREA_UNIT}/`}
                            <IntlMessages id={`subscriptions.stepper.price.interval.${Interval.MONTH}`} />
                        </Typography>
                    </>
                )
            } else {
                return (
                    <IntlMessages id="subscriptions.stepper.price.free" />
                )
            }
        } else {
            log.debug(`No prices found for product ${itemToDisplay.name}`)
            return null
        }
    }


    const firstPriceToDisplay = (itemToDisplay: Price, totalArea: number) => {
        const intervalCount = itemToDisplay.interval_count !== 1 ? itemToDisplay.interval_count : "";
        const computedPrice = computePrice(itemToDisplay, totalArea)

        if (computedPrice) {
            const computedPriceToDisplay = priceToDisplay(computedPrice, itemToDisplay.currency);
            const reducedPrice = reducePriceToOneMonth(computedPrice / totalArea, itemToDisplay.interval_count, itemToDisplay.interval);
            const reducedPriceToDisplay = priceToDisplay(reducedPrice, itemToDisplay.currency);
            return (
                <>
                    <Box>
                        {`${computedPriceToDisplay} /${intervalCount} `}
                        <IntlMessages id={`subscriptions.stepper.price.interval.${itemToDisplay.interval}`} />
                        <PriceTooltip table={<TablePrice item={itemToDisplay} type={type} />} />
                    </Box>
                    <Typography>
                        <IntlMessages id="subscriptions.stepper.price.comparison" />
                        {` ${reducedPriceToDisplay}/${AREA_UNIT}/`}
                        <IntlMessages id={`subscriptions.stepper.price.interval.${Interval.MONTH}`} />
                    </Typography>
                </>
            )
        } else {
            return (
                <IntlMessages id="subscriptions.stepper.price.free" />
            )
        }
    }

    let description = itemToDisplay.description;
    const localizedDescriptionMetadata = `${STRIPE_METADATA_}${STRIPE_METADATA_DESCRIPTION_PREFIX}${locale.locale}`;
    if (itemToDisplay[localizedDescriptionMetadata]) {
        description = itemToDisplay[localizedDescriptionMetadata];
        log.debug(`Metadata ${localizedDescriptionMetadata} found for ${type} ${itemToDisplay.id}: ${description}`);
    } else {
        log.warn(`No metadata ${localizedDescriptionMetadata} for ${type} ${itemToDisplay.id}`);
    }
    /**
     * position : Free trial offer is first a product with ref : FREE_TRIAL_PRODUCT_REF
     * all free trial price ref include FREE_TRIAL_PRODUCT_REF
     * if one wagoSubscriptions with productref == FREE_TRIAL_PRODUCT_REF then all free_trial prices are disable
     * @param wagoData 
     * @returns 
     */
    const isAlreadyFreeTrial = (wagoData: {[key: string]: WagoSubscription}) => {
        const subscriptionsList: WagoSubscription[] = Object.values(wagoData);
        const freeTrialSubscriptions = subscriptionsList.find(sub => sub.product_ref === FREE_TRIAL_PRODUCT_REF)

        if (freeTrialSubscriptions && type === OfferCardType.PRICE && itemToDisplay.ref.includes(FREE_TRIAL_PRODUCT_REF)) {
            return true
        } else {
            return false
        }
    }
    let freeTrial: boolean = false
    if (wagoData) {
        freeTrial = isAlreadyFreeTrial(wagoData)
    }
    const over = (maxQuantity !== undefined && totalArea > maxQuantity);
    const less = (minQuantity !== undefined && totalArea < minQuantity);
    const isDisabled = over || less || freeTrial;
    log.debug(`Checking item ${item.ref} quantity ${totalArea} for max ${maxQuantity} and min ${minQuantity}: ${isDisabled}`)

    return (
        <Box display='flex' flexDirection='column' alignItems="center">
            <ButtonBase onClick={() => setSelected(item)} disabled={isDisabled} className={[classes.buttonBase, classes.flexColumnStretch, ButtonClassSelection(), isDisabled ? classes.disabled : ""].join(' ')}>
                <Box className={classes.cardHeader}>
                    <Typography variant='subtitle1' className={classes.productName}>
                        {type === OfferCardType.PRODUCT ? productName(itemToDisplay as Product) : priceName(itemToDisplay as Price)}
                    </Typography>
                </Box>
                <Box className={[classes.flexColumnStretch, classes.cardContent].join(' ')}>
                    {type === OfferCardType.PRICE && (maxQuantity || minQuantity) ? (
                        <Box className={classes.cardElement}>
                            <Typography variant='subtitle1' className={classes.maxQuantity}>
                                {maxQuantity ? `${maxQuantity} ${AREA_UNIT} max` : (minQuantity ?
                                    `${minQuantity} ${AREA_UNIT} min` : <IntlMessages id="subscriptions.stepper.price.max_quantity_area.unlimited" />
                                )}
                            </Typography>
                        </Box>
                    ) : <Box pl={2} pr={2}>{description}</Box>}
                    <Box className={classes.cardElement}>
                        <Typography variant='subtitle1' className={classes.productPrice}>
                            {type === OfferCardType.PRODUCT ? productFirstPriceToDisplay(itemToDisplay as Product, totalArea) : firstPriceToDisplay(itemToDisplay as Price, totalArea)}
                        </Typography>
                    </Box>
                </Box>
            </ButtonBase>
            {isDisabled && (over || less) ? (<HelperTextQuantityDisable quantity={over ? maxQuantity : minQuantity} over={over} />) :
                isDisabled && freeTrial ? (<HelperTextFreeTrialExist />) : ""}

        </Box>

    )
}
