import {indexPath} from 'modules/campaigns/configs/CampaignsRoutePaths';

/**
 * The app initial url: current campaign fields overview
 */
export const initialUrl = indexPath; // this url will open after login

/**
 * Mapbox access token extracted from env
 */
export const mapToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

/**
 * General constant used to wait for server process before refreshing data
 */
export const timeoutDuration = 1000;

/**
 * Cloud functions location
 */
export const FUNCTION_LOCATION = "europe-west1";
