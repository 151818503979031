import React from 'react';

export const authRouteConfigs = [
  {
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./components/Signin/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup',
        component: React.lazy(() => import('./components/Signup/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./components/ForgetPassword')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/users',
        component: React.lazy(() => import('./components/ManageUsers')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../../shared/components/errorPages/Error404/index')),
      },
    ],
  },
];
