import {indexPath, selectedCampaignParam, selectedCampaignRootPath} from 'modules/campaigns/configs/CampaignsRoutePaths';

/**
 * Campaign fields path
 */
export const selectedCampaignFieldsPath = `${selectedCampaignRootPath}/fields`

/**
 * Get the campaign field overview path or inital path if the campaign is not defined
 * @param campaignId 
 */
export const getCampaignFieldsPath = function (campaignId: number | null): string {
    if (campaignId) {
        return selectedCampaignFieldsPath.replace(`:${selectedCampaignParam}`, campaignId.toString());
    }
    return indexPath;
}

export const getCampaignFieldOverviewPath = function (campaignId: number, fieldId: number): string {
    return selectedFieldRootPath.replace(`:${selectedCampaignParam}`, campaignId.toString()).replace(`:${selectedFieldParam}`, fieldId.toString());
}

export const getCampaignFieldEditPath = function (campaignId: number, fieldId: number): string {
    return selectedFieldParametersPath.replace(`:${selectedCampaignParam}`, campaignId.toString()).replace(`:${selectedFieldParam}`, fieldId.toString());
}

/**
 * Field parameter
 */
export const selectedFieldParam = 'fieldId';

/**
 * Tab parameter
 */
export const selectedFieldTabParam = 'tabId';

/**
 * Path for selected field overview
 */
export const selectedFieldRootPath = `${selectedCampaignFieldsPath}/:${selectedFieldParam}`;
export const selectedFieldGenericPath = `${selectedFieldRootPath}/:${selectedFieldTabParam}?`;

/**
 * Path for selected field ndvi page
 */
export const selectedFieldTabNdvi = 'ndvi';
export const selectedFieldNdviPath = `${selectedFieldRootPath}/${selectedFieldTabNdvi}`;

/**
 * Path to edit field parameters
 */
export const selectedFieldTabParameters = 'edit';
export const selectedFieldParametersPath = `${selectedFieldRootPath}/${selectedFieldTabParameters}`;

/**
 * Path to view field irrigation graph
 */
export const selectedFieldTabIrrigation = 'irrigation';
export const selectedFieldIrrigationPath = `${selectedFieldRootPath}/${selectedFieldTabIrrigation}`;

/**
 * Path to view field weather detail forecast
 */
export const selectedFieldTabWeather = 'weather';
export const selectedFieldWeatherPath = `${selectedFieldRootPath}/${selectedFieldTabWeather}`;
