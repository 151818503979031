import React from 'react';
import {actions} from '../../actions/AuthActions';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';
import IntlMessages from 'shared/components/IntlMessages';
import {Box, Button, Icon} from '@material-ui/core';
import {ChangeUserPasswordModel} from '../../../auth/models/ChangeUserPasswordModel';
import {Form, Formik} from 'formik';
import useStyles from '../../../../shared/components/layout/HeaderButton.style';
import {useIntl} from 'react-intl';
import MyTextField from 'shared/components/MyTextField';

interface ChangePasswordProps {
  /**
   * Callback when the submit button is pressed
   */
  handleModalChangePassword: () => void;
}

/**
 * Change password form
 * Does not include the modal
 * @param param0
 */
const ChangePassword: React.FC<ChangePasswordProps> = ({
  handleModalChangePassword,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {messages} = useIntl();

  const passwordChangeInfo: ChangeUserPasswordModel = {
    old_password: '',
    password: '',
    confirmPassword: '',
  };

  const [viewOldPassword, setViewOldPassword] = React.useState<boolean>(false);
  const [viewPassword, setViewPassword] = React.useState<boolean>(false);
  const [viewConfirmPassword, setViewConfirmPassword] = React.useState<boolean>(
    false,
  );

  const validationSchema = yup.object({
    old_password: yup
      .string()
      .required(messages['modifyUser.validation.oldPassword'] as string),
    password: yup
      .string()
      .max(30, messages['form.validation.maxString30'] as string)
      .required(messages['modifyUser.validation.newPassword'] as string),
    confirmPassword: yup
      .string()
      .max(30, messages['form.validation.maxString30'] as string)
      .oneOf(
        [yup.ref('password')],
        messages['validation.passwordMisMatch'] as string,
      )
      .required(messages['validation.reTypePassword'] as string),
  });

  return (
    <>
      <Box width={400} className={classes.userForm}>
        <Formik
          initialValues={passwordChangeInfo}
          validationSchema={validationSchema}
          onSubmit={(data: ChangeUserPasswordModel) => {
            const dataToSend: ChangeUserPasswordModel = {
              old_password: data.old_password,
              password: data.password,
            };
            dispatch(actions.changeUserPassword(dataToSend));
            handleModalChangePassword();
          }}>
          <Form autoComplete='off'>
            <Box className={classes.boxesTextForm}>
              <MyTextField
                type={viewOldPassword ? 'text' : 'password'}
                placeholder={messages['modifyUser.oldPassword']}
                label={<IntlMessages id='modifyUser.oldPassword' />}
                name='old_password'
              />
              <Box className={classes.boxesViewPassword}>
                <Icon
                  role='button'
                  onClick={() => setViewOldPassword(!viewOldPassword)}>
                  {viewOldPassword ? 'visibility_off' : 'visibility'}
                </Icon>
              </Box>
            </Box>
            <Box className={classes.boxesTextForm}>
              <MyTextField
                type={viewPassword ? 'text' : 'password'}
                placeholder={messages['modifyUser.newPassword']}
                label={<IntlMessages id='modifyUser.newPassword' />}
                name='password'
              />
              <Box className={classes.boxesViewPassword}>
                <Icon
                  role='button'
                  onClick={() => setViewPassword(!viewPassword)}>
                  {viewPassword ? 'visibility_off' : 'visibility'}
                </Icon>
              </Box>
            </Box>
            <Box className={classes.boxesTextForm}>
              <MyTextField
                type={viewConfirmPassword ? 'text' : 'password'}
                placeholder={messages['common.retypePassword']}
                label={<IntlMessages id='common.retypePassword' />}
                name='confirmPassword'
              />
              <Box className={classes.boxesViewPassword}>
                <Icon
                  role='button'
                  onClick={() => setViewConfirmPassword(!viewConfirmPassword)}>
                  {viewConfirmPassword ? 'visibility_off' : 'visibility'}
                </Icon>
              </Box>
            </Box>
            <Box className={classes.modifyPasswordButtonContainer}>
              <Button
                variant='contained'
                color='primary'
                type='submit'
                className={classes.formUserButton}>
                <IntlMessages id='modifyUser.submitButton' />
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </>
  );
};

export default ChangePassword;
