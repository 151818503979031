import React from 'react';
import {RouteConfig} from 'react-router-config';
import {indexPath} from './configs/CampaignsRoutePaths';

export const campaignsRouteConfigs: RouteConfig[] = [
  {
    auth: ['user'],
    routes: [
      {
        path: indexPath,
        exact: true,
        component: React.lazy(() => import('./components/CampaignsRedirect')),
      },
    ],
  },
];
