import {takeEvery, all, put, select, call} from "@redux-saga/core/effects";
import rsf from "shared/services/ReduxSagaFirebase";
import {AppState} from "shared/store";
import * as FirebaseFirestore from '@firebase/firestore-types';
import {actions as SubscriptionsActions, LoadInvoicesInfoAction, LOAD_INVOICES_INFO, LOAD_SUBSCRIPTIONS_LISTING} from "../actions/SubscriptionsActions";
import Subscription from "../models/Subscription";
import api, {wagoApiUrl} from "shared/services/ApiConfig";
import {AxiosResponse} from "axios";
import WagoSubscription from "../models/WagoSubscription";
import Stripe from "stripe";
import {SelectedSubscriptionState} from "../models/SubscriptionsState";
import {fetchError, fetchStart, fetchSuccess} from "shared/actions";
import log from "shared/services/LogService";
import {appIntl} from "shared/utils/IntlGlobalProvider";

function getSubscriptionsRequest(): Promise<AxiosResponse<WagoSubscription[]>> {
    const subsriptionsUrl = `${wagoApiUrl}/subscriptions`;
    return api.get(subsriptionsUrl);
}

function* loadSubscriptions() {

    try {
        const {user} = yield select((state: AppState) => state.auth)
        const uid = user.uid

        // Fetch from wago
        let wagoSubscriptions: {[key: number]: WagoSubscription} = {};
        const wagoSubscriptionsResp: AxiosResponse<WagoSubscription[]> = yield call(getSubscriptionsRequest);
        if (wagoSubscriptionsResp && wagoSubscriptionsResp.data) {
            const wagoSubscriptionsList = wagoSubscriptionsResp.data;

            wagoSubscriptionsList.forEach((wagoSubscription) => {
                if (wagoSubscription.sub_id) {
                    wagoSubscriptions[wagoSubscription.sub_id] = wagoSubscription;
                }
            })
        }

        // Fetch from firebase
        const subscriptionsSnapShot: FirebaseFirestore.QuerySnapshot = yield call(rsf.firestore.getCollection, `customers/${uid}/subscriptions`)
        let subscriptions: {[key: string]: Subscription} = {};
        subscriptionsSnapShot.forEach((data) => {
            const subscriptionData = data.data() as Subscription;
            subscriptionData.id = data.id;
            subscriptions[data.id] = subscriptionData;
        })

        yield put(SubscriptionsActions.loadSubscriptionsSuccess(subscriptions, wagoSubscriptions))
    } catch (error) {
        log.error(error);
    }
}

function* loadInvoicesInfoSaga(action: LoadInvoicesInfoAction) {
    const subscriptionId = action.payload;
    try {
        yield put(fetchStart("loadInvoicesInfoSaga"));
        const {user} = yield select((state: AppState) => state.auth)
        const uid = user.uid

        const invoicesSnapshot: FirebaseFirestore.QuerySnapshot = yield call(rsf.firestore.getCollection, `customers/${uid}/subscriptions/${subscriptionId}/invoices`)
        const selectedSubscription: SelectedSubscriptionState = {
            subscriptionId: subscriptionId,
            invoices: {} as {[key: string]: Stripe.Invoice}
        }
        invoicesSnapshot.forEach(snap => {
            const invoiceId = snap.id as string
            const invoiceData = snap.data() as Stripe.Invoice
            selectedSubscription.invoices = {
                ...selectedSubscription.invoices,
                [invoiceId]: invoiceData
            }
        })

        yield put(SubscriptionsActions.loadInvoicesInfoSuccess(selectedSubscription))
        yield put(fetchSuccess("loadInvoicesInfoSaga"));
    } catch (error) {
        log.error(`Error fetching invoices for subscription ${subscriptionId}`, error);
        yield put(fetchError("loadInvoicesInfoSaga", appIntl().formatMessage({id: "subscriptions.load_invoices_error"})));
    }
}

export function* subscriptionsSaga() {
    yield all([
        takeEvery(LOAD_SUBSCRIPTIONS_LISTING, loadSubscriptions),
        takeEvery(LOAD_INVOICES_INFO, loadInvoicesInfoSaga)
    ])
}