import {makeStyles} from '@material-ui/core/styles';
import {CremaTheme} from '../../models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  headerBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 30,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',

    '&.selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    [theme.breakpoints.up('sm')]: {
      height: 46,
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      width: 'auto',
      borderRight: 'solid 1px',
      borderRightColor: theme.palette.grey[200],
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
  headerBtnIcon: {
    fontSize: 22,
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
    },
  },
  headerBtnTitle: {
    fontSize: 16,
    marginLeft: theme.spacing(2),
  },
  userForm: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formUserButton: {
    margin: '1em',
  },
  modifyPasswordButtonContainer: {
    textAlign: 'center',
  },
  textFormContainer: {
    display: 'flex',
    margin: '1em',
    alignItems: 'flex-start',
  },
  boxesTextForm: {
    display: 'flex',
    alignContent: 'space-between',
    margin: '1em',
  },
  boxesTextFormPassword: {
    display: 'flex',
    justifyContent: 'center',
  },
  boxesViewPassword: {
    alignSelf: 'flex-end',
    marginLeft: '1em',
  },
  fullWidth: {
    width: '100%'
  }
}));

export default useStyles;
