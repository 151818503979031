import saMessages from '../locales/es_ES.json';
import {esES} from '@material-ui/core/locale';

const esLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: esES,
  locale: 'es-ES',
};
export default esLang;
