import React, {useState} from 'react';
import {AppBar, Hidden} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LanguageSwitcher from '../LanguageSwitcher';
import Box from '@material-ui/core/Box';
import useStyles from './AppHeader.style';
import CropsHeaderButton from '../../../modules/crops/components/CropsHeaderButton';
import SoilsHeaderButton from '../../../modules/soils/components/SoilsHeaderButton';
import UserHeaderButton from '../../../modules/auth/components/UserHeaderButton';
import FieldsHeaderButton from '../../../modules/fields/components/FieldsHeaderButton';
import HeaderLogo from './HeaderLogo';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import {onToggleAppDrawer} from 'shared/actions/Common';
import ManageUsersHeaderButton from 'modules/auth/components/ManageUsersHeaderButton';

interface AppHeaderProps { }

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState<null | HTMLElement>(null);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}>
      <MenuItem className={classes.menuItem} onClick={handleMobileMenuClose}>
        <FieldsHeaderButton alwaysShowTitle />
      </MenuItem>
      <MenuItem className={classes.menuItem} onClick={handleMobileMenuClose}>
        <CropsHeaderButton alwaysShowTitle />
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <SoilsHeaderButton alwaysShowTitle />
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <ManageUsersHeaderButton />
      </MenuItem>
      <MenuItem>
        <UserHeaderButton
          alwaysShowTitle
          handleMobileMenuClose={handleMobileMenuClose}
        />
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar color='primary' className={clsx(classes.appBar, 'app-bar')}>
        <Toolbar className={classes.appToolbar}>
          <Hidden lgUp>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
              onClick={() => dispatch(onToggleAppDrawer())}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
          <HeaderLogo />
          <Box className={classes.grow} />
          <Box className={classes.sectionDesktop}>
            <FieldsHeaderButton />
            <CropsHeaderButton />
            <SoilsHeaderButton />
            <ManageUsersHeaderButton />
            <UserHeaderButton />
          </Box>
          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'>
              <MoreIcon />
            </IconButton>
          </Box>
          <LanguageSwitcher localeOnly />
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};
export default AppHeader;
