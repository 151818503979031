import {Box, Typography, useTheme} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import FieldSelectionTable from './SubscriptionFieldSelectionTable';
import ReactMapGL, {Layer, Source, PointerEvent} from 'react-map-gl';
import {mapToken} from '../../../shared/configs/AppConst';
import Field from '../../fields/models/Field';
import IntlMessages from 'shared/components/IntlMessages';
import useStepperStyles from 'modules/subscriptions/components/StepperStyle';
import {computeTotalAreaHa} from 'modules/fields/services/FieldsService';
import Campaign from 'modules/campaigns/models/Campaign';
import MapPosition from 'modules/fields/models/MapPosition';
import {generateFieldMapSelectionPolygonStyle, generateFieldMapSelectionSymbolStyle} from '../configs/FieldMapSelectionStyles';
import useMapIcons from 'shared/hooks/useMapIcons';
import {Feature, FeatureCollection} from 'geojson';
import bbox from '@turf/bbox';
import log from 'shared/services/LogService';
import useDimensions from 'react-use-dimensions';
import _ from 'lodash';
import {ZOOM_LEVEL_MARGIN_OFFSET} from 'modules/fields/components/FieldsMap';
import {SubscriptionsStatus} from '../models/SubscriptionStatus';
const geoViewport = require('@mapbox/geo-viewport');

interface FieldSelectionStepProps {
    initialMapPosition?: MapPosition;
    selectedFields: Field[];
    onSelectedFieldsChange: (field: Field[]) => void,
    selectedCampaign?: Campaign;
    setSelectedCampaign: any;
}
//TODO: Map interactive to select fields
export default function FieldSelectionStep({initialMapPosition, selectedFields, onSelectedFieldsChange, selectedCampaign, setSelectedCampaign}: FieldSelectionStepProps) {

    const theme = useTheme();
    const classes = useStepperStyles();
    const [ref, {width, height}] = useDimensions();

    // Initialize viewport state
    const initialViewPort = {
        latitude: 46.23219,
        longitude: 2.20966,
        zoom: 5
    };
    if (initialMapPosition) {
        initialViewPort.latitude = initialMapPosition.latitude;
        initialViewPort.longitude = initialMapPosition.longitude;
        initialViewPort.zoom = initialMapPosition.zoom;
    }

    const [viewport, setViewport] = useState(initialViewPort);
    const [displayedFieldsList, setDisplayedFieldsList] = useState<Field[]>([])
    const [displayedFieldsGeojson, setDisplayedFieldsGeojson] = useState<{
        geojson: FeatureCollection;
        centroidGeojson: FeatureCollection;
        bbox: number[];
    } | null>(null)

    const mapRef = useRef<ReactMapGL>(null);
    useMapIcons(mapRef);

    const handleViewportChange = (newViewport: any) => {
        const {width, height, ...mapPosition} = newViewport;
        setViewport(mapPosition)
    };

    useEffect(() => {

        //let bboxFeature: Feature | null = null;
        //let targetZoomBbox: number[] | null = null;
        if (displayedFieldsList) {
            log.debug(`Refreshing displayed field list geojson with ${displayedFieldsList.length} fields`);
            // Build the geojson with fields and MapNdvi
            const fieldsGeojson: FeatureCollection = {
                type: 'FeatureCollection',
                features: [],
            };
            const fieldsCentroidGeojson: FeatureCollection = {
                type: 'FeatureCollection',
                features: [],
            };
            displayedFieldsList.forEach((field) => {
                if (field) {
                    const hovered = false;//field.field_id === hoveredState.featureId;
                    let selected = undefined;
                    const subscriptionStatus = field.last_subscription?.status;
                    if (subscriptionStatus !== SubscriptionsStatus.ACTIVE) {
                        selected = selectedFields.findIndex((selectedField) => field.field_id === selectedField.field_id) >= 0;
                    }

                    if (field.centroid) {
                        const centroidFeature: Feature = {
                            type: 'Feature',
                            id: field.field_id,
                            geometry: {
                                type: 'Point',
                                coordinates: field.centroid,
                            },
                            properties: {
                                hovered: hovered,
                                selected: selected,
                                subscriptionStatus: subscriptionStatus,
                            },
                        };
                        fieldsCentroidGeojson.features.push(centroidFeature);
                    }

                    if (field.geojson_geometry) {
                        const feature: Feature = {
                            type: 'Feature',
                            id: field.field_id,
                            geometry: field.geojson_geometry,
                            properties: {
                                hovered: hovered,
                                selected: selected,
                                subscriptionStatus: subscriptionStatus,
                            },
                        };
                        fieldsGeojson.features.push(feature);
                    }
                }
            });

            const fieldsBbox = bbox(fieldsGeojson);
            zoomToBbox(fieldsBbox, width, height);

            setDisplayedFieldsGeojson({geojson: fieldsGeojson, centroidGeojson: fieldsCentroidGeojson, bbox: fieldsBbox});
        } else {
            setDisplayedFieldsGeojson(null);
        }

    }, [displayedFieldsList]);

    /**
     * Refresh selected fields
     */
    useEffect(() => {

        //log.info(`Selected fields change ${selectedFields.length}`, selectedFields);

        if (displayedFieldsGeojson) {
            // Deep clone to force a refresh
            const newGeojson = _.cloneDeep(displayedFieldsGeojson.geojson);
            const newCentroidGeojson = _.cloneDeep(displayedFieldsGeojson.centroidGeojson);
            if (newGeojson && newGeojson.features) {
                newGeojson.features.forEach((feature) => {
                    if (feature && feature.properties) {
                        if (feature.properties.SubscriptionStatus !== SubscriptionsStatus.ACTIVE) {
                            const selected = selectedFields.findIndex((selectedField) => feature.id === selectedField.field_id) >= 0;
                            feature.properties.selected = selected;
                        }
                    }
                })
            }
            if (newCentroidGeojson && newCentroidGeojson.features) {
                newCentroidGeojson.features.forEach((feature) => {
                    if (feature && feature.properties) {
                        if (feature.properties.SubscriptionStatus !== SubscriptionsStatus.ACTIVE) {
                            const selected = selectedFields.findIndex((selectedField) => feature.id === selectedField.field_id) >= 0;
                            feature.properties.selected = selected;
                        }
                    }
                })
            }
            log.debug(`Refreshing features`);
            setDisplayedFieldsGeojson({geojson: newGeojson, centroidGeojson: newCentroidGeojson, bbox: displayedFieldsGeojson.bbox});
        }

    }, [selectedFields]);

    /**
     * Handle click on feature on map: dispatch an event to select another field
     * @param event
     */
    const onClick = (event: PointerEvent) => {
        const {features} = event;

        if (features && features.length > 0) {
            const firstClickedFeature = features[0];
            const field = displayedFieldsList.find((displayedField) => displayedField.field_id === firstClickedFeature.id)
            if (field && (!field.last_subscription || field.last_subscription.status !== SubscriptionsStatus.ACTIVE)) {
                log.debug(`Field selected change: ${field.field_id}`, selectedFields)
                if (selectedFields.filter(fieldSelected => fieldSelected.field_id === field.field_id).length) {
                    onSelectedFieldsChange(selectedFields.filter(fieldSelected => fieldSelected.field_id !== field.field_id))
                } else {
                    onSelectedFieldsChange(selectedFields.concat(field))
                }
            }
        }
    };

    const zoomToBbox = function (zoomBbox: number[], width: number, height: number) {
        // Get the viewport from the all the features or the filtered features
        log.debug(`Size: ${width} x ${height}`);
        const viewport = geoViewport.viewport(zoomBbox, [width, height]);
        log.debug(`Target view port: ${JSON.stringify(viewport)}`);
        if (viewport && viewport.zoom) {

            setViewport({
                ...viewport,
                latitude: viewport.center[1],
                longitude: viewport.center[0],
                zoom: (viewport.zoom - ZOOM_LEVEL_MARGIN_OFFSET),
            });
        }
    }

    const fieldMapPolygonStyle = generateFieldMapSelectionPolygonStyle(theme);
    const fieldMapSymbolStyle = generateFieldMapSelectionSymbolStyle(theme);

    return (
        <>
            <Box className={classes.stepContent}>
                <Box width='70%' height='100%' {...{ref: ref}}>
                    <ReactMapGL
                        width='100%'
                        height="100%"
                        style={{borderRadius: '10px'}}
                        {...viewport}
                        ref={mapRef}
                        mapboxApiAccessToken={mapToken}
                        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
                        onViewportChange={handleViewportChange}
                        onClick={onClick}
                    >
                        {displayedFieldsGeojson && (
                            <>
                                <Source type='geojson' data={displayedFieldsGeojson.centroidGeojson}>
                                    <Layer {...fieldMapSymbolStyle} />
                                </Source>

                                <Source type='geojson' data={displayedFieldsGeojson.geojson}>
                                    <Layer {...fieldMapPolygonStyle} />
                                </Source>
                            </>
                        )}

                    </ReactMapGL>
                </Box>
                <FieldSelectionTable selectedFields={selectedFields} onSelectedFieldsChange={onSelectedFieldsChange} selectedCampaign={selectedCampaign} onSelectedCampaignChange={setSelectedCampaign} onDisplayedFieldsChange={setDisplayedFieldsList} />
            </Box>
            <Box className={classes.stepContentResume}>
                <Box className={classes.resumeItem}>
                    <Typography className={classes.resumeItemText}>
                        <IntlMessages id='subscriptions.stepper.step_one.field_number' />
                    </Typography>
                    <Typography className={classes.resumeItemNumber}>
                        {selectedFields.length}
                    </Typography>
                </Box>
                <Box className={classes.resumeItem}>
                    <Typography className={classes.resumeItemText}>
                        <IntlMessages id="subscriptions.stepper.step_one.area" />
                    </Typography>
                    <Typography className={classes.resumeItemNumber}>
                        {computeTotalAreaHa(selectedFields).toFixed(0)}
                    </Typography>
                </Box>
            </Box>
        </>
    )

}