import React from 'react';
import LayersIcon from '@material-ui/icons/Layers';
import {indexPath} from '../configs/SoilsRoutePaths';
import HeaderButton from 'shared/components/layout/HeaderButton';
import useCheckPermissions from 'modules/auth/hooks/useCheckPermissions';

interface SoilsHeaderButtonProps {
  alwaysShowTitle?: boolean;
}

const SoilsHeaderButton: React.FC<SoilsHeaderButtonProps> = (props) => {

  const allowed = useCheckPermissions("soils.read");
  if (allowed) {
    return (
      <>
        <HeaderButton path={indexPath} titleId="soils.headerButton.title" icon={<LayersIcon />} alwaysShowTitle={props.alwaysShowTitle} />
      </>
    );
  }
  return null;
};

export default SoilsHeaderButton;
