import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IntlMessages from 'shared/components/IntlMessages';
import {Card, CardProps, Box, Typography} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    ndviGradient: {
        background: 'linear-gradient(to right, #564625 0%,#574726 1%,#645130 3%,#65532d 5%,#705a35 6%,#745e37 8%,#745e35 9%,#836b3d 11%,#836b3d 12%,#7e6e3a 16%,#747237 19%,#727433 22%,#6a7731 27%,#637b2d 30%,#607c2f 31%,#557f2b 33%,#4c8527 39%,#3d9423 46%,#3d9423 47%,#339820 50%,#319822 52%,#2c9a1f 53%,#24a01e 58%,#1aac1b 69%,#1baf1b 71%,#1dac1e 73%,#13a014 74%,#189b19 78%,#129314 79%,#118d11 80%,#148916 82%,#0f810f 83%,#117c12 86%,#0b6f0c 88%,#0d6d0c 90%,#09610a 92%,#0b5f0a 94%,#055106 96%,#075007 98%,#124910 99%)', /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}));

interface NdviLegendProps extends CardProps { }

/**
 * Component with the NDVI legend gradient and card around it
 * @param props 
 */
const NdviLegend: React.FC<NdviLegendProps> = (props) => {

    const styles = useStyles();

    // 
    return (
        <Card {...props}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center">
                <h5><IntlMessages id="waterBalance.ndviLegend.label" /></h5>

                <Box pt={2} width={1} display="flex" flexDirection="row" alignItems="center">
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" width={60} pr={1}><Typography variant="caption"><IntlMessages id="waterBalance.ndviLegend.minNdviLabel" /></Typography></Box>
                    <Box flex={1} height={10} className={styles.ndviGradient}></Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width={60} pl={1}><Typography variant="caption"><IntlMessages id="waterBalance.ndviLegend.maxNdviLabel" /></Typography></Box>
                </Box>

            </Box>
        </Card>
    );
}

export default NdviLegend;
