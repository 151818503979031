import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';
import {ReactComponent as ArrowOverview} from '../../../assets/images/icons/arrowOverviewMd3.svg';

const ArrowOverview3 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={ArrowOverview}
      viewBox='0 0 105 83'
    />
  );
};

export default ArrowOverview3;
