import CheckoutSession from "../models/CheckoutSession";
import {firestore, auth} from 'shared/services/ReduxSagaFirebase';
import {loadStripe} from '@stripe/stripe-js';
import log from 'loglevel';
import {STRIPE_PUBLISHABLE_KEY} from "../configs/constant";


export async function handleStripeSubscription(checkoutSession: CheckoutSession) {
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    const currentUser = auth.currentUser?.uid;
    log.info(`Current user: ${currentUser}`);
    if (currentUser) {
      const docRef = await firestore
        .collection('customers')
        .doc(currentUser)
        .collection('checkout_sessions')
        .add(checkoutSession);
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot((snap) => {
        const checkoutData: any = snap.data() as any;
        const {error, sessionId} = checkoutData;
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          stripe?.redirectToCheckout({sessionId});
        }
      });
    }
  }
  