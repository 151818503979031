import {Reducer} from 'redux';
import {
  FieldsActionTypes,
  LOAD_FIELDS_SUMMARY,
  LOAD_FIELDS_SUMMARY_SUCCESS,
  ON_SELECT_FIELD,
  SET_ZOOM_TO_SELECTED,
  SET_MAP_POSITION,
  CLEAR_FIELDS_DATA,
  ON_SELECT_FILTERS_SUCCESS,
  SET_MAP_DISPLAY_NDVI,
  ON_SELECT_CAMPAIGN
} from '../actions/FieldsActions';
import FieldsState from '../models/FieldsState';

const initialState: FieldsState = {
  count: null,
  data: null,
  campaigns: null,
  crops: {},
  farmers: {},
  selectedFieldId: null,
  zoomToSelected: false,
  selectedCampaign: null,
  mapPosition: {
    latitude: 0,
    longitude: 0,
    zoom: 3,
  },
  bbox: [],
  filteredFields: [],
  filteredFieldsBbox: [],
  selectedFilters: {},
  mapDisplayNdvi: false,
};

const fieldsReducers: Reducer<FieldsState, FieldsActionTypes> = (state: FieldsState = initialState, action: FieldsActionTypes) => {
  switch (action.type) {
    case LOAD_FIELDS_SUMMARY:
      return {
        ...state,
      }
    case LOAD_FIELDS_SUMMARY_SUCCESS:
      return {
        ...action.payload
      }
    case ON_SELECT_FIELD:
      return {
        ...state,
        selectedFieldId: action.payload.selectedFieldId,
        zoomToSelected: action.payload.zoomToSelected
      }
    case SET_ZOOM_TO_SELECTED:
      return {
        ...state,
        zoomToSelected: action.payload.zoomToSelected
      }
    case SET_MAP_POSITION:
      return {
        ...state,
        mapPosition: action.payload
      }
    case CLEAR_FIELDS_DATA:
      return {
        ...initialState,
      };
    case ON_SELECT_FILTERS_SUCCESS:
      return {
        ...state,
        selectedFilters: action.payload.selectedFilters,
        filteredFields: action.payload.filteredFields,
        filteredFieldsBbox: action.payload.filteredFieldsBbox,
        zoomToSelected: action.payload.zoomToSelected,
      };
    case SET_MAP_DISPLAY_NDVI:
      return {
        ...state,
        mapDisplayNdvi: action.payload
      };
    case ON_SELECT_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: action.payload
      }
    default:
      return state;
  }
};
export default fieldsReducers;
