import {all, call, put, takeEvery, select} from 'redux-saga/effects';
import api, {wagoApiUrl} from '../../../shared/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess, redirectTo} from '../../../shared/actions/Common';
import {AddNewRotationAction, ADD_NEW_ROTATION, ModifyRotationAction, MODIFY_ROTATION, LoadRotationsAction, LOAD_ROTATIONS, DeleteRotationAction, DELETE_ROTATION} from '../actions/RotationsActions';
import actions from '../actions/RotationsActions';
import Rotation from '../models/Rotation';
import Field from '../../fields/models/Field';
import {reloadFieldsSummaryAfterDelay} from '../../fields/sagas/FieldsSagas';
import {AppState} from 'shared/store';
import {getCampaignFieldsPath} from '../../fields/configs/FieldsRoutePaths';

function addNewRotationRequest(selectedFieldId: number, newRotation: Rotation) {
  const rotationUrl = `${wagoApiUrl}/fields/${selectedFieldId}/rotations`;
  return api.post(rotationUrl, newRotation);
}

function* addNewRotation(action: AddNewRotationAction) {
  try {
    yield put(fetchStart("addNewRotation"));
    let newRotation: Rotation = action.payload.newRotation;
    yield call(addNewRotationRequest, action.payload.selectedFieldId, newRotation);
    yield put(fetchSuccess("addNewRotation"));

    yield call(reloadFieldsSummaryAfterDelay, 3);

  } catch (error) {
    yield put(fetchError("addNewRotation", error.message))
  }
};

function modifyRotationRequest(modifiedRotation: Field) {
  const selectedField: number | undefined = modifiedRotation.field_id;
  const selectedRotation: number | undefined = modifiedRotation.rotation_id;
  const rotationUrl = `${wagoApiUrl}/fields/${selectedField}/rotations/${selectedRotation}`;
  return api.put(rotationUrl, modifiedRotation);
};

function* modifyRotation(action: ModifyRotationAction) {
  try {
    yield put(fetchStart("modifyRotation"));
    yield call(modifyRotationRequest, action.payload);
    yield put(fetchSuccess("modifyRotation"));
  } catch (error) {
    yield put(fetchError("modifyRotation", error.message));
  }
};

function getRotationsFieldRequest(selectedFieldId: number) {
  const rotationUrl = `${wagoApiUrl}/fields/${selectedFieldId}/rotations`;
  return api.get(rotationUrl);
};

function* loadRotations(action: LoadRotationsAction) {
  try {
    yield put(fetchStart("loadRotations"));
    const selectedFieldId = action.payload.selectedFieldId;
    if (selectedFieldId !== null) {
      const res = yield call(getRotationsFieldRequest, selectedFieldId);
      const rotationsListing = res.data as Rotation[];
      const rotationsMap: {[key: string]: Rotation} = {};
      rotationsListing.forEach((value) => {
        if (value.rotation_id !== undefined) {
          rotationsMap[value.rotation_id] = value;
        }
      })
      yield put(actions.loadRotationsSuccess(rotationsMap));
      yield put(fetchSuccess("loadRotations"));
    };
  } catch (error) {
    yield put(fetchError("loadRotations", error.message));
  }
};

function deleteRotationFieldRequest(selectedFieldId: number | null, selectedRotationId: number | null) {
  if (selectedRotationId !== null && selectedFieldId !== null) {
    const rotationUrl = `${wagoApiUrl}/fields/${selectedFieldId}/rotations/${selectedRotationId}`;
    return api.delete(rotationUrl);
  }
}

function* deleteRotation(action: DeleteRotationAction) {
  try {
    yield put(fetchStart("deleteRotation"));
    const selectedField = action.payload.selectedField;
    const selectedRotationId = action.payload.selectedRotationId;
    yield call(deleteRotationFieldRequest, selectedField?.field_id as number, selectedRotationId);
    yield put(fetchSuccess("deleteRotation"));
    yield call(reloadFieldsSummaryAfterDelay);
    const {selectedCampaign} = yield select((state: AppState) => state.fields);

    if (selectedField && selectedField.rotation_id === selectedRotationId) {
      yield put(redirectTo(getCampaignFieldsPath(selectedCampaign)));
    }

  } catch (error) {
    yield put(fetchError("deleteRotation", error.message));
  }
}

export function* rotationSagas() {
  yield all([
    takeEvery(ADD_NEW_ROTATION, addNewRotation),
    takeEvery(MODIFY_ROTATION, modifyRotation),
    takeEvery(LOAD_ROTATIONS, loadRotations),
    takeEvery(DELETE_ROTATION, deleteRotation)
  ])
}