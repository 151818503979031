import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {AppState} from '../store';
import authActions from '../../modules/auth/actions/AuthActions';
import {auth} from 'shared/services/ReduxSagaFirebase';
import log, {setRemoteLogsConfig} from 'shared/services/LogService';
import {isProdEnv} from 'shared/configs/FirebaseConfig';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  useEffect(() => {
    const firebaseCheck = () =>
      new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
          unsubscribe();
          if (authUser) {
            log.info(`Reloading user ${authUser.uid}`);
            const tokenResult = await authUser.getIdTokenResult();
            if (tokenResult) {
              if (!isProdEnv) {
                log.info(`Retrieved user token: ${tokenResult.token}`);
              }
              setRemoteLogsConfig(tokenResult.token);
              const tokenExpiration = Math.floor(Date.parse(tokenResult.expirationTime) / 1000);
              dispatch(authActions.setJWTToken({token: tokenResult.token, tokenExpiration: tokenExpiration}));
              dispatch(authActions.loadUserInfo());
            }
          } else {
            log.info(`No saved user to be restored`);
          }
          resolve(null);
        });
        return Promise.resolve();
      });
    const checkAuth = () => {
      Promise.all([firebaseCheck()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [user, loading];
};

export const useAuthUser = () => {
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  if (user) {
    return user;
  }
  return null;
};

