import React, {useEffect, useState} from 'react';
import {Loader} from '@crema';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import IntlMessages from 'shared/components/IntlMessages';
import StepBar from './StepBar';
import StepTitle from './StepTitle';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import FieldSelectionStep from 'modules/subscriptions/components/SubscriptionFieldSelectionStep';
import SummaryFieldSelectionStep from 'modules/subscriptions/components/SummaryFieldSelectionStep';
import PriceSelectionStep from 'modules/products/components/PriceSelectionStep';
import ProductSelectionStep from 'modules/products/components/ProductSelectionStep';
import Field from 'modules/fields/models/Field';
import Product from 'modules/products/models/Product';
import Campaign from 'modules/campaigns/models/Campaign';
import Price from 'modules/products/models/Price';
import CheckoutSession, {CheckoutLineItem, supportedCheckoutSessionLocales} from '../models/CheckoutSession';
import {baseCheckoutSession} from '../configs/chekoutSession';
import {useHistory} from 'react-router-dom';
import {QUERY_PAYMENT_CANCEL, QUERY_PAYMENT_SUCCESS, STRIPE_METADATA_} from '../configs/constant';
import {STRIPE_METADATA_REF} from 'modules/products/configs/constant';
import {computeTotalAreaHa} from '../../fields/services/FieldsService';
import {getCampaignFieldsPath} from 'modules/fields/configs/FieldsRoutePaths';
import log from 'shared/services/LogService';
import {handleStripeSubscription} from '../services/sendCheckoutSession';
import {fetchStart} from 'shared/actions';
import useStepperStyles from './StepperStyle';
import {CremaTheme} from 'shared/models/AppContextPropsType';


interface Error {
    isError: boolean;
    message: string;
}

export default function SubscriptionCreation() {

    const {customer} = useSelector<AppState, AppState['auth']>(
        (state) => state.auth,
    );
    const {loading} = useSelector<AppState, AppState['common']>(
        ({common}) => common,
    );

    const productsState = useSelector<AppState, AppState['products']>((state) => state.products);
    const products = productsState.data;
    const history = useHistory();
    const dispatch = useDispatch();
    const theme: CremaTheme = useTheme();
    const isDownMd = useMediaQuery(theme.breakpoints.down('md'))

    const classes = useStepperStyles()


    const [stepContentList, setStepContentList] = useState<JSX.Element[]>([])
    const [selectedFields, setSelectedFields] = useState<Field[] | null>(null)
    const [selectedProduct, setSelectedProduct] = useState<Product>()
    const [activeStep, setActiveStep] = useState<number>(0)
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign>()
    const [selectedPrice, setSelectedPrice] = useState<Price>()
    const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>(baseCheckoutSession)
    const errorStateDefault: Error = {isError: false, message: ""}
    const [error, setError] = useState<Error>(errorStateDefault)



    useEffect(() => {

        if (products) {
            const productList = Object.keys(products);
            if (productList.length > 1) {
                setStepContentList([
                    (<FieldSelectionStep selectedFields={selectedFields ? selectedFields : []} onSelectedFieldsChange={setSelectedFields} selectedCampaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} />),
                    (<SummaryFieldSelectionStep selectedFields={selectedFields ? selectedFields : []} setSelectedFields={setSelectedFields} editable />),
                    (<ProductSelectionStep selectedFields={selectedFields ? selectedFields : []} products={products} selectedProduct={selectedProduct} setSelectedProduct={handleSelectedProduct} />),
                    (<PriceSelectionStep selectedFields={selectedFields ? selectedFields : []} selectedProduct={selectedProduct} selectedPrice={selectedPrice} setSelectedPrice={setSelectedPrice} />)
                ])

            } else if (productList.length === 1) {

                const product = Object.values(products)
                setSelectedProduct(product[0])
                setStepContentList([
                    (<FieldSelectionStep selectedFields={selectedFields ? selectedFields : []} onSelectedFieldsChange={setSelectedFields} selectedCampaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} />),
                    (<SummaryFieldSelectionStep selectedFields={selectedFields ? selectedFields : []} setSelectedFields={setSelectedFields} editable />),
                    (<PriceSelectionStep selectedFields={selectedFields ? selectedFields : []} selectedProduct={selectedProduct} selectedPrice={selectedPrice} setSelectedPrice={setSelectedPrice} />)
                ])

            }
        }
    }, [products, selectedFields, selectedProduct, selectedPrice, selectedCampaign])

    const resetModalDefaultState = () => {
        setSelectedFields(null)
        setSelectedProduct(undefined)
        setSelectedPrice(undefined)
        handleCreatingSubscriptionProcess()
        setActiveStep(0)
        setError(errorStateDefault)
    }

    const handleCreatingSubscriptionProcess = (query?: string) => {
        // setCreatingSubscription(init ? defaultCreationSubsState : !creatingSubscription)
        history.push({pathname: history.location.pathname, search: query})
    }


    const completeCheckoutSession = () => {
        if (selectedCampaign && selectedFields && selectedFields.length && selectedProduct && selectedPrice && checkoutSession) {

            /**
             * Complete Metadata
             */

            const field_ids: number[] = []
            selectedFields.forEach(field => {
                field_ids.push(field.field_id)
            })

            const product_ref: string = selectedProduct[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`]
            const price_ref: string = selectedPrice[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`]
            const checkoutSessionMetadata = {
                campaign_id: selectedCampaign.campaign_id.toString(),
                field_ids: JSON.stringify(field_ids),
                product_ref: product_ref,
                price_ref: price_ref
            }

            /**
             * Complete line-item
             */


            const price = selectedPrice.id
            const quantity = Math.floor(computeTotalAreaHa(selectedFields))

            const lineItem: CheckoutLineItem = {
                price: price,
                quantity: quantity,
            }
            const checkoutSessionCompleted: CheckoutSession = {
                ...checkoutSession,
                success_url: `${window.location.origin}${getCampaignFieldsPath(selectedCampaign.campaign_id)}${QUERY_PAYMENT_SUCCESS}`,
                cancel_url: `${window.location.origin}${getCampaignFieldsPath(selectedCampaign.campaign_id)}${QUERY_PAYMENT_CANCEL}`,
                metadata: checkoutSessionMetadata,
                line_items: [lineItem],
            }
            if (customer && customer.preferred_locales && customer.preferred_locales.length > 0) {
                let targetLocale: string | null = null;
                const preferredLocale = customer.preferred_locales[0];
                if (supportedCheckoutSessionLocales.includes(preferredLocale)) {
                    targetLocale = preferredLocale;
                } else {
                    const preferredLocaleParts = preferredLocale.split("-");
                    if (supportedCheckoutSessionLocales.includes(preferredLocaleParts[0])) {
                        targetLocale = preferredLocaleParts[0];
                    }
                }
                if (targetLocale) {
                    checkoutSessionCompleted.locale = targetLocale;
                }
            }
            setCheckoutSession(checkoutSessionCompleted)
            return checkoutSessionCompleted
        }
    }
    const controlAndSendCheckoutSession = (checkoutSession: CheckoutSession) => {
        const missingValues: string[] = []
        const lineItems = checkoutSession.line_items[0];
        const metadata = checkoutSession.metadata;
        Object.keys(lineItems).forEach(key => {
            if (!lineItems[key as keyof CheckoutLineItem]) {
                missingValues.push(key)
            }
        })
        Object.keys(metadata).forEach(key => {
            if (!metadata[key]) {
                missingValues.push(key)
            }
        })
        if (missingValues.length) {
            log.error(`Cannot send checkout session because of missing values for`, missingValues)
        } else {
            // Dispatch fetch start in order to display a loader
            dispatch(fetchStart("controlAndSendCheckoutSession"));
            handleStripeSubscription(checkoutSession);
        }
    }
    /**
     * Action to do when click on next button
     * @param activeStep 
     */
    const onFinalAction = (activeStep: number) => {
        if (!selectedPrice && activeStep < stepContentList.length - 1) {
            setActiveStep(activeStep + 1)
        } else {
            const checkoutSessionCompleted = completeCheckoutSession();
            if (checkoutSessionCompleted) {
                controlAndSendCheckoutSession(checkoutSessionCompleted)
            }
        }
    }
    const checkSelectedField = () => {
        if (selectedFields && selectedFields.length) {
            setError(errorStateDefault)
            return true
        } else {
            setError({
                isError: true,
                message: "subscriptions.stepper.error.message.missing_fields"
            })
            return false
        }
    }
    const checkSelectedProduct = () => {
        if (selectedProduct) {
            setError(errorStateDefault)
            return true
        } else {
            setError({
                isError: true,
                message: "subscriptions.stepper.error.message.missing_product"
            })
            return false
        }
    }
    const checkSelectedPrice = () => {
        if (selectedPrice) {
            setError(errorStateDefault)
            return true
        } else {
            setError({
                isError: true,
                message: "subscriptions.stepper.error.message.missing_price"
            })
            return false
        }
    }
    const isValidate = (activeStep: number) => {
        switch (activeStep) {
            case 0:
                return checkSelectedField();
            case 1:
                return checkSelectedField();
            case 2:
                if (products && Object.keys(products).length > 1) {
                    return checkSelectedProduct()
                } else {
                    return () => {checkSelectedProduct(); checkSelectedPrice()}
                }
            case 3:
                return checkSelectedPrice();
            default:
                break;
        }
    }

    const previousStep = (activeStep: number) => {
        setSelectedPrice(undefined);
        setActiveStep(activeStep - 1)
    }

    const validationStep = (activeStep: number) => {
        if (isValidate(activeStep)) {
            onFinalAction(activeStep)
        }
    }

    const handleSelectedProduct = (product: Product) => {
        setSelectedProduct(product);
        // If only one price, set the price at the same time
        if (product && product.prices?.length === 1) {
            setSelectedPrice(product.prices[0]);
        }
    }

    return (
        <Dialog fullWidth maxWidth={isDownMd ? 'lg' : 'md'} open={true} onClose={resetModalDefaultState}>
            {loading && <Loader />}
            <DialogTitle disableTypography className={classes.stepperDialogTitle}>
                <Typography variant='h5' classes={{h5: classes.title}}>
                    <IntlMessages id={'subscriptions.stepper.title'} />
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={resetModalDefaultState}
                    className={classes.closeButton}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.stepperDialogContent}>
                <StepTitle activeStep={activeStep} />
                {stepContentList[activeStep]}
            </DialogContent>
            <DialogActions className={classes.stepperDialogAction}>
                {error && error.isError ? (
                    <Typography className={classes.errorMessage}>
                        <IntlMessages id={error.message} />
                    </Typography>
                ) : ""}
                <Box className={classes.stepperButtonsContainer}>
                    {activeStep > 0 ? (
                        <Button className={classes.stepperPrevButton} variant='outlined' color={'primary'} onClick={() => previousStep(activeStep)}>
                            <IntlMessages id={"subscriptions.stepper.button.prev_step"} />
                        </Button>) : ""}
                    <Button className={classes.stepperButtonValidation} color='primary' variant='contained' onClick={() => validationStep(activeStep)}>
                        {selectedPrice ? (
                            <IntlMessages id='subscriptions.stepper.button.go_to_payment' />
                        ) : (
                            <IntlMessages id={"subscriptions.stepper.button.next_step"} />
                        )}
                    </Button>
                </Box>
                <StepBar activeStep={activeStep} stepperLength={stepContentList.length} />
            </DialogActions>
        </Dialog>
    )
}