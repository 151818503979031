import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import BaseDialog from 'shared/components/BaseDialog';
import {AppState} from 'shared/store';
import {QUERY_SUBSCRIPTION_DISPLAY} from '../configs/constant';
import SubscriptionsInvoicesTable from './SubscriptionsInvoicesTable';

export default function SubscriptionInvoicesList() {

    const {selectedSubscription} = useSelector<AppState, AppState['subscriptions']>(({subscriptions}) => subscriptions)
    const history = useHistory()
    const returnToSubscriptionTable = () => {
        history.push({pathname: history.location.pathname, search: QUERY_SUBSCRIPTION_DISPLAY})
    }

    if (selectedSubscription && selectedSubscription.invoices) {

        return (
            <BaseDialog fullWidth maxWidth={'lg'} open={true} onClose={returnToSubscriptionTable} titleMessageId="subscriptions.subscription_table.invoices_list.title">
                <SubscriptionsInvoicesTable invoices={selectedSubscription.invoices} />
            </BaseDialog>

        )
    } else {
        return null
    }
}