import {
  CommonActionTypes,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  REDIRECT_TO,
  TOGGLE_APP_DRAWER,
} from './CommonAction';

export const fetchStart = (
  fetchId: string
): CommonActionTypes => (
  {
    type: FETCH_START,
    fetchId
  });

export const fetchSuccess = (fetchId: string): CommonActionTypes => ({
  type: FETCH_SUCCESS,
  fetchId
});

export const fetchError = (fetchId: string, error: string, redirectTo?: string): CommonActionTypes => ({
  type: FETCH_ERROR,
  error,
  redirectTo,
  fetchId
});

export const showMessage = (message: string, redirectTo?: string): CommonActionTypes => ({
  type: SHOW_MESSAGE,
  message,
  redirectTo,
});

export const redirectTo = (redirectTo: string): CommonActionTypes => ({
  type: REDIRECT_TO,
  redirectTo,
});

export const onToggleAppDrawer = (): CommonActionTypes => ({
  type: TOGGLE_APP_DRAWER,
});

export const hideMessage = (): CommonActionTypes => ({type: HIDE_MESSAGE});
