import {Box, Switch, Typography, withStyles} from '@material-ui/core';
import {ModifyNotificationModel} from 'modules/auth/models/ModifyNotificationModel';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import {AppState} from 'shared/store';
import {actions} from '../../actions/AuthActions';


export default function ModifyNotification() {

    const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
    const dispatch = useDispatch()
    const [notification, setNotification] = useState<ModifyNotificationModel | null>(null)

    useEffect(() => {
        let alert_sms: boolean = false;
        let alert_mail: boolean = false;
        if (user) {
            alert_sms = user.alert_sms ? user.alert_sms : false
            alert_mail = user.alert_mail ? user.alert_mail : false
        }
        setNotification({alert_sms: alert_sms, alert_mail: alert_mail})
    }, [user])

    if (notification) {
        return (
            <Box>
                <Box pb={3} pt={3}>
                    <Typography variant='subtitle1'>
                        <IntlMessages id='user.settings.notification.title.hydric_stress' />
                    </Typography>
                </Box>
                <Box display='flex' flexDirection='column'>
                    <Box p={3} display='flex' alignItems='center'>
                        <AntSwitch
                            checked={notification.alert_sms}
                            color='primary'
                            onChange={() => {
                                setNotification({...notification, alert_sms: !notification.alert_sms})
                                dispatch(actions.modifyUserNotification({...notification, alert_sms: !notification.alert_sms}))
                            }}
                        />
                        <Typography style={{paddingLeft: '20px'}}>
                            <IntlMessages id="user.settings.notification.alert_sms" />
                        </Typography>
                    </Box>
                    <Box p={3} display='flex' alignItems='center'>
                        <AntSwitch
                            checked={notification.alert_mail}
                            color='primary'
                            onChange={() => {
                                setNotification({...notification, alert_mail: !notification.alert_mail})
                                dispatch(actions.modifyUserNotification({...notification, alert_mail: !notification.alert_mail}))
                            }}
                        />
                        <Typography style={{paddingLeft: '20px'}}>
                            <IntlMessages id="user.settings.notification.alert_mail" />
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
    }
    return null
}

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 20,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(22px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 16,
        height: 16,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor: theme.palette.grey[500],
    },
    checked: {},

}))(Switch);
