import React from 'react';
import HeaderButton from 'shared/components/layout/HeaderButton';
import {MainRole} from '../models/MainRole';
import useCheckMainRole from 'modules/auth/hooks/useCheckMainRole';
import {usersAdminPath} from '../configs/AuthRoutePaths';
import {People} from '@material-ui/icons';

interface ManageUsersHeaderButtonProps {
  alwaysShowTitle?: boolean;
}

/**
 * Button to access admin interface to be able to define wago role for users
 * @param props
 * @returns 
 */
const ManageUsersHeaderButton: React.FC<ManageUsersHeaderButtonProps> = (props) => {

  const allowed = useCheckMainRole(MainRole.ADMIN);
  if (allowed) {
    return (
      <>
        <HeaderButton path={usersAdminPath} titleId="auth.manageUsersButton.title" icon={<People />} alwaysShowTitle={props.alwaysShowTitle} />
      </>
    );
  }
  return null;
};

export default ManageUsersHeaderButton;
