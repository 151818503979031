import Campaign from '../models/Campaign';

export const LOAD_CAMPAIGNS = 'LOAD_CAMPAIGNS';
export interface LoadCampaignsAction {
  type: typeof LOAD_CAMPAIGNS;
}

export const LOAD_CAMPAIGNS_SUCCESS = 'LOAD_CAMPAIGNS_SUCCESS';
export interface LoadCampaignsSuccessAction {
  type: typeof LOAD_CAMPAIGNS_SUCCESS;
  payload: {[key: string]: Campaign}
}

export type CampaignsTypes = 
  | LoadCampaignsAction
  | LoadCampaignsSuccessAction

// Actions Creator
export const actions = {
  loadCampaign: (): LoadCampaignsAction => {
    return {
      type: LOAD_CAMPAIGNS
    };
  },

  loadCampaignSucces: (campaigns: {[key: string]: Campaign}) => {
    return {
      type: LOAD_CAMPAIGNS_SUCCESS,
      payload: campaigns
    }
  }
};

export default actions;