import {Reducer} from 'redux';
import {
  WaterBalanceActionTypes,
  LOAD_WATER_BALANCE_SUCCESS,
  SET_SELECTED_NDVI_DATE,
  CLEAR_WATER_BALANCE_DATA,
} from '../actions/WaterBalanceActions';
import WaterBalanceState from '../models/WaterBalanceState';

const initialState: WaterBalanceState = {
  data: {
    ET: [],
    fc: [],
    kcb: [],
    ndvi: [],
    SWC1: [],
    SWC2: [],
    SWC3: [],
    GDD: [],
    TAW: [],
    RAW: [],
    DE: [],
    GDD_phase1_min: [],
    GDD_phase1_max: [],
    GDD_phase6_min: [],
    GDD_phase6_max: [],
    ETO: [],
    pluie: [],
    GDD_phase8_min: [],
    irrigation: [],
    GDD_phase8_max: [],
    irrigauto: [],
  },
  selectedNdviDate: null,
  vegetationDataChart: [],
  irrigationDataChart: []
};
const waterBalanceReducers: Reducer<WaterBalanceState, WaterBalanceActionTypes> = (state = initialState, action: WaterBalanceActionTypes) => {
  switch (action.type) {
    case LOAD_WATER_BALANCE_SUCCESS:
      return {
        ...action.payload,
      };
    case SET_SELECTED_NDVI_DATE:
      return {
        ...state,
        selectedNdviDate: action.payload,
      };
    case CLEAR_WATER_BALANCE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default waterBalanceReducers;
