import firebase from 'firebase/app';
import '@firebase/firestore'
import '@firebase/auth'
import ReduxSagaFirebase from 'redux-saga-firebase'
import firebaseConfig from 'shared/configs/FirebaseConfig';

export const firebaseApp = !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();

export const auth = firebaseApp.auth();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

export const firestore = firebaseApp.firestore()
const rsf = new ReduxSagaFirebase(firebaseApp)

export default rsf