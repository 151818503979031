/**
 * Function return the url to associate with wagoApiUrl. Complete with force_compute if params is provided 
 * @param fieldId 
 * @param rotationId 
 * @param forceCompute 
 * @returns url path
 */
export const getWaterBalanceRoutePath = function (fieldId: number, rotationId: number, forceCompute?:boolean): string {
    let routePath = `/fields/${fieldId}/rotations/${rotationId}/water_balance`
    if(forceCompute) {
        routePath+='/compute'
    }
    return routePath
}