import React, {ReactElement, useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import IntlMessages from '../IntlMessages';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import {useHistory} from "react-router-dom";
import useStyles from './HeaderButton.style';
import * as H from 'history';

/**
 * Generic header buttons props
 */
interface HeaderButtonProps {
  /**
   * The path to which the app is redirected
   * Used to define if the button is in the selected state too
   */
  path: string;

  /**
   * The title id for internationalization
   */
  titleId: string;

  /**
   * The SVG icon
   */
  icon: ReactElement;

  /**
   * True if the title must always be displayed even for small screen 
   * (usefull to use the same component in a popup for mobile screens)
   */
  alwaysShowTitle?: boolean;
}

const getIsSelected = (history: H.History<any>, path: string) => history.location.pathname.includes(path);

/**
 * Generic header buttons used by fields, crops and soils
 * @param props props (see above)
 */
const HeaderButton: React.FC<HeaderButtonProps> = (props) => {

  const {path} = props;
  let history = useHistory();

  const onClickHeaderButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    history.push(props.path);
  };

  const classes = useStyles();
  const [selected, setSelected] = useState(getIsSelected(history, path));
  useEffect(() => {

    history.listen(() => setSelected(getIsSelected(history, path)))
  }, [history, path]);

  const title = () => {
    return (<Box className={clsx(classes.headerBtnTitle, 'headerBtnTitle')} component='span'>
      <IntlMessages id={props.titleId} />
    </Box>)
  };

  return (
    <>
      <IconButton
        className={clsx(classes.headerBtn, {
          selected,
        }, 'headerBtn')}
        color='inherit'
        onClick={onClickHeaderButton}>
        {props.icon}
        {props.alwaysShowTitle ? title() :
          (<Hidden mdDown>
            {title()}
          </Hidden>
          )}

      </IconButton>
    </>
  );
};

export default HeaderButton;
