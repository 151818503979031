import React from 'react';
import {RouteConfig} from 'react-router-config';
import {selectedCampaignFieldsPath} from './configs/FieldsRoutePaths';

export const fieldsRouteConfigs: RouteConfig[] = [
  {
    auth: ['user'],
    routes: [
      {
        path: selectedCampaignFieldsPath,
        component: React.lazy(() => import('./components/Fields')),
      },
    ],
  },
];
