import axios from 'axios';
import log from 'loglevel';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

log.info(`Environment: ${process.env.REACT_APP_ENVIRONMENT_NAME}`);
export const wagoApiServer = process.env.REACT_APP_ENVIRONMENT_NAME === "prod" ? `${process.env.REACT_APP_WAGO_SERVER}` : `${process.env.REACT_APP_WAGO_DEV_SERVER}`;
export const wagoApiUrl = process.env.REACT_APP_ENVIRONMENT_NAME === "prod" ? `${wagoApiServer}${process.env.REACT_APP_WAGO_API_PREFIX_PROD}` : `${wagoApiServer}${process.env.REACT_APP_WAGO_API_PREFIX_DEV}`;

log.info(`Wago Api Url: ${wagoApiUrl}`);

export default axiosInstance;
