import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import NoSsr from '@material-ui/core/NoSsr';
import {Backdrop} from '@material-ui/core';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';

interface LoaderProps { }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 10,
    },
  }),
);

const Loader: React.FC<LoaderProps> = () => {
  const classes = useStyles();

  return (
    <NoSsr>
      <Box
        height='100%'
        display='flex'
        flex={1}
        alignItems='center'
        justifyContent='center'
        position='absolute'
        top={0}
        left={0}
        right={0}
        bottom={0}>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Box>
    </NoSsr >
  );
};

export default Loader;
