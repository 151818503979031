import {call, put, takeEvery, select, CallEffect} from 'redux-saga/effects';
import api, {wagoApiUrl} from '../../../shared/services/ApiConfig';
import {removeDuplicates} from '../services/FieldsService';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  redirectTo
} from 'shared/actions/Common';
import {
  LOAD_FIELDS_SUMMARY,
  LoadFieldsSummaryAction,
  ON_SELECT_FIELD,
  OnSelectFieldAction,
  OnSelectFiltersAction,
  ON_SELECT_FILTERS,
  AddNewFieldAction,
  ADD_NEW_FIELD,
  ModifyFieldAction,
  MODIFY_FIELD,
  DeleteFieldAction,
  DELETE_FIELD,
  ImportFieldsAction,
  IMPORT_FIELDS,
} from '../actions/FieldsActions';
import actions from '../actions/FieldsActions';
import waterBalanceActions from '../../waterBalance/actions/WaterBalanceActions';
import {all, delay} from 'redux-saga/effects';
import Field from '../models/Field';
import FieldsState from '../models/FieldsState';
import {Feature, FeatureCollection, Polygon} from 'geojson';
import centroid from '@turf/centroid';
import bbox from '@turf/bbox';
import FieldCreation from '../models/FieldCreation';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import getCropFromNameMatch from '../../crops/configs/CropIconsConfig';
import {getHydroStressLevel} from 'modules/waterBalance/services/WaterBalanceService';
import {HydroStressLevel} from 'modules/waterBalance/models/HydroStressLevel';
import wellknown from 'wellknown';
import {AppState} from 'shared/store/index';
import {timeoutDuration} from 'shared/configs/AppConst';
import log from 'shared/services/LogService';
import SubscriptionsState from 'modules/subscriptions/models/SubscriptionsState';
import {getCampaignFieldsPath} from '../configs/FieldsRoutePaths';
import Campaign from 'modules/campaigns/models/Campaign';

function getUserFieldsRequest() {
  const fieldsSummaryUrl = `${wagoApiUrl}/fields`;
  return api.get(fieldsSummaryUrl);
}

//Sends api request to Redux store
function* loadFieldsSummary(action: LoadFieldsSummaryAction) {

  // retrieve intl only now to be sure correctly initialized
  const intl = appIntl();

  try {
    yield put(fetchStart("loadFieldsSummary"));
    const res = yield call(getUserFieldsRequest);
    let fieldsSummary = res.data as Field[];
    if (!fieldsSummary) {
      fieldsSummary = [];
    }
    const fieldsCount = fieldsSummary.length;
    let selectedCampaignId = action.payload.selectedCampaignId;
    let selectedFieldId = action.payload.selectedFieldId;
    let user = action.payload.user
    const campaigns = fieldsSummary ? ([
      ...new Set(
        fieldsSummary.map((item) => {
          const campaign: Campaign = {
            campaign_id: item.campaign_id,
            campaign_name: item.campaign,
            campaign_start: item.campaign_start,
            campaign_end: item.campaign_end,
            campaign_type: "",
            hemisphere: ""
          };
          return campaign;
        }),
      ),
    ] as FieldsState['campaigns'])
      : ([] as FieldsState['campaigns']);

    const noDuplicatesCampaign = campaigns && removeDuplicates(campaigns);
    const cropsMap: {[key: string]: string[]} = {};
    const farmersMap: {[key: string]: string[]} = {};

    let featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    // Get the subscription
    const subscriptionState: SubscriptionsState = yield select((state: AppState) => state.subscriptions);
    const wagoSubscriptions = subscriptionState.wagoData;
    // TODO: loop until it's loaded if null?
    if (wagoSubscriptions) {
      // Affect subscription
      fieldsSummary.forEach((field) => {
        if (field.last_subscription && field.last_subscription.sub_id) {
          const lastSubscription = wagoSubscriptions[field.last_subscription.sub_id];
          if (lastSubscription) {
            field.last_subscription = lastSubscription;
          } else {
            log.error(`Field subscription ${field.last_subscription.sub_id} not found in wago`);
          }
        }
      });
    }

    //filter by campaign starts
    let fieldsNewMap: {[key: string]: {[key: string]: Field}} = {};
    campaigns?.map((campaign) => {
      let campaignFields: {[key: string]: Field} = {};
      let crops: string[] = [];
      let farmers: string[] = [];
      let uniq = (a: string[]) => [...new Set(a)];

      fieldsSummary.forEach((item) => {

        item.crop_type = getCropFromNameMatch(item.crop_name);
        if (campaign.campaign_id === item.campaign_id) {
          crops.push(item.crop_name);
          farmers.push(item.farmer);

          if (item.geometry) {
            item.geojson_geometry = wellknown.parse(item.geometry) as Polygon;
          }

          if (item.geojson_geometry) {
            const feature: Feature = {
              type: 'Feature',
              id: item.field_id,
              geometry: item.geojson_geometry,
              properties: {},
            };
            // Add it to the list of features in order to compute bbox
            featureCollection.features.push(feature);

            item.bbox = bbox(feature);

            // Use the geometry bbox for nvi pic by default
            let ndvi_bbox = item.bbox;
            // Except if custom bbox is defined
            if (item.ndvi && item.ndvi.ndvi_pic_bbox) {
              try {
                ndvi_bbox = JSON.parse(item.ndvi.ndvi_pic_bbox);
              } catch (error) {
                log.error(`Error while parsing ndvi_pic_bbox for field ${item.field_id}`);
              }
            }
            // Start from the top left corner and clockwise to have correct image display
            item.ndvi_coordinates = [
              [ndvi_bbox[0], ndvi_bbox[3]],
              [ndvi_bbox[2], ndvi_bbox[3]],
              [ndvi_bbox[2], ndvi_bbox[1]],
              [ndvi_bbox[0], ndvi_bbox[1]],
            ];
            item.centroid = centroid(feature).geometry.coordinates;
          }
          //find the stress level
          if (item.RAWC) {
            item.hydro_stress_level = getHydroStressLevel(
              item.RAWC?.most_recent_value,
              user,
            );
          } else {
            item.hydro_stress_level = HydroStressLevel.UNDEFINED;
          }
          return (campaignFields[item.field_id] = item as Field);

        }
      });
      cropsMap[campaign.campaign_id] = uniq(crops);
      farmersMap[campaign.campaign_id] = uniq(farmers);

      return (fieldsNewMap[campaign.campaign_id] = campaignFields);
    });
    //filter by campaigns ends

    const allFeaturesBbox = bbox(featureCollection);
    const fieldsState: FieldsState = {
      count: fieldsCount,
      data: fieldsNewMap,
      campaigns: noDuplicatesCampaign,
      crops: cropsMap,
      farmers: farmersMap,
      selectedFieldId: null,
      selectedCampaign: selectedCampaignId,
      zoomToSelected: false,
      mapPosition: {
        latitude: 0,
        longitude: 0,
        zoom: 3,
      },
      bbox: allFeaturesBbox,
      mapDisplayNdvi: false,
    };

    let selectedField: Field | null = null;
    let selectedRotationId: number | null = null;
    if (selectedFieldId && selectedCampaignId) {
      selectedField = fieldsNewMap[selectedCampaignId][selectedFieldId];
      if (selectedField) {
        selectedRotationId = selectedField.rotation_id;
      }
    }
    yield put(actions.loadFieldsSummarySuccess(fieldsState));
    yield put(fetchSuccess("loadFieldsSummary"));

    if (!selectedFieldId || (selectedFieldId && selectedField)) {
      yield put(
        actions.onSelectField(
          selectedFieldId,
          selectedRotationId,
          action.payload.zoomToSelected,
        ),
      );
    } else {
      yield put(fetchError("loadFieldsSummary", intl.formatMessage({id: 'fields.errors.errorFieldNotFound'}),
        getCampaignFieldsPath(selectedCampaignId)));
    }
  } catch (error) {
    log.error(`Error loading fields: ${error.message}`, error);
    yield put(
      fetchError("loadFieldsSummary", intl.formatMessage({id: 'fields.errors.errorLoading'})),
    );
  }
}

/**
 *
 * @param action Load the water balance data when a field is selected
 */
function* selectField(action: OnSelectFieldAction) {
  if (action.payload.selectedFieldId && action.payload.selectedRotationId) {
    yield put(
      waterBalanceActions.loadWaterBalance(
        action.payload.selectedFieldId,
        action.payload.selectedRotationId,
      ),
    );
  }
}

function* selectFilters(action: OnSelectFiltersAction) {

  const {selectedFilters} = action.payload;
  log.debug("Applying filter");
  log.debug(selectedFilters);
  const {data, selectedCampaign} = yield select((state: AppState) => state.fields);
  if (data && selectedCampaign && selectedFilters) {
    const fieldsMap: {[key: string]: Field} = data[selectedCampaign];

    const multiPropsFilter = (): Field[] => {
      const filtersKeys: string[] = Object.keys(selectedFilters);

      if (fieldsMap) {
        const fieldsList = [...Object.values(fieldsMap)];
        return fieldsList.filter(
          (element: any) => {
            return filtersKeys.every((key: string) => {
              const filterAllowedValues = selectedFilters[key];
              if (!filterAllowedValues.length) return false;
              const fieldValue = element[key];
              if (Array.isArray(fieldValue)) {
                return fieldValue.some((keyEle: string) =>
                  filterAllowedValues.includes(keyEle),
                );
              }
              return filterAllowedValues.includes(fieldValue);
            });
          },
        );
      }
      return [];
    };
    let filteredElements = multiPropsFilter();
    const itemdAdded = filteredElements.map((element) => element.field_id);
    log.debug(`Filtered fields count: ${itemdAdded?.length}`);
    let featureCollection: FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    itemdAdded.forEach((fieldId) => {
      const item = fieldsMap[fieldId];
      if (item.geojson_geometry) {
        const feature: Feature = {
          type: 'Feature',
          id: item.field_id,
          geometry: item.geojson_geometry,
          properties: {},
        };
        // Add it to the list of features in order to compute bbox
        featureCollection.features.push(feature);
      }
    });
    const filteredFeaturesBbox = bbox(featureCollection);

    // Clear selection if needed
    if (
      action.payload.selectedFieldId &&
      !itemdAdded.includes(action.payload.selectedFieldId)
    ) {
      yield put(actions.onSelectField(null, null, false));
    }

    yield put(
      actions.onSelectFiltersSuccess(
        selectedFilters,
        itemdAdded,
        filteredFeaturesBbox,
        action.payload.zoomToSelected,
      ),
    );
  } else {
    log.info("Campaign or filter not defined");
  }
}

function addNewFieldRequest(newField: FieldCreation) {
  const fieldUrl = `${wagoApiUrl}/fields`;
  return api.post(fieldUrl, newField);
}

function* importFields(action: ImportFieldsAction) {

  // retrieve intl only now to be sure correctly initialized
  const intl = appIntl();
  try {
    if (action.payload.featureCollection &&
      action.payload.featureCollection.features &&
      action.payload.featureCollection.features.length > 0) {

      yield put(fetchStart("importFields"));
      let fieldTemplate: FieldCreation = action.payload.template;

      const creationRequests: CallEffect<any>[] = [];
      const today = new Date();
      action.payload.featureCollection.features.forEach((feature, index) => {
        // Make a copy to be sure
        const newField = {...fieldTemplate};
        // Extract the wkt from the geojson
        // Convert to wkt
        newField.geometry = wellknown.stringify(feature.geometry as wellknown.GeoJSONPolygon);

        // Build the name
        if (feature.properties?.name || feature.properties?.Name) {
          newField.field_name = feature.properties.name || feature.properties?.Name;
        } else {
          newField.field_name = intl.formatMessage({id: 'fields.import.default_name_template'}, {number: index + 1, importDate: today});
        }

        log.debug(newField);
        creationRequests.push(call(addNewFieldRequest, newField));
      })
      yield all(creationRequests);
      yield put(fetchSuccess("importFields"));

      yield call(reloadFieldsSummaryAfterDelay, 2);
    } else {
      log.warn("Missing some information for fields import");
    }

  } catch (error) {
    log.error(`Error while importing fields`, error);
    const message = intl.formatMessage({id: 'fields.import.fields_creation_error'});
    yield put(fetchError("importFields", message));
  }
}

function* addNewField(action: AddNewFieldAction) {
  try {
    yield put(fetchStart("addNewField"));
    let newField: FieldCreation = action.payload;
    const res = yield call(addNewFieldRequest, newField);
    const createdField = res.data as {field_id: number; campaign_id: number;};
    if (createdField && createdField.field_id && createdField.campaign_id) {
      log.info(`Field created with id ${createdField.field_id} for campaign ${createdField.campaign_id}`);
    }
    yield put(fetchSuccess("addNewField"));

    yield call(reloadFieldsSummaryAfterDelay, 2);

  } catch (error) {
    yield put(fetchError("addNewField", error.message));
  }
}

export function* reloadFieldsSummaryAfterDelay(delayMultiplier: number = 1) {
  // Wait for some times
  yield put(fetchStart("reloadFieldsSummaryAfterDelay"));
  yield delay(timeoutDuration * delayMultiplier);
  yield put(fetchSuccess("reloadFieldsSummaryAfterDelay"));

  // Reload fields
  const {user} = yield select((state: AppState) => state.auth);
  const {selectedCampaign} = yield select((state: AppState) => state.fields);
  const action: LoadFieldsSummaryAction =
  {
    type: LOAD_FIELDS_SUMMARY,
    payload: {selectedFieldId: null, zoomToSelected: true, selectedCampaignId: selectedCampaign, user: user}
  };
  yield call(loadFieldsSummary, action);
}

function modifyFieldRequest(modifiedField: FieldCreation) {
  const selectedField: number | undefined = modifiedField.field_id;
  const fieldUrl = `${wagoApiUrl}/fields/${selectedField}`;
  return api.put(fieldUrl, modifiedField);
}

function* modifyField(action: ModifyFieldAction) {
  try {
    yield put(fetchStart("modifyField"));
    yield call(modifyFieldRequest, action.payload);
    yield put(fetchSuccess("modifyField"));

    yield call(reloadFieldsSummaryAfterDelay);

  } catch (error) {
    yield put(fetchError("modifyField", error.message));
  }
}

function deleteFieldRequest(selectedField: number) {
  const fieldUrl = `${wagoApiUrl}/fields/${selectedField}`;
  return api.delete(fieldUrl);
}

function* deleteField(action: DeleteFieldAction) {
  try {
    yield put(fetchStart("deleteField"));
    yield call(deleteFieldRequest, action.payload);
    yield put(fetchSuccess("deleteField"));

    // Redirect to campaign
    const {selectedCampaign} = yield select((state: AppState) => state.fields);
    yield put(redirectTo(getCampaignFieldsPath(selectedCampaign)));

    yield call(reloadFieldsSummaryAfterDelay);

  } catch (error) {
    yield put(fetchError("deleteField", error.message));
  }
}

export function* fieldsSagas() {
  yield all([
    takeEvery(LOAD_FIELDS_SUMMARY, loadFieldsSummary),
    takeEvery(ON_SELECT_FIELD, selectField),
    takeEvery(ON_SELECT_FILTERS, selectFilters),
    takeEvery(ADD_NEW_FIELD, addNewField),
    takeEvery(IMPORT_FIELDS, importFields),
    takeEvery(MODIFY_FIELD, modifyField),
    takeEvery(DELETE_FIELD, deleteField),
  ]);
}
