import React from 'react';
import {GetSubscriptionQuery} from '../services/getSubscriptionQuery';
import {SubscriptionQuery} from '../models/SubscriptionQuery';
import {SUBSCRIPTION_CREATE, SUBSCRIPTION_DISPLAY} from '../configs/constant';
import SubscriptionList from './SubscriptionList';
import SubscriptionFieldListDetail from './SubscriptionFieldListDetail';
import SubscriptionInvoicesList from './SubscriptionsInvoicesList';
import SubscriptionCreation from './SubscriptionCreation';

/**
 * Router for subscriptions:
 * Main entry point to display subscription listing or subscribe dialog
 * @param param0 
 * @returns 
 */
export default function SubscriptionsDialog() {

    if (GetSubscriptionQuery(SubscriptionQuery.SUBSCRIPTION) === SUBSCRIPTION_CREATE) {
        return (
            <SubscriptionCreation />
        )

    } else if (GetSubscriptionQuery(SubscriptionQuery.SUBSCRIPTION) === SUBSCRIPTION_DISPLAY) {

        return (
            <SubscriptionList />
        )

    } else if (GetSubscriptionQuery(SubscriptionQuery.FIELD_LIST)) {
        return (
            <SubscriptionFieldListDetail />
        )
    } else if (GetSubscriptionQuery(SubscriptionQuery.INVOICE)) {
        return (
            <SubscriptionInvoicesList />
        )
    }
    return null

}
