import {call, put, takeEvery, all} from 'redux-saga/effects';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../../../shared/actions/Common';
import {LOAD_CAMPAIGNS} from '../actions/CampaignsActions';
import Campaign from '../models/Campaign';
import api, {wagoApiUrl} from '../../../shared/services/ApiConfig';
import actions from '../actions/CampaignsActions';

function getCampaignsRequest() {
  const getCampaignsUrl = `${wagoApiUrl}/campaigns`;
  return api.get(getCampaignsUrl);
}

function* loadCampaignsListing() {
  try {
    yield put(fetchStart("loadCampaignsListing"));
    const res = yield call(getCampaignsRequest);
    const campaignsListing = res.data as Campaign[];
    const campaignsMap: {[key: string]: Campaign} = {};
    campaignsListing.forEach((value) => {
      if (value.campaign_id !== undefined) campaignsMap[value.campaign_id] = value
    });
    yield put(actions.loadCampaignSucces(campaignsMap));
    yield put(fetchSuccess("loadCampaignsListing"));
  } catch (error) {
    yield put(fetchError("loadCampaignsListing", error.message));
  }
}

export function* campaignsSagas() {
  yield all([
    takeEvery(LOAD_CAMPAIGNS, loadCampaignsListing),
  ])
}