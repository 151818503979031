/**
 * Path to redirect to current campaign
 */
export const indexPath = '/campaigns';

/**
 * Campaign parameter
 */
export const selectedCampaignParam = 'campaignId';

/**
 * Campaign root path
 */
export const selectedCampaignRootPath = `${indexPath}/:${selectedCampaignParam}`;
