import React from 'react';
import {Box, BoxProps, IconButton, makeStyles} from '@material-ui/core';
import QuestionIcon from 'shared/components/Icons/QuestionIcon';
import Informations from 'shared/components/Infos/Informations';
import BaseDialog from 'shared/components/BaseDialog';
import {CremaTheme} from 'shared/models/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  dialogInfo: {
    height: '700px',
    minHeight: '700px',
    [theme.breakpoints.up('xs')]: {
      width: '430px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '560px',
    },
    [theme.breakpoints.up('md')]: {
      width: '915px',
      minWidth: '915px',
    },
  },
}));

interface InformationDialogProps extends BoxProps {
  /**
   * to manage which part of the information dialog is shown
   */
  tabSelected: string;
  /**
   * to manage the closing of the information dialog
   */
  handleModal: () => void;
  /**
   * state of the information dialog open/close
   */
  open: boolean;
  /**
   * whether to show or not the question mark, depending on where the component is called
   */
  showQuestionMark: boolean;
}

/**
 * Component for showing information dialog
 * Contains an icon shown next to the title's components
 * And the informationDialog
 */
const InformationDialog: React.FC<InformationDialogProps> = ({
  tabSelected,
  handleModal,
  open,
  showQuestionMark,
  ...boxProps
}) => {
  const classes = useStyles();

  return (
    <Box>
      {showQuestionMark ? (
        <>
          <IconButton onClick={handleModal}>
            <QuestionIcon fontSize='inherit' color='primary' />
          </IconButton>
          <BaseDialog
            open={open}
            titleMessageId='baseDialog.noTitle'
            onClose={handleModal}
            classes={{paper: classes.dialogInfo}}>
            <Informations
              selectedInfo={tabSelected}
              handleModal={handleModal}
              open={open}
            />
          </BaseDialog>
        </>
      ) : (
        <BaseDialog
          open={open}
          titleMessageId='baseDialog.noTitle'
          onClose={handleModal}
          classes={{paper: classes.dialogInfo}}>
          <Informations
            selectedInfo={tabSelected}
            handleModal={handleModal}
            open={open}
          />
        </BaseDialog>
      )}
    </Box>
  );
};
export default InformationDialog;
