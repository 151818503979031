import React from 'react';
import useStyles from './Informations.style';
import {Box, Typography} from '@material-ui/core';
import ArrowOverview1 from 'shared/components/Icons/ArrowOverview1';
import ArrowOverview2 from 'shared/components/Icons/ArrowOverview2';
import ArrowOverview3 from 'shared/components/Icons/ArrowOverview3';
import ArrowOverview4 from 'shared/components/Icons/ArrowOverview4';
import {SvgIconProps} from '@material-ui/core';
import IntlMessages from '@crema/utility/IntlMessages';

interface OverviewProps extends SvgIconProps { }

/**
 * Component for showing overview in the information dialog
 * Contains an image and indication for the user to understand the application
 */
const Overview: React.FC<OverviewProps> = () => {
  const classes = useStyles();
  return (
    <>
      <Box height={1} width={1} display='flex' flexDirection='column'>
        <Typography variant='h4' className={classes.dialogSubtitle}>
          <IntlMessages id='informations.overview.title' />
        </Typography>
        <Box width='100%' className={classes.overviewImg}>
          <img
            src={require('assets/images/dialogInfoImages/overviewDialog.jpg')}
            alt='site-overview'
          />
        </Box>
      </Box>
      <Box className={classes.boxTextOverview1}>
        <Typography variant='body2' className={classes.textBold}>
          <IntlMessages id='informations.overview.text1' />
        </Typography>
      </Box>
      <Box className={classes.boxTextOverview2}>
        <Typography variant='body2' className={classes.textBold}>
          <IntlMessages id='informations.overview.text2' />
        </Typography>
      </Box>
      <Box className={classes.boxTextOverview3}>
        <Typography variant='body2' className={classes.textBold}>
          <IntlMessages id='informations.overview.text3' />
        </Typography>
      </Box>
      <Box className={classes.boxTextOverview4}>
        <Typography variant='body2' className={classes.textBold}>
          <IntlMessages id='informations.overview.text4' />
        </Typography>
      </Box>
      <Box className={classes.boxTextOverview5}>
        <Typography variant='body2' className={classes.textBold}>
          <IntlMessages id='informations.overview.text5' />
        </Typography>
      </Box>
      <Box className={classes.arrowOverview1}>
        <ArrowOverview1
          color='primary'
          fontSize='large'
          classes={{fontSizeLarge: classes.svgArrowMedium}}
        />
      </Box>
      <Box className={classes.arrowOverview2}>
        <ArrowOverview2
          color='primary'
          fontSize='large'
          classes={{fontSizeLarge: classes.svgArrowLarge}}
        />
      </Box>
      <Box className={classes.arrowOverview3}>
        <ArrowOverview3
          color='primary'
          fontSize='large'
          classes={{fontSizeLarge: classes.svgArrowMedium}}
        />
      </Box>
      <Box className={classes.arrowOverview4}>
        <ArrowOverview4
          color='primary'
          fontSize='large'
          classes={{fontSizeLarge: classes.svgArrowLarge}}
        />
      </Box>
      <Box className={classes.arrowOverview5}>
        <ArrowOverview1
          color='primary'
          fontSize='large'
          classes={{fontSizeLarge: classes.svgArrowMedium}}
        />
      </Box>
    </>
  );
};

export default Overview;
