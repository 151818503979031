import log from 'shared/services/LogService';
import React from 'react';
import {IntlShape, useIntl} from 'react-intl';

// 'intl' service singleton reference
let intl: IntlShape;

interface IntlGlobalProviderProps { }

const IntlGlobalProvider: React.FC<IntlGlobalProviderProps> = (props) => {

  intl = useIntl(); // Keep the 'intl' service reference

  if (intl) {
    log.debug(`Intl global provider correctly initialized`);
  }
  return <>{props.children}</>;;
};

export default IntlGlobalProvider;

// Getter function to expose the read-only 'intl' service
export function appIntl(): IntlShape {
  if (!intl) {
    log.error(`Intl global provider null`);
  }
  return intl;
}