import {Box, Card, Typography} from '@material-ui/core';
import {AuthUser} from 'modules/auth/models/AuthUser';
import CropIcon from 'modules/crops/components/icons/CropIcon';
import {getCropTranslationKeyFromCode} from 'modules/crops/services/CropsTranslation';
import {getHydroStressMessageId, getNdviHomogeneityMessageId} from 'modules/waterBalance/services/WaterBalanceService';
import React from 'react';
import {useIntl} from 'react-intl';
import IntlMessages from 'shared/components/IntlMessages';
import Field from '../models/Field';
import useStyles from './FieldsMap.style';


export interface FieldsMapTooltipProps {
    field: Field;
    position: {
        x: number;
        y: number;
    }
    user: AuthUser | null;
}

/**
 * Tooltip displayed on map
 * @param props 
 * @returns 
 */
const FieldsMapTooltip: React.FC<FieldsMapTooltipProps> = (props) => {
    const {field, position, user} = props;
    const intl = useIntl();
    const classes = useStyles();

    if (field) {
        return (
            <Card
                className={classes.tooltip}
                style={{left: position.x, top: position.y}}>
                <Box>
                    <Typography variant='h6'>{field.field_name}</Typography>
                    <Box display="flex" flexDirection="row">
                        <Typography color='textSecondary'>
                            {field.crop_code ? intl.formatMessage({id: getCropTranslationKeyFromCode(field.crop_code)!}) : field.crop_name}
                        </Typography>
                        <CropIcon color='inherit' crop={field.crop_type} />
                    </Box>
                </Box>

                <Box mt={3}>
                    <Typography color='textSecondary'>
                        <IntlMessages id='waterBalance.SWC2Risk.label' />
                    </Typography>
                    {
                        //TODO : color
                    }
                    {field.RAWC ? (
                        <Typography variant='body2' color='textPrimary'>
                            <IntlMessages
                                id={getHydroStressMessageId(
                                    field.RAWC.most_recent_value,
                                    user,
                                )}
                            />
                        </Typography>
                    ) : (
                        <Typography variant='body2' color='textPrimary'>
                            <IntlMessages id='common.notAvailable' />
                        </Typography>
                    )}
                </Box>

                <Box mt={3}>
                    <Typography color='textSecondary'>
                        <IntlMessages id='waterBalance.NdviHomogeneity.label' />
                    </Typography>
                    {field.ndvi && field.ndvi.ndvi_most_recent_value_std ? (
                        <Typography variant='body2' color='textPrimary'>
                            <IntlMessages
                                id={getNdviHomogeneityMessageId(
                                    field.ndvi.ndvi_most_recent_value_std,
                                )}
                            />
                        </Typography>
                    ) : (
                        <Typography variant='body2' color='textPrimary'>
                            <IntlMessages id='common.notAvailable' />
                        </Typography>
                    )}
                </Box>
            </Card>
        );
    }
    return null;
};
export default FieldsMapTooltip;
