import React, {useContext} from 'react';
import AppHeader from './AppHeader';
import ContentView from './ContentView';
import Box from '@material-ui/core/Box';
import useStyles from './AppLayout.style';
import clsx from 'clsx';
import AppContext from '../../../@crema/utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import {LayoutType} from '../../configs/AppEnums';
import AppContextPropsType from '../../models/AppContextPropsType';
import InfoView from '../InfoView';
import SubscriptionsDialog from 'modules/subscriptions/components/SubscriptionsDialog';

interface AppLayoutProps {
  props?: any;
}

const AppLayout: React.FC<AppLayoutProps> = (props) => {
  const classes = useStyles(props);
  const {footer, layoutType, footerType} = useContext<AppContextPropsType>(
    AppContext,
  );

  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />

      <Box className={classes.mainContent}>
        <Box className={classes.mainContainerFull}>
          <SubscriptionsDialog />
          <ContentView>
            {props.children}
          </ContentView>
          <InfoView />
          <AppFixedFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
