import {call, put, all, takeEvery, select} from 'redux-saga/effects';
import {
  actions,
  AUTHENTICATE_USER,
  AuthenticateUserAction,
  LOAD_USER_INFO,
  LoadUserInfoAction,
  SIGNOUT_USER,
  SignoutUserAction,
  MODIFY_USER_INFO,
  ModifyUserInfoAction,
  CHANGE_USER_PASSWORD,
  ChangeUserPasswordAction,
  CREATE_USER,
  CreateUserAction,
  SAVE_PREFERRED_LANGUAGE,
  SavePreferredLanguageAction,
  ResetPasswordAction,
  RESET_PASSWORD,
  LOAD_ROLES_CONFIG,
  LoadRolesConfigAction,
  ModifyUserTresholdAction,
  MODIFY_USER_TRESHOLD,
  MODIFY_USER_NOTIFICATION,
  ModifyUserNotificationAction,
  LOAD_ALL_USERS,
  LoadAllUsersAction,
  SetUserRoleAction,
  SET_USER_ROLE,
} from '../actions/AuthActions';
import fieldsAction from '../../fields/actions/FieldsActions';
import waterBalanceAction from '../../waterBalance/actions/WaterBalanceActions';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  showMessage,
} from '../../../shared/actions/Common';
import api, {wagoApiUrl} from '../../../shared/services/ApiConfig';
import {AuthRequest} from '../models/AuthRequestModel';
import {UserResponseModel} from '../models/UserResponseModel';
import {ChangeUserPasswordModel} from '../models/ChangeUserPasswordModel';
import {delay} from 'redux-saga/effects';
import {appIntl} from 'shared/utils/IntlGlobalProvider';
import campaignsActions from '../../campaigns/actions/CampaignsActions';
import cropsActions from '../../crops/actions/CropsActions';
import soilsActions from '../../soils/actions/SoilsActions';
import {setInitialPath} from 'shared/actions/Setting';
import sysIrrigationActions from '../../sysIrrigation/actions/SysIrrigationActions';
import {AuthUser} from '../models/AuthUser';
import rsf, {auth, firebaseApp, firestore} from 'shared/services/ReduxSagaFirebase';
import log, {setRemoteLogsConfig} from 'shared/services/LogService';
import {IdTokenResult} from '@firebase/auth-types';
import {actions as subscriptionsActions} from '../../subscriptions/actions/SubscriptionsActions';
import authConfig from '../configs/AuthConfig';
import {SavePreferredLocaleRequest} from '../models/SavePreferredLocaleRequest';
import Customer, {CustomerServiceInfo} from '../models/Customer';
import {defaultRolesConfig} from '../configs/DefaultRolesConfig';
import {TerranisService} from 'shared/models/TerranisService';
import {AppState} from 'shared/store';
import {isProdEnv} from 'shared/configs/FirebaseConfig';
import * as FirebaseFirestore from '@firebase/firestore-types';
import {SaveCustomerRoleRequest} from '../models/SaveCustomerRoleRequest';

/**
 * Wait for max 3s to consider that wago user has not bee correctly propagated
 */
const MAX_USER_PROPAGATION_WAIT_TIME = 3;
export const LOGIN_URL = '/login';

export const getCurrentUserToken = (): Promise<IdTokenResult> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        unsubscribe();
        const tokenResult = await userAuth.getIdTokenResult();
        if (!isProdEnv) {
          log.info(`Retrieved user token: ${tokenResult.token}`);
        }
        setRemoteLogsConfig(tokenResult.token)
        resolve(tokenResult);
      }
    }, reject);
  });
};

function* authenticateUser(action: AuthenticateUserAction) {
  try {
    yield put(fetchStart("authenticateUser"));


    let token: string = "";
    let tokenExpiration: number = 0;

    yield call(rsf.auth.signInWithEmailAndPassword, action.payload.email, action.payload.password);
    const tokenResult: IdTokenResult = yield call(getCurrentUserToken);
    token = tokenResult.token;
    tokenExpiration = Math.floor(Date.parse(tokenResult.expirationTime) / 1000);

    yield put(actions.setJWTToken({token, tokenExpiration}));

    yield put(fetchSuccess("authenticateUser"));

    yield put(actions.loadUserInfo());

  } catch (error) {
    yield put(
      fetchError("authenticateUser", appIntl().formatMessage({id: 'authenticateUser.error'})),
    );
  }
}

function getUserInfoRequest() {
  const userUrl = `${wagoApiUrl}/users`;
  return api.get(userUrl);
}

function* loadUserInfo(action: LoadUserInfoAction) {
  try {
    const user = firebaseApp.auth().currentUser;

    if (user) {
      yield put(fetchStart("loadUserInfo"));
      const uid = user.uid;

      // Get customer from terranis transverse project with authentication
      const customerDocRef = firestore.collection(authConfig.USERS_COLLECTION).doc(uid);
      const userSnap = yield call(rsf.firestore.getDocument, customerDocRef);
      const customer: Customer = userSnap.data() as Customer;

      let userInfos: UserResponseModel[] | null = null;
      // if it's just after a creation, wait for the user propagation to wago
      let iWaitIteration = 0;
      let foundWagoUser = false;
      while (!foundWagoUser && iWaitIteration < MAX_USER_PROPAGATION_WAIT_TIME) {
        try {
          const res = yield call(getUserInfoRequest);
          log.info(`Loading user info for user: ${uid} (iteration: ${iWaitIteration})`);
          userInfos = res.data as UserResponseModel[];
          if (userInfos && userInfos.length > 0) {
            foundWagoUser = true;
          }
        } catch (error) {
          log.debug(`Error retrieving wago user info`, error);
        }
        yield delay(1000);
        iWaitIteration++;
      }

      if (userInfos && userInfos.length > 0) {
        // TODO: why several user info
        const mainUserInfo = userInfos[0];
        /*console.log(
          `res (status ${res.status}): ${JSON.stringify(mainUserInfo)}`,
        );*/
        const authUser: AuthUser = {
          uid: uid,
          username: mainUserInfo.username,
          email: mainUserInfo.email,
          role: mainUserInfo.role,
          photoURL: '',
          organism: mainUserInfo.organism,
          lastname: mainUserInfo.lastname,
          firstname: mainUserInfo.firstname,
          threshold_high: mainUserInfo.threshold_high,
          threshold_medium: mainUserInfo.threshold_medium,
          alert_mail: mainUserInfo.alert_mail,
          alert_sms: mainUserInfo.alert_sms
        };
        yield put(actions.updateAuthUser(authUser, customer));
        yield put(fetchSuccess("loadUserInfo"));
        yield put(subscriptionsActions.loadSubscriptions());
        yield put(campaignsActions.loadCampaign());
        yield put(cropsActions.loadCropsListing());
        yield put(soilsActions.loadSoilsListing());
        yield put(sysIrrigationActions.loadSysIrrigation());
        yield put(fieldsAction.loadFieldsSummary(null, true, null, authUser));

      } else {
        console.log(`Invalid user data for user ${uid}`);
        yield put(fetchError("loadUserInfo", 'Invalid user data'));
      }

    }
  } catch (error) {
    log.error(`Error loading user info: ${error.message}`, error);
    yield put(fetchError("loadUserInfo", error.message));
  }
}

function* loadAllUsers(action: LoadAllUsersAction) {

  try {
    yield put(fetchStart("loadAllUsers"));

    const allUsersSnapShot: FirebaseFirestore.QuerySnapshot = yield call(rsf.firestore.getCollection, `customers`)
    let allUsers: {[key: string]: Customer} = {};
    allUsersSnapShot.forEach((data) => {
      const userData = data.data() as Customer;
      userData.id = data.id;
      allUsers[data.id] = userData;
    })

    yield put(actions.loadAllUsersSuccess(allUsers))
    yield put(fetchSuccess("loadAllUsers"));

  } catch (error) {
    log.error(`Error loading all users: ${error.message}`, error);
    yield put(fetchError("loadAllUsers", error.message));
  }
}

function* loadRolesConfig(action: LoadRolesConfigAction) {
  try {

    // TODO: to be done
    // const res = yield call(getRolesConfigRequest);
    //const rolesConfig = res.data as {[key: string]: RoleConfig};

    yield put(actions.loadRolesConfigSuccess(defaultRolesConfig))

  } catch (error) {
    log.error(`Error loading roles config`, error);
  }
}

function* signoutUser(action: SignoutUserAction) {
  yield put(fetchStart("signoutUser"));
  yield put(actions.setJWTToken(null));
  yield call(rsf.auth.signOut);
  yield delay(500);
  yield put(actions.signoutUserSuccess());
  yield put(fieldsAction.clearFieldsData());
  yield put(waterBalanceAction.clearWaterBalanceData());
  // Clear initial path to avoid loading other user field
  yield put(setInitialPath('/'));
  yield put(fetchSuccess("signoutUser"));
}

/*function modifyUserInfoRequest(data: ModifyUserInfoModel) {
  const modifyUserInfo = {
    lastname: data.lastname,
    firstname: data.firstname,
    organism: data.organism,
    email: data.email,
  };
  const userUrl = `${wagoApiUrl}/users`;
  return api.put(userUrl, modifyUserInfo);
}*/

function* modifyThresholdInfo(action: ModifyUserTresholdAction) {
  try {
    yield put(fetchStart("modifyThresholdInfo"));

    const thresholdUrl = `${wagoApiUrl}/users/thresholds`;
    api.put(thresholdUrl, action.payload);

    yield put(fetchSuccess("modifyThresholdInfo"))
  } catch (error) {
    yield put(fetchError("modifyThresholdInfo", error.message));
  }
}

function* modifyNotificationInfo(action: ModifyUserNotificationAction) {
  try {
    yield put(fetchStart("modifyNotificationInfo"));

    const notificationUrl = `${wagoApiUrl}/users/notifications`;
    api.put(notificationUrl, action.payload);

    yield put(fetchSuccess("modifyNotificationInfo"))
  } catch (error) {
    yield put(fetchError("modifyNotificationInfo", error.message));
  }
}

function* setUserRole(action: SetUserRoleAction) {
  try {
    if (action.payload.user) {
      const updatedCustomer = {...action.payload.user};
      yield put(fetchStart("setUserRole"));
      const customerDocRef = firestore.collection(authConfig.USERS_COLLECTION).doc(updatedCustomer.id);
      if (!updatedCustomer.servicesList) {
        updatedCustomer.servicesList = [];
      }
      if (!updatedCustomer.servicesList.includes(TerranisService.WAGO)) {
        updatedCustomer.servicesList.push(TerranisService.WAGO)
      }
      if (!updatedCustomer.servicesInfo) {
        updatedCustomer.servicesInfo = {};
      }
      let wagoServiceInfo: CustomerServiceInfo = updatedCustomer.servicesInfo[TerranisService.WAGO];
      if (!wagoServiceInfo) {
        wagoServiceInfo = {};
      }
      wagoServiceInfo.role = action.payload.role;

      updatedCustomer.servicesInfo[TerranisService.WAGO] = wagoServiceInfo;

      const saveCustomerRoleRequest: SaveCustomerRoleRequest = {
        servicesList: updatedCustomer.servicesList,
        servicesInfo: {}
      }
      saveCustomerRoleRequest.servicesInfo[TerranisService.WAGO] = wagoServiceInfo;
      log.debug("Updating user role");
      yield call(rsf.firestore.updateDocument, customerDocRef, saveCustomerRoleRequest)

      yield put(actions.setUserRoleSuccess(updatedCustomer));
      yield put(fetchSuccess("setUserRole"))
    } else {
      log.error(`Error setting user role: no user`)
    }
  } catch (error) {
    log.error(`Error setting user ${action.payload.user.id} role`, error)
    yield put(fetchError("setUserRole", error.message));
  }
}

function* savePreferredLanguage(action: SavePreferredLanguageAction) {
  const uid = auth.currentUser?.uid;
  try {
    if (uid) {
      const customerDocRef = firestore.collection(authConfig.USERS_COLLECTION).doc(uid);
      const savePreferredLocaleRequest: SavePreferredLocaleRequest = {
        preferred_locales: [action.payload],
      }
      yield call(rsf.firestore.updateDocument, customerDocRef, savePreferredLocaleRequest)
    } else {
      log.error(`Error saving user ${uid} preferred language: no uid`)
    }
  } catch (error) {
    log.error(`Error saving user ${uid} preferred language`, error)
    // Do not display any message to user (not needed)
  }
}

function* modifyUserInfo(action: ModifyUserInfoAction) {
  try {

    yield put(fetchStart("modifyUserInfo"));

    const {user, customer} = yield select((state: AppState) => state.auth);
    const uid = user.uid

    let customerUpdated: Customer = {
      ...customer,
      email: action.payload.email,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      organism: action.payload.organism,
      phone: action.payload.phone,
      address: {
        postal_code: action.payload.postal_code,
        city: action.payload.city,
        line1: action.payload.address,
        country: action.payload.country
      },
    }

    if (action.payload.taxNumber) {
      customerUpdated = {
        ...customerUpdated,
        tax_id: {
          type: 'eu_vat',
          value: action.payload.taxNumber
        }
      }
    }

    yield call(rsf.firestore.updateDocument, `${authConfig.USERS_COLLECTION}/${uid}`, customerUpdated)
    yield put(actions.updateAuthUser(user, customerUpdated))
    yield put(fetchSuccess("modifyUserInfo"));
  } catch (error) {
    yield put(fetchError("modifyUserInfo", error.message));
  }
}

function changeUserPasswordRequest(data: ChangeUserPasswordModel) {
  const userPasswordUrl = `${wagoApiUrl}/users/password`;
  return api.put(userPasswordUrl, data);
}

function* changeUserPassword(action: ChangeUserPasswordAction) {
  try {
    const data = action.payload;
    const dataToChangePassword: ChangeUserPasswordModel = {
      old_password: data.old_password,
      password: data.password,
    };
    yield put(fetchStart("changeUserPassword"));
    yield call(changeUserPasswordRequest, dataToChangePassword);
    yield put(fetchSuccess("changeUserPassword"));
  } catch (error) {
    yield put(fetchError("changeUserPassword", error.message));
  }
}

function* createUser(action: CreateUserAction) {
  try {
    yield put(fetchStart("createUser"));

    const resp: any = yield call(rsf.auth.createUserWithEmailAndPassword, action.payload.email, action.payload.password);
    const uid = resp.user.uid;
    log.info(`User created in firebase with id: ${uid}`);
    const customerDocRef = firestore.collection(authConfig.USERS_COLLECTION).doc(uid);
    const customer: Customer = {
      id: uid,
      email: action.payload.email,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      organism: action.payload.organism,
      servicesList: [TerranisService.WAGO],
      phone: action.payload.phone,
      address: {
        postal_code: action.payload.postal_code,
        city: action.payload.city,
        line1: action.payload.address,
        country: action.payload.country
      },
    }
    if (action.payload.taxNumber) {
      customer.tax_id = {
        type: 'eu_vat',
        value: action.payload.taxNumber
      }
    }
    if (action.payload.locale) {
      customer.preferred_locales = [action.payload.locale];
    }
    customer.servicesList = [TerranisService.WAGO];
    yield call(rsf.firestore.setDocument, customerDocRef, customer, {merge: true});

    // Wait for user propagation
    yield delay(2000);

    yield put(fetchSuccess("createUser"));
    const authData: AuthRequest = {
      email: action.payload.email,
      password: action.payload.password,
    };
    yield put(actions.authenticateUser(authData));
  } catch (error) {
    log.error(`Error creating user`, error);
    yield put(fetchError("createUser", error.message));
  }
}

// TODO: Change in saga when implemented
function* resetPassword(action: ResetPasswordAction) {
  try {
    yield put(fetchStart("resetPassword"));
    yield call(rsf.auth.sendPasswordResetEmail, action.payload, null);
    yield put(fetchSuccess("resetPassword"));
    yield call(signoutUser, {type: SIGNOUT_USER});
    yield put(showMessage(appIntl().formatMessage({id: "auth.reset_password_confirmation"})));
  } catch (error) {
    log.error(`Error while resetting password`, error);
    yield put(fetchError("resetPassword", error.message));
  }
}

export function* authSagas() {
  //only last call perform
  yield all([
    takeEvery(AUTHENTICATE_USER, authenticateUser),
    takeEvery(LOAD_USER_INFO, loadUserInfo),
    takeEvery(LOAD_ROLES_CONFIG, loadRolesConfig),
    takeEvery(LOAD_ALL_USERS, loadAllUsers),
    takeEvery(SET_USER_ROLE, setUserRole),
    takeEvery(SIGNOUT_USER, signoutUser),
    takeEvery(MODIFY_USER_INFO, modifyUserInfo),
    takeEvery(CHANGE_USER_PASSWORD, changeUserPassword),
    takeEvery(CREATE_USER, createUser),
    takeEvery(SAVE_PREFERRED_LANGUAGE, savePreferredLanguage),
    takeEvery(RESET_PASSWORD, resetPassword),
    takeEvery(MODIFY_USER_TRESHOLD, modifyThresholdInfo),
    takeEvery(MODIFY_USER_NOTIFICATION, modifyNotificationInfo)
  ]);
}
