import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';
import {ReactComponent as Corn} from '../../assets/vine.svg';

const VineIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} component={Corn} viewBox="0 0 24 24" />
  );
};

export default VineIcon;
