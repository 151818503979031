import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import * as yup from 'yup';
import {useIntl} from 'react-intl';
import {Form, Formik} from 'formik';
import {Box, Button} from '@material-ui/core';
import MyTextField from 'shared/components/MyTextField';
import IntlMessages from 'shared/components/IntlMessages';
import useStyles from 'shared/components/layout/HeaderButton.style';
import useSignupStyles from '../Signup/UserSignup.style';
import clsx from 'clsx';
import {getUserInfo} from 'modules/auth/services/getUserInfo';
import {ModifyUserInfoModel} from 'modules/auth/models/ModifyUserInfoModel';
import {defaultUserInfo} from 'modules/auth/configs/DefaultUserInfo';
import {actions} from '../../actions/AuthActions';
import {validateTaxNumber} from 'modules/auth/services/AuthService';
import {CountryDropdown} from 'react-country-region-selector';
import log from 'shared/services/LogService';

export default function () {
    const dispatch = useDispatch();
    const {customer} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
    const intl = useIntl();
    const classes = useStyles();
    const classesSignup = useSignupStyles();

    const [changeInfo, setChangeInfo] = React.useState<boolean>(true);
    const handleChangeInfo = () => {
        setChangeInfo(false);
    };

    let userInfo: ModifyUserInfoModel = defaultUserInfo

    if (customer) {
        userInfo = getUserInfo(customer)
    }

    const [country, setCountry] = React.useState<string>(userInfo.country ? userInfo.country : "");

    const validationSchema = yup.object({
        organism: yup
            .string()
            .max(50, intl.formatMessage({id: 'form.validation.maxString'}))
            .required(intl.formatMessage({id: 'validation.organismRequired'})),
        taxNumber: yup
            .string().test("tax", intl.formatMessage({id: 'auth.signup.invalid_tax'}), (val) => validateTaxNumber(val as string, country)),
        address: yup
            .string()
            .required(intl.formatMessage({id: 'validation.adressRequired'})),
        postal_code: yup
            .string()
            .required(intl.formatMessage({id: 'validation.postalCodeRequired'})),
        city: yup
            .string()
            .required(intl.formatMessage({id: 'validation.cityRequired'})),
    })
    return (
        <>
            <Box className={classes.userForm}>
                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={userInfo}
                    validationSchema={validationSchema}
                    onSubmit={(data: ModifyUserInfoModel) => {
                        data.country = country;
                        dispatch(actions.modifyUserInfo(data))
                        setChangeInfo(true)
                    }}>
                    <Form className={classes.fullWidth} autoComplete='off'>
                        <Box className={classes.textFormContainer}>
                            <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                                <MyTextField
                                    className={classes.fullWidth}
                                    disabled={changeInfo}
                                    placeholder={userInfo.organism}
                                    label={<IntlMessages id='modifyOrganism.organism' />}
                                    name='organism'
                                    variant='outlined' />
                            </Box>
                            <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                                <MyTextField
                                    className={classes.fullWidth}
                                    disabled={changeInfo}
                                    placeholder={userInfo.taxNumber}
                                    label={<IntlMessages id='modifyOrganism.taxNumber' />}
                                    name='taxNumber'
                                    variant='outlined' />
                            </Box>
                        </Box>
                        <Box className={classes.textFormContainer}>

                            <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                                <MyTextField
                                    className={classes.fullWidth}
                                    disabled={changeInfo}
                                    placeholder={userInfo.address}
                                    label={<IntlMessages id='modifyOrganism.address' />}
                                    name='address'
                                    variant='outlined' />
                            </Box>
                        </Box>

                        <Box className={classes.textFormContainer}>
                            <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                                <MyTextField
                                    className={classes.fullWidth}
                                    disabled={changeInfo}
                                    placeholder={userInfo.postal_code}
                                    label={<IntlMessages id='modifyOrganism.postal_code' />}
                                    name='postal_code'
                                    variant='outlined' />
                            </Box>
                            <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                                <MyTextField
                                    className={classes.fullWidth}
                                    disabled={changeInfo}
                                    placeholder={userInfo.city}
                                    label={<IntlMessages id='modifyOrganism.city' />}
                                    name='city'
                                    variant='outlined' />
                            </Box>
                        </Box>
                        <Box className={classes.textFormContainer}>
                            <Box width={1} style={{margin: '1em'}}>
                                <CountryDropdown
                                    value={country}
                                    defaultOptionLabel={intl.formatMessage({id: "auth.signInWithPopup.country"})}
                                    labelType="full"
                                    valueType="short"
                                    disabled={changeInfo}
                                    classes={classesSignup.countryList}
                                    name="country"
                                    onChange={(val) => {
                                        log.debug(`Selected country: ${val}`);
                                        setCountry(val)
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box
                            flexDirection='column'
                            alignItems='center'
                            display={changeInfo ? 'none' : 'flex'}>
                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                className={classes.formUserButton}>
                                <IntlMessages id='modifyUser.submitButton' />
                            </Button>
                        </Box>
                    </Form>
                </Formik>
                <Box flexDirection='row' alignItems='center' display={!changeInfo ? 'none' : 'flex'}>
                    <Button
                        variant='contained'
                        color='primary'
                        type='button'
                        className={classes.formUserButton}
                        onClick={() => handleChangeInfo()}>
                        <IntlMessages id='modifyUser.modifyButton' />
                    </Button>
                </Box>
            </Box>
        </>
    )
}

