import React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import log from "shared/services/LogService";

interface EBProps { }
interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
}

class ErrorBoundary extends React.Component<
    EBProps & RouteComponentProps,
    ErrorBoundaryState
> {
    constructor(props: EBProps & RouteComponentProps) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
        };
    }
    static getDerivedStateFromError(error: any) {
        return {error: error};
    }

    render() {
        const {error} = this.state;
        if (error) {
            log.error(`Error catch by error boundary`, error);
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);