import {Reducer} from 'redux';
import {
  RotationActionTypes,
  LOAD_ROTATIONS,
  LOAD_ROTATIONS_SUCCESS,
} from '../actions/RotationsActions';
import RotationState from '../models/RotationState';

const initialState: RotationState = {
  data: {}
};

const rotationReducers: Reducer<RotationState, RotationActionTypes> = (state: RotationState = initialState, action: RotationActionTypes) => {
  switch (action.type) {
    case LOAD_ROTATIONS:
      return {
        ...state,
      }
    case LOAD_ROTATIONS_SUCCESS:
      return {
        data: action.payload
      }
    default:
      return state;
  }
};

export default rotationReducers;