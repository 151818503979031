import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';
import {ReactComponent as Leaf} from '../../assets/default.svg';

const DefaultCropIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} component={Leaf} viewBox="0 0 24 24" />
  );
};

export default DefaultCropIcon;
