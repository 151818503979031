import React from 'react';
import {useDispatch} from 'react-redux';
import authActions from '../actions/AuthActions';
import {useAuthUser} from '../../../shared/utils/AppHooks';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IntlMessages from '../../../shared/components/IntlMessages';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import useStyles from '../../../shared/components/layout/HeaderButton.style';
import ChangePassword from './ModifyUserInfo/ChangePassword';
import InformationDialog from 'shared/components/Infos/InformationDialog';
import {InfoDialogChoice} from 'shared/configs/AppEnums';
import BaseDialog from 'shared/components/BaseDialog';
import {useHistory} from 'react-router-dom';
import {QUERY_SUBSCRIPTION_DISPLAY} from 'modules/subscriptions/configs/constant';
import {SubscriptionQuery} from 'modules/subscriptions/models/SubscriptionQuery';
import SettingsUserInfo from './settingsUserInfo/SettingsUserInfo';

interface UserHeaderButtonProps {
  alwaysShowTitle?: boolean;
  handleMobileMenuClose?: () => void;
}

/**
 * Header buttons used to access & change user information
 * Includes also access to help and logout
 * @param props
 */
const UserHeaderButton: React.FC<UserHeaderButtonProps> = (
  props: UserHeaderButtonProps,
) => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalPassword, setOpenModalPassword] = React.useState<boolean>(
    false,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalModifyUser = () => {
    setOpenModal(!openModal);
  };

  const handleModalChangePassword = () => {
    setOpenModalPassword(!openModalPassword);
  };


  const handleModalSubscription = () => {
    if (history.location.search.includes(SubscriptionQuery.SUBSCRIPTION)) {
      history.push({pathname: history.location.pathname})
    } else {
      history.push({pathname: history.location.pathname, search: QUERY_SUBSCRIPTION_DISPLAY})
    }
  }

  const onClickParams = (event: React.MouseEvent<HTMLLIElement>) => {
    handleModalModifyUser();
  };

  const classes = useStyles();
  const open = Boolean(anchorEl);

  /**
   * To manage information dialog opening
   */
  const [openInfo, setOpenInfo] = React.useState(false);
  function handleModalInfo() {
    setOpenInfo(!openInfo);
  }

  const title = () => {
    return (
      <Box
        className={clsx(classes.headerBtnTitle, 'headerBtnTitle')}
        component='span'>
        <IntlMessages id='auth.headerButton.accountTitle' />
      </Box>
    );
  };

  return (
    <>
      <Box>
        <IconButton
          className={clsx(classes.headerBtn, 'headerBtn')}
          color='inherit'
          onClick={handleClick}>
          <AccountCircleIcon
            className={clsx(classes.headerBtnIcon, 'headerBtnIcon')}
          />
          {props.alwaysShowTitle ? title() : <Hidden mdDown>{title()}</Hidden>}
        </IconButton>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}>
          <MenuItem
            onClick={() => {
              handleModalSubscription();
              handleClose();
            }}>
            <IntlMessages id='subscriptions.title' />
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              onClickParams(event);
              props.handleMobileMenuClose && props.handleMobileMenuClose();
              handleClose();
            }}>
            <IntlMessages id='auth.headerMenu.parametersTitle' />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleModalInfo();
              handleClose();
            }}>
            <IntlMessages id='auth.headerMenu.helpTitle' />
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (user) {
                dispatch(authActions.signoutUser());
              }
            }}>
            <IntlMessages id='auth.headerMenu.logoutTitle' />
          </MenuItem>
        </Menu>
        <BaseDialog
          fullWidth
          maxWidth={'md'}
          titleMessageId='modifyUser.modifyButton'
          open={openModal}
          onClose={handleModalModifyUser}>
          <SettingsUserInfo
            handleModalModifyUser={handleModalModifyUser}
            handleModalChangePassword={handleModalChangePassword}
          />
        </BaseDialog>
        <BaseDialog
          titleMessageId='modifyUser.changePasswordButton'
          open={openModalPassword}
          onClose={handleModalChangePassword}>
          <ChangePassword
            handleModalChangePassword={handleModalChangePassword}
          />
        </BaseDialog>
      </Box>
      <InformationDialog
        tabSelected={InfoDialogChoice.NONE}
        handleModal={handleModalInfo}
        open={openInfo}
        showQuestionMark={false}
      />
    </>
  );
};

export default UserHeaderButton;
