import {put, takeEvery, all, call} from "@redux-saga/core/effects";
import {LOAD_PRODUCT_LISTING} from "../actions/ProductsActions";
import productsActions from "../actions/ProductsActions";
import Product from "../models/Product";
import rsf from "shared/services/ReduxSagaFirebase";
import Price from "../models/Price";
import * as FirebaseFirestore from '@firebase/firestore-types';
import {STRIPE_METADATA_} from "modules/subscriptions/configs/constant";
import {TerranisService} from "shared/models/TerranisService";
import log from "shared/services/LogService";
import {STRIPE_METADATA_MAX_QUANTITY, STRIPE_METADATA_MIN_QUANTITY, STRIPE_METADATA_REF, STRIPE_METADATA_SERVICE} from "../configs/constant";


function extractMetadata(data: Price | Product) {

    const refMetadata = data[`${STRIPE_METADATA_}${STRIPE_METADATA_REF}`];
    if (refMetadata) {
        data.ref = refMetadata;
    }

    let maxQuantity: number | null = null;
    const maxQuantityMetadata = data[`${STRIPE_METADATA_}${STRIPE_METADATA_MAX_QUANTITY}`];
    if (maxQuantityMetadata) {
        maxQuantity = parseInt(maxQuantityMetadata);
        if (!isNaN(maxQuantity)) {
            data.maxQuantity = maxQuantity;
        }
    }
    let minQuantity: number | null = null;
    const minQuantityMetadata = data[`${STRIPE_METADATA_}${STRIPE_METADATA_MIN_QUANTITY}`];
    if (minQuantityMetadata) {
        minQuantity = parseInt(minQuantityMetadata);
        if (!isNaN(minQuantity)) {
            data.minQuantity = minQuantity;
        }
    }
}

function* loadProducts() {
    try {

        //TODO: type productsSnapsdshot
        const productsSnapShots: FirebaseFirestore.QuerySnapshot = yield call(rsf.firestore.getCollection, 'products')

        let products: {[key: string]: Product} = {};
        productsSnapShots.forEach((productData) => {
            const product = productData.data() as Product;
            product.id = productData.id;
            if (product[`${STRIPE_METADATA_}${STRIPE_METADATA_SERVICE}`] === TerranisService.WAGO && product.active) {

                extractMetadata(product);
                products = {
                    ...products,
                    [productData.id]: product
                }
            }
        })

        //  get the prices for each Product and add them to productState

        const productsIdList = Object.keys(products);
        const pricesSnaphots: FirebaseFirestore.QuerySnapshot[] = yield all(productsIdList.map((productId) => {
            return call(rsf.firestore.getCollection, `products/${productId}/prices`)
        }))
        pricesSnaphots.forEach((pricesSnapshot, index) => {
            const productId = productsIdList[index];
            const product = products[productId];
            const prices: Price[] = [];
            product.prices = prices;
            pricesSnapshot.forEach((priceDoc) => {
                const price = priceDoc.data() as Price;
                price.id = priceDoc.id
                extractMetadata(price);
                if (price.active) {
                    prices.push(price);
                }
            });
        })

        yield put(productsActions.loadProductListingSuccess(products))

    } catch (error) {
        log.error(error);
    }
}

export function* productsSagas() {
    yield all([
        takeEvery(LOAD_PRODUCT_LISTING, loadProducts),
    ])
}