import React from 'react';
import TextField from '@material-ui/core/TextField';
import {useField} from 'formik';

/**
 * Formik textfield wrapper
 * @param props
 */
const MyTextField = (props: any) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <TextField
            {...props}
            {...field}
            helperText={errorText}
            error={!!errorText}
        />
    );
};
export default MyTextField;