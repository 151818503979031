import {ModifyTresholdModel} from 'modules/auth/models/ModifyTresholdModel';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'shared/store';
import * as yup from 'yup';
import useStyles from 'shared/components/layout/HeaderButton.style';
import {useIntl} from 'react-intl';
import {Form, Formik} from 'formik';
import clsx from 'clsx';
import MyTextField from 'shared/components/MyTextField';
import {Box, Button} from '@material-ui/core';
import IntlMessages from 'shared/components/IntlMessages';
import {actions} from '../../actions/AuthActions';




export default function ModifyTresholds() {

    const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
    const {messages} = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch()
    const [changeInfo, setChangeInfo] = useState<boolean>(true);

    const handleChangeInfo = () => {
        setChangeInfo(false);
    };

    let threshold_high: number = 0;
    let threshold_medium: number = 0;

    if (user && user.threshold_high && user.threshold_medium) {
        threshold_high = user.threshold_high;
        threshold_medium = user.threshold_medium;
    }
    const userThreshold: ModifyTresholdModel = {
        threshold_high: threshold_high,
        threshold_medium: threshold_medium,
    }
    const validationSchema = yup.object({
        threshold_high: yup
            .number()
            .max(100, messages['form.validation.lowerThan100'] as string)
            .positive(messages['form.validation.positiveNumber'] as string)
            .typeError(messages['form.validation.number'] as string)
            .required(messages['form.validation.other'] as string),
        threshold_medium: yup
            .number()
            .max(100, messages['form.validation.lowerThan100'] as string)
            .positive(messages['form.validation.positiveNumber'] as string)
            .typeError(messages['form.validation.number'] as string)
            .required(messages['form.validation.other'] as string),
    });

    return (
        <Box className={classes.userForm}>
            <Formik
                initialValues={userThreshold}
                validationSchema={validationSchema}
                onSubmit={(data) => {
                    dispatch(actions.modifyUserTreshold(data))
                    setChangeInfo(true)
                }}
            >
                <Form className={classes.fullWidth} autoComplete='off'>
                    <Box className={classes.textFormContainer}>
                        <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                            <MyTextField
                                className={classes.fullWidth}
                                disabled={changeInfo}
                                placeholder={userThreshold.threshold_high.toString()}
                                label={<IntlMessages id='modifyUser.thresholdHigh' />}
                                name='threshold_high'
                                variant='outlined'
                            />
                        </Box>
                        <Box className={clsx(classes.boxesTextForm, classes.fullWidth)}>
                            <MyTextField
                                className={classes.fullWidth}
                                disabled={changeInfo}
                                placeholder={userThreshold.threshold_medium.toString()}
                                label={<IntlMessages id='modifyUser.thresholdMedium' />}
                                name='threshold_medium'
                                variant='outlined'
                            />
                        </Box>
                    </Box>
                    <Box
                        flexDirection='column'
                        alignItems='center'
                        display={changeInfo ? 'none' : 'flex'}>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            className={classes.formUserButton}>
                            <IntlMessages id='modifyUser.submitButton' />
                        </Button>
                    </Box>

                </Form>
            </Formik>
            <Box flexDirection='row' alignItems='center' display={!changeInfo ? 'none' : 'flex'}>
                <Button
                    variant='contained'
                    color='primary'
                    type='button'
                    className={classes.formUserButton}
                    onClick={() => handleChangeInfo()}>
                    <IntlMessages id='modifyUser.modifyButton' />
                </Button>
            </Box>
        </Box>
    )
}