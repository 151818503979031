import {all} from 'redux-saga/effects';
import {authSagas} from '../../modules/auth/sagas/AuthSagas';
import {soilsSagas} from '../../modules/soils/sagas/SoilsSagas';
import {fieldsSagas} from '../../modules/fields/sagas/FieldsSagas';
import {cropsSagas} from "../../modules/crops/sagas/CropsSagas";
import {waterBalanceSagas} from "../../modules/waterBalance/sagas/WaterBalanceSagas";
import {sysIrrigationSagas} from '../../modules/sysIrrigation/sagas/SysIrrigationSagas';
import {campaignsSagas} from '../../modules/campaigns/sagas/CampaignsSagas';
import {rotationSagas} from '../../modules/rotations/sagas/RotationSagas';
import {productsSagas} from 'modules/products/saga/ProductsSaga';
import {subscriptionsSaga} from 'modules/subscriptions/saga/SubscriptionsSaga';

export function* rootSagas() {
  yield all([
    authSagas(),
    fieldsSagas(),
    soilsSagas(),
    cropsSagas(),
    waterBalanceSagas(),
    sysIrrigationSagas(),
    campaignsSagas(),
    rotationSagas(),
    productsSagas(),
    subscriptionsSaga(),
  ]);
}
