import React from 'react';
import {indexPath} from './configs/CropsRoutePaths';

export const cropsRouteConfigs = [
  {
    auth: ['user'],
    routes: [
      {
        path: indexPath,
        component: React.lazy(() => import('./components/Crops')),
      },
    ],
  },
];
