import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import {getTerranisSharedApiEndpoint} from 'shared/configs/FirebaseConfig';
import {Log} from 'shared/models/Log';
import {auth} from 'shared/services/ReduxSagaFirebase';

const getReportLogsEndpoint = (): string => {
    return `${getTerranisSharedApiEndpoint()}/logs/batch`;
}

// Send the error logs to our backend
const customJSON = (log: any): Log => {
    //console.info(`Formatting log: ${log.message}`);
    const formattedLog: any = {
        application: "wago-front",
        level: log.level.label.toLowerCase(),
        message: log.message,
    };
    return formattedLog;
};

export const setRemoteLogsConfig = function (token: string) {
    try {
        log.debug("setRemoteLogsConfig");
        // Only send errors
        remote.apply(log, {
            url: getReportLogsEndpoint(),
            method: 'POST',
            onUnauthorized: async (failedToken: string) => {
                log.info(`Token for remote logs has probably expired`);
                const tokenResult = await auth.currentUser?.getIdTokenResult();
                if (tokenResult) {
                    log.info(`New one retrieved: ${tokenResult.token}`);
                    remote.setToken(tokenResult.token);
                }
            },
            level: 'error',
            token: token,
            capacity: 1,
            format: customJSON
        });
    } catch (error) {
        //log.error("setRemoteLogsConfig error", error);
        // Can crash if called several time
        // Generally in debug mode
    }
}

// LOg by default to info & upper in production
log.setLevel("info")

export default log;