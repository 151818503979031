import CropCode from "../models/CropCode";
import CropModel from "../models/CropModel";

export const getCropTranslationKey = (crop: CropModel): string | null => {
    return getCropTranslationKeyFromCode(crop.crop_code);
}

export const getCropTranslationKeyFromCode = (cropCode?: CropCode): string | null => {
    if (cropCode) {
        return `crops.${cropCode.toLowerCase()}.label`;
    } else {
        return null;
    }
}