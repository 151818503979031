import React from 'react';
import SpaIcon from '@material-ui/icons/Spa';
import {indexPath} from '../configs/CropsRoutePaths';
import HeaderButton from 'shared/components/layout/HeaderButton';
import useCheckPermissions from 'modules/auth/hooks/useCheckPermissions';

interface CropsHeaderButtonProps {
  alwaysShowTitle?: boolean;
}

const CropsHeaderButton: React.FC<CropsHeaderButtonProps> = (props) => {

  const allowed = useCheckPermissions("crops.read");
  if (allowed) {
    return (
      <>
        <HeaderButton path={indexPath} titleId="crops.headerButton.title" icon={<SpaIcon />} alwaysShowTitle={props.alwaysShowTitle} />
      </>
    );
  }
  return null;
};

export default CropsHeaderButton;
