import Settings from './Setting';
import Common from './Common';
import AuthReducers from '../../modules/auth/reducers/AuthReducers';
import FieldsReducers from '../../modules/fields/reducers/FieldsReducers'
import WaterBalanceReducers from '../../modules/waterBalance/reducers/WaterBalanceReducers'
import SoilsReducers from '../../modules/soils/reducers/SoilsReducers';
import CropsReducers from '../../modules/crops/reducers/CropsReducers'
import SysIrrigationReducers from '../../modules/sysIrrigation/reducers/SysIrrigationReducers';
import CampaignsReducers from '../../modules/campaigns/reducers/CampaignsReducers';
import RotationsReducers from '../../modules/rotations/reducers/RotationsReducers';
import ProductsReducers from 'modules/products/reducers/ProductsReducers';
import SubscriptionsReducers from 'modules/subscriptions/reducers/SubscriptionsReducers';

const reducers = {
  settings: Settings,
  auth: AuthReducers,
  fields: FieldsReducers,
  common: Common,
  crops: CropsReducers,
  soils: SoilsReducers,
  waterBalance: WaterBalanceReducers,
  sysIrrigation: SysIrrigationReducers,
  campaigns: CampaignsReducers,
  rotations: RotationsReducers,
  products: ProductsReducers,
  subscriptions: SubscriptionsReducers
};

export default reducers;
