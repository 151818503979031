import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {AppState} from 'shared/store';
import WaterBalanceModel from '../models/WaterBalanceModel';
import WaterBalanceState from '../models/WaterBalanceState';
import {actions} from '../actions/WaterBalanceActions';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface NdviSelectorProps { }

/**
 * Select component in order to be able to select date of ndvi map 
 * Use the redux state waterBalance.selectedNdviDate
 * @param props 
 */
const NdviSelector: React.FC<NdviSelectorProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();

    const {data, selectedNdviDate}: WaterBalanceState = useSelector<
        AppState,
        AppState['waterBalance']
    >(({waterBalance}) => waterBalance);

    let ndviData: WaterBalanceModel[] = [];
    if (data) {
        ndviData = data.ndvi as WaterBalanceModel[];
    }

    const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
        // Dispatch action
        dispatch(actions.setSelectedNdviDate(event.target.value as string));
    };

    const label: string = intl.formatMessage({id: "waterBalance.ndviSelector.label"});

    return (
        <div>
            <FormControl variant="standard" className={classes.formControl}>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={selectedNdviDate ? selectedNdviDate : ""}
                    onChange={handleChange}
                    label={label}
                >
                    {ndviData ? ndviData.map((waterBalanceModel, index, array) => {
                        return (
                            <MenuItem value={waterBalanceModel.wb_date} key={index}>{waterBalanceModel.wb_date}</MenuItem>
                        )
                    }) : null}
                </Select>
            </FormControl>
        </div >
    );
}

export default NdviSelector;
