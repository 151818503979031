import {CropType} from '../models/CropType';

export interface CropIconConfig {

    /**
     * Match localized keywords (key is a 2 letter iso code) to define the crop icon
     */
    localizedKeywords: {[key: string]: string[];}
}

/**
   * A map of all the crop icon configs.
   */
export const defaultCropIconsConfig = new Map<CropType, CropIconConfig>();
defaultCropIconsConfig.set(CropType.CORN, {
    localizedKeywords: {
        "fr": ["mais", "maïs"],
        "en": ["corn"],
        "es": ["maíz", "choclo"],
    }
});
defaultCropIconsConfig.set(CropType.VINE, {
    localizedKeywords: {
        "fr": ["vigne"],
        "en": ["vine"],
        "es": ["viña", "vid"],
    }
});
defaultCropIconsConfig.set(CropType.WHEAT, {
    localizedKeywords: {
        "fr": ["blé", "ble"],
        "en": ["wheat"],
        "es": ["trigo"],
    }
});
defaultCropIconsConfig.set(CropType.BARLEY, {
    localizedKeywords: {
        "fr": ["orge"],
        "en": ["barley"],
        "es": ["cebada", "alcacer"],
    }
});
defaultCropIconsConfig.set(CropType.LUCERNE, {
    localizedKeywords: {
        "fr": ["luzerne"],
        "en": ["lucerne", "alfalfa"],
        "es": ["alfalfa"],
    }
});
defaultCropIconsConfig.set(CropType.SUNFLOWER, {
    localizedKeywords: {
        "fr": ["tournesol"],
        "en": ["sunflower"],
        "es": ["girasol", "mirasol"],
    }
});
defaultCropIconsConfig.set(CropType.SOY, {
    localizedKeywords: {
        "fr": ["soja"],
        "en": ["soy"],
        "es": ["soja", "soya"],
    }
});
defaultCropIconsConfig.set(CropType.RAPE, {
    localizedKeywords: {
        "fr": ["colza"],
        "en": ["rape", "canola"],
        "es": ["colza"]
    }
});
defaultCropIconsConfig.set(CropType.TOMATO, {
    localizedKeywords: {
        "fr": ["tomate"],
        "en": ["tomato"],
        "es": ["tomate"]
    }
});

export default function getCropFromNameMatch(name: string): CropType | null {

    let foundCropType: CropType | null = null;
    if (name) {
        const lowerCaseName = name.toLocaleLowerCase();
        defaultCropIconsConfig.forEach((value, key) => {
            if (value.localizedKeywords) {
                for (let lang in value.localizedKeywords) {
                    const keywords = value.localizedKeywords[lang];
                    for (let iKeyword = 0; iKeyword < keywords.length; iKeyword++) {
                        const keyword = keywords[iKeyword];
                        if (lowerCaseName.includes(keyword)) {
                            //console.log(`${key} crop found for ${name}`);
                            foundCropType = key;
                            break;
                        }
                    }

                    if (foundCropType) {
                        break;
                    }
                }
            }

            if (foundCropType) {
                return;
            }
        });
    }
    return foundCropType;
}