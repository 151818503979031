import {makeStyles} from "@material-ui/core";
import {CremaTheme} from "shared/models/AppContextPropsType";

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    filtersContainer: {
      margin: '1em'
    },

    listItem: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    listItemIcon: {
      color: theme.palette.primary.main,
      minWidth: 40
    },
    listItemIconSelected: {
      color: theme.palette.primary.contrastText,
      minWidth: 40
    },
    addFieldButton: {
      position: 'relative',
      bottom: 0,
      zIndex: 999,
      width: '100%',
    },
    select: {
      margin: '0.3em 0',
      width: '100%'
    },
    addFiltersButton: {
      width: '96%'
    },
    formControl: {
      display: 'block',
      width: '100%'
    }
  }
})

export default useStyles;
