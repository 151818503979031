import {Scrollbar} from '@crema';
import {Box, Checkbox, List, ListItem, ListItemText, MenuItem, TextField} from '@material-ui/core';
import {getCurrentCampaign} from 'modules/campaigns/components/CampaignsRedirect';
import Campaign from 'modules/campaigns/models/Campaign';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import IntlMessages from 'shared/components/IntlMessages';
import log from 'shared/services/LogService';
import {AppState} from 'shared/store';
import Field from '../../fields/models/Field';
import useFieldElementStyles from '../../fields/components/FieldElement.style';
import FieldsFilter from '../../fields/components/FieldsFilter';
import {SubscriptionsStatus} from '../models/SubscriptionStatus';
import ActiveSubscriptionIcon from './icons/ActiveSubscriptionIcon';
import {getCampaignTranslation} from 'modules/campaigns/services/CampaignsTranslation';

interface FieldSelectionTableProps {
    selectedFields: Field[],
    onSelectedFieldsChange: (field: Field[]) => void,
    selectedCampaign?: Campaign;
    onSelectedCampaignChange: (campaign: Campaign) => void;
    onDisplayedFieldsChange: (field: Field[]) => void,
}
export default function FieldSelectionTable({selectedFields, onSelectedFieldsChange, selectedCampaign, onSelectedCampaignChange, onDisplayedFieldsChange}: FieldSelectionTableProps) {

    const {data} = useSelector<AppState, AppState['campaigns']>(({campaigns}) => campaigns)
    const classes = useFieldElementStyles()
    const {data: fieldsData, crops, selectedFieldId, campaigns, selectedCampaign: selectedCampaignId} = useSelector<AppState, AppState['fields']>(({fields}) => fields)
    const [campaignAvailable, setCampaignAvailable] = useState<Campaign[]>()
    const [fieldsList, setFieldsList] = useState<Field[]>([])
    const [selectedCrop, setSelectedCrop] = useState<{[key: string]: string[]}>({
        crop_name: [],
        farmer: [],
    });
    const [displayedFieldsList, setDisplayedFieldsList] = useState<Field[]>([])
    const [noSubscriptionFieldsList, setNoSubscriptionFieldsList] = useState<Field[]>([])

    /**
     * Use to get list of fields map sort by campaign
     * @param campaignAvailable campaign Array
     * @param fields fieldsState.data
     * @returns Array of fields by campaign
     */
    const getFieldsList = (campaignAvailable: Campaign[], fields: {[key: string]: {[key: string]: Field}}) => {
        let fieldsList: Field[] = [];
        campaignAvailable.forEach((campaign: Campaign) => {
            if (fields.hasOwnProperty(campaign.campaign_id)) {
                const fieldsListInCampaign = Object.values(fields[campaign.campaign_id])
                fieldsList = fieldsList.concat(fieldsListInCampaign)
            }
        })
        return fieldsList
    }

    useEffect(() => {
        if (data && campaigns) {
            const userCampaigns = campaigns
            const campaignList: Campaign[] = []
            Object.values(data).forEach(campaign => {
                userCampaigns.forEach(userCampaign => {
                    if (userCampaign.campaign_id === campaign.campaign_id) {
                        campaignList.push(campaign)
                    }
                })
            })
            setCampaignAvailable(campaignList)
        }
    }, [data, campaigns])

    useEffect(() => {
        if (campaignAvailable && fieldsData) {
            let campaign: Campaign | undefined;
            if (selectedFieldId) {
                campaign = campaignAvailable.find(camp => camp.campaign_id === selectedCampaignId) as Campaign
            }
            setFieldsList(getFieldsList(campaignAvailable, fieldsData))
            const currentCampaignId = getCurrentCampaign(campaignAvailable)
            const currentCampaign = campaignAvailable.find(campaign => campaign.campaign_id === currentCampaignId)
            onSelectedCampaignChange(campaign ? campaign : currentCampaign ? currentCampaign : campaignAvailable[0])
        }
    }, [campaignAvailable, fieldsData, selectedCampaignId])

    useEffect(() => {
        if (selectedCampaignId && selectedFieldId && fieldsData) {
            let field: Field
            field = fieldsData[selectedCampaignId][selectedFieldId]
            if (field && (!field.last_subscription || field.last_subscription.status !== SubscriptionsStatus.ACTIVE)) {
                onSelectedFieldsChange([field])
            }
        }
    }, [selectedCampaignId, selectedFieldId, fieldsData])

    const filterFieldsByCampaign = (fields: Field[], selectedCampaign: Campaign): Field[] => {
        return fields.filter(field => field.campaign_id === selectedCampaign.campaign_id)
    }

    const filterFieldsByCrop = (fields: Field[], cropNameTab: string[]): Field[] => {
        return fields.filter((field: Field) => cropNameTab.includes(field.crop_name))
    }

    const fieldListItem = (field: Field, index: number) => {
        const handleFieldSelected = () => {
            if (!field.last_subscription || field.last_subscription.status !== SubscriptionsStatus.ACTIVE) {
                // field already selected
                log.debug(`Field selected change: ${field.field_id}`, selectedFields)
                if (selectedFields.filter(fieldSelected => fieldSelected.field_id === field.field_id).length) {
                    onSelectedFieldsChange(selectedFields.filter(fieldSelected => fieldSelected.field_id !== field.field_id))
                } else {
                    onSelectedFieldsChange(selectedFields.concat(field))
                }
            }
        }

        const isSelected = selectedFields ? selectedFields.findIndex(fieldSelected => field.field_id === fieldSelected.field_id) > -1 : false;
        if (isSelected) {
            log.debug(`Field ${field.field_id} selected: ${isSelected}`);
        }

        return (
            <ListItem
                key={field.rotation_id}
                button
                onClick={handleFieldSelected}
                className={index % 2 !== 0 ? classes.listItemSelectionBgNone : classes.listItemSelectionBgGrey}
            >
                {(field.last_subscription && field.last_subscription.status === SubscriptionsStatus.ACTIVE) ?
                    <Box mr={3}><ActiveSubscriptionIcon color="disabled" /></Box>
                    : <Checkbox className={classes.checkBox} checked={isSelected} />}

                <ListItemText primary={field.field_name} />
            </ListItem>
        )
    }

    useEffect(() => {
        let fieldArrayToDisplay = fieldsList;
        if (fieldArrayToDisplay) {
            log.debug(`All fields: ${fieldArrayToDisplay.length}`);
            if (selectedCampaign) {
                fieldArrayToDisplay = filterFieldsByCampaign(fieldArrayToDisplay, selectedCampaign);
                log.debug(`Fields for campaign ${selectedCampaign.campaign_name} (${selectedCampaign.campaign_id}): ${fieldArrayToDisplay.length}`);
            }

            if (selectedCrop && selectedCrop.crop_name.length) {
                fieldArrayToDisplay = filterFieldsByCrop(fieldArrayToDisplay, selectedCrop.crop_name);
                log.debug(`Fields for crops: ${fieldArrayToDisplay.length}`, selectedCrop.crop_name);
            }

            const noSubscriptionFieldsList = fieldArrayToDisplay.filter((field) => (!field.last_subscription || field.last_subscription.status !== SubscriptionsStatus.ACTIVE));

            log.debug(`setDisplayedFieldsList`);
            setNoSubscriptionFieldsList(noSubscriptionFieldsList);
            setDisplayedFieldsList(fieldArrayToDisplay);
            onDisplayedFieldsChange(fieldArrayToDisplay);
        }
    }, [fieldsList, selectedCampaign, selectedCrop])

    const listFields = () => {
        if (displayedFieldsList) {

            log.debug(`Displayed Fields List: ${displayedFieldsList.length}`);
            const selectedFieldsIds = selectedFields.map((field) => field.field_id);
            const noSubscriptionFieldsIds = noSubscriptionFieldsList.map((field) => field.field_id);
            let allSelected = true;
            noSubscriptionFieldsIds.forEach((fieldId) => {
                if (!selectedFieldsIds.includes(fieldId)) {
                    allSelected = false;
                }
            })

            const selectAll = () => {
                if (!allSelected) {
                    const newSelectedFields = [...selectedFields];
                    noSubscriptionFieldsList.forEach((field) => {
                        if (newSelectedFields.findIndex((selectedField) => field.field_id === selectedField.field_id) === -1) {
                            newSelectedFields.push(field);
                        }
                    })
                    onSelectedFieldsChange(newSelectedFields)
                } else {
                    const newSelectedFields = [...selectedFields];
                    noSubscriptionFieldsList.forEach((field) => {
                        const foundFieldIndex = newSelectedFields.findIndex((selectedField) => field.field_id === selectedField.field_id);
                        if (foundFieldIndex > -1) {
                            newSelectedFields.splice(foundFieldIndex, 1);
                        }
                    })
                    onSelectedFieldsChange(newSelectedFields)
                }
            }

            return (
                <List>
                    <ListItem
                        button
                        onClick={selectAll}
                        className={classes.listItemSelectionBgNone}
                    >
                        <Checkbox className={classes.checkBox} checked={allSelected} />
                        <ListItemText>
                            <IntlMessages id={"subscriptions.stepper.step_one.table.selectAll"} />
                        </ListItemText>
                    </ListItem>
                    <Box>
                        {displayedFieldsList?.map((field: Field, index: number) => {
                            return fieldListItem(field, index)
                        })}
                    </Box>
                </List>
            )
        } else {
            log.debug(`No fields to display found`)
            return null
        }
    }

    if (campaignAvailable && fieldsList && selectedCampaign) {
        return (
            <Box className={classes.tabFieldSelection}>
                <Box>
                    <Box className={classes.tabFieldSelectionHeader}>
                        <IntlMessages id={"subscriptions.stepper.step_one.table.title"} />
                    </Box>
                    <Box className={classes.tabFieldSelector}>
                        <TextField
                            select
                            value={selectedCampaign.campaign_id}
                            defaultValue={0}
                            onChange={(event) => {
                                const campaign = campaignAvailable.find((campaign) => campaign.campaign_id === parseInt(event.target.value))
                                if (campaign) {
                                    onSelectedCampaignChange(campaign)
                                } else {
                                    log.error(`No match found with campaign ${event.target.value}`)
                                }
                            }}>
                            {campaignAvailable
                                ?.sort((a, b) => a.campaign_id - b.campaign_id)
                                .map((element) => {
                                    return (
                                        <MenuItem key={element.campaign_id} value={element.campaign_id}>
                                            {getCampaignTranslation(element)}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                        <Box width={'100%'}>
                            <FieldsFilter
                                selectedFilters={selectedCrop}
                                allFilters={crops[selectedCampaign.campaign_id as number]}
                                propertyKey='crop_name'
                                onFilterChange={setSelectedCrop}
                                title='fields.crops'
                            />
                        </Box>
                    </Box>
                </Box>
                <Box overflow='auto'>
                    <Scrollbar>
                        {/* show selected filters fields */}
                        {listFields()}
                    </Scrollbar>
                </Box>
            </Box>


        )
    }
    return null
}