import React from 'react';
import useStyles from './Informations.style';
import {Box, Typography} from '@material-ui/core';
import ArrowFieldsDetails1 from 'shared/components/Icons/ArrowFieldsDetails1';
import {SvgIconProps} from '@material-ui/core';
import IntlMessages from '@crema/utility/IntlMessages';

interface FieldsDetailsProps extends SvgIconProps { }

/**
 * Component for showing FieldsDetails in the information dialog
 * Contains an image and indication for the user to understand the application
 */
const FieldsDetails: React.FC<FieldsDetailsProps> = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        className={classes.fieldsDetailsContainer}>
        <Typography variant='h4' className={classes.dialogSubtitle}>
          <IntlMessages id='informations.fieldsDetails.title' />
        </Typography>
        <Box className={classes.boxTextFieldsDetails1}>
          <Typography variant='body1' className={classes.textBold}>
            <IntlMessages id='informations.fieldsDetails.text1' />
          </Typography>
        </Box>
        <Box width={1} className={classes.fieldsDetailsImg}>
          <img
            src={require('assets/images/dialogInfoImages/fieldsDetails.jpg')}
            alt='site-overview'
          />
        </Box>
        <Box className={classes.boxTextFieldsDetails2}>
          <Typography variant='body1' className={classes.textBold}>
            <IntlMessages id='informations.fieldsDetails.text2' />
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            className={classes.textBold}>
            <IntlMessages id='fields.selectedFieldOverviewTab.title' />
          </Typography>
          <Typography variant='body2'>
            <IntlMessages id='informations.fieldsDetails.summaryText' />
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            className={classes.textBold}>
            <IntlMessages id='tabs.vegetation' />
          </Typography>
          <Typography variant='body2'>
            <IntlMessages id='informations.fieldsDetails.vegetationText' />
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            className={classes.textBold}>
            <IntlMessages id='tabs.irrigation' />
          </Typography>
          <Typography variant='body2'>
            <IntlMessages id='informations.fieldsDetails.irrigationText' />
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            className={classes.textBold}>
            <IntlMessages id='tabs.weather' />
          </Typography>
          <Typography variant='body2'>
            <IntlMessages id='informations.fieldsDetails.weatherText' />
          </Typography>
          <Typography
            variant='body1'
            color='primary'
            className={classes.textBold}>
            <IntlMessages id='fields.selectedFieldParametersTab.title' />
          </Typography>
          <Typography variant='body2'>
            <IntlMessages id='informations.fieldsDetails.settingsText' />
          </Typography>
        </Box>
      </Box>
      <Box className={classes.arrowFieldsDetails1}>
        <ArrowFieldsDetails1
          color='primary'
          fontSize='large'
          classes={{fontSizeLarge: classes.svgArrowLarge}}
        />
      </Box>
    </>
  );
};

export default FieldsDetails;
