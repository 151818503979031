import React, {FunctionComponent, useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import IntlMessages from '../../../shared/components/IntlMessages';
import useStyles from './FieldsChoice.style';
import {Button, Box} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';

//type for props
interface FieldsFiltersProps {
  /**
   * array of all filters possible values for all properties
   */
  allFilters: string[];

  /**
   * set state for filters if one is selected
   */
  onFilterChange: (newFilter: {[key: string]: string[]}) => void;

  /**
   * The property this component allow to filter
   */
  propertyKey: string;
  title: string;

  /**
   * Currently selected filter values  for all properties
   */
  selectedFilters: {
    [key: string]: string[];
  };
  /**
   * Select properties
   */
  selectProps?: {
    variant?: "filled" | "outlined" | "standard" | undefined
  };

  /**
   * Get the display name using the val
   */
  getDisplayName?: (val: string) => string;
}

/**
 * component for both filters, displays the filters selected
 */
const FieldsFilter: FunctionComponent<FieldsFiltersProps> = ({getDisplayName, allFilters, onFilterChange, title, propertyKey, selectedFilters, selectProps}) => {
  const classes = useStyles();
  const [selectOpen, setSelectOpen] = useState<boolean>(false)

  //put chosen filter into corresponding state array
  const handleChange = (event: React.ChangeEvent<{name?: any, value: any}>) => {
    onFilterChange({...selectedFilters, [propertyKey]: event.target.value as string[]})
  };

  // Currently selected values for the property
  let filterValues: string[] = [];
  if (selectedFilters && propertyKey) {
    filterValues = selectedFilters[propertyKey];
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>
          <IntlMessages id={title} />
        </InputLabel>
        <Select
          className={classes.select}
          multiple
          value={filterValues ? filterValues : []}
          name={propertyKey}
          open={selectOpen}
          onOpen={() => setSelectOpen(!selectOpen)}
          onChange={handleChange}
          onClose={() => setSelectOpen(!selectOpen)}
          renderValue={(selectedValues: any) => {
            if (getDisplayName) {
              const selectedNames: string[] = [];
              selectedValues.forEach((selectedVal: any) => {
                selectedNames.push(getDisplayName(selectedVal));
              })
              return selectedNames.join(', ')
            } else {
              return selectedValues.join(', ')
            }
          }}
          variant={selectProps && selectProps.variant ? selectProps.variant : 'standard'}
        >
          {allFilters && allFilters.map((element: string, index: number) => {
            return (
              <MenuItem key={index} value={element}>
                <Checkbox checked={filterValues ? filterValues.indexOf(element) > -1 : false} />
                <ListItemText primary={getDisplayName ? getDisplayName(element) : element} />
              </MenuItem>
            )
          })}
          <Box display='flex' justifyContent='center'>
            <Button variant='contained' color='primary' onClick={() => {setSelectOpen(!selectOpen);}}><FormattedMessage id="fields.filter.close_button" /></Button>
          </Box>
        </Select>
      </FormControl>
    </>
  );
};

export default FieldsFilter;