import Customer from "../models/Customer";
import {ModifyUserInfoModel} from "../models/ModifyUserInfoModel";

/**
 * get all user data from database firebase and return updatable data in on single map
 * @param customer 
 * @returns 
 */
export const getUserInfo = (customer: Customer) => {

    /**
     * init and affect values for personal information
     */
    let lastName: string = '';
    let firstName: string = '';
    let phone: string = '';
    let email: string = '';

    if (
        customer &&
        customer.lastname &&
        customer.firstname &&
        customer.phone &&
        customer.email
    ) {
        lastName = customer.lastname;
        firstName = customer.firstname;
        email = customer.email;
        phone = customer.phone;
    }

    /**
     * init and affect values for organism information
     */

    let organism: string = ''
    let taxNumber: string = ''
    let postal_code: string = ''
    let address: string = ''
    let city: string = ''
    let country: string = '';

    if (
        customer &&
        customer.organism
    ) {
        organism = customer.organism
    }
    if (
        customer &&
        customer.address
    ) {
        if (customer.address.line1) {
            address = customer.address.line1
        }
        if (customer.address.city) {
            city = customer.address.city
        }
        if (customer.address.postal_code) {
            postal_code = customer.address.postal_code
        }
        if (customer.address.country) {
            country = customer.address.country
        }
    }

    if (customer && customer.tax_id && customer.tax_id.value) {
        taxNumber = customer.tax_id.value
    }

    const userInfo: ModifyUserInfoModel = {
        lastname: lastName,
        firstname: firstName,
        email: email,
        phone: phone,
        organism: organism,
        taxNumber: taxNumber,
        postal_code: postal_code,
        address: address,
        city: city,
        country: country,
    };

    return userInfo

}
