import enLang from './entries/en-US';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';

const AppLocale: any = {
  "en-US": enLang,
  "es-ES": esLang,
  "fr-FR": frLang,
};

export default AppLocale;
