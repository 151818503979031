import {call, put, takeEvery, all} from 'redux-saga/effects';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../../../shared/actions/Common';
import {LOAD_SYS_IRRIGATION} from '../actions/SysIrrigationActions';
import SysIrrigation from '../models/SysIrrigation';
import api, {wagoApiUrl} from '../../../shared/services/ApiConfig';
import actions from '../actions/SysIrrigationActions';

function getSysIrrigationsRequest() {
  const sysIrrigationUrl = `${wagoApiUrl}/sys_irrigation`;
  return api.get(sysIrrigationUrl);
}

function* loadSysIrrigationsListing() {
  try {
    yield put(fetchStart("loadSysIrrigationsListing"));
    const res = yield call(getSysIrrigationsRequest);
    const sysIrrigationListing = res.data as SysIrrigation[];
    const sysIrrigationMap: {[key: string]: SysIrrigation} = {};
    sysIrrigationListing.forEach((value) => {
      if (value.sys_irrigation_id !== undefined) sysIrrigationMap[value.sys_irrigation_id] = value
    });
    yield put(actions.loadSysIrrigationSucces(sysIrrigationMap));
    yield put(fetchSuccess("loadSysIrrigationsListing"));
  } catch (error) {
    yield put(fetchError("loadSysIrrigationsListing", error.message));
  }
}

export function* sysIrrigationSagas() {
  yield all([
    takeEvery(LOAD_SYS_IRRIGATION, loadSysIrrigationsListing),
  ])
}