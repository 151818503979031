import {Box} from '@material-ui/core';
import Field from 'modules/fields/models/Field';
import {computeTotalAreaHa} from 'modules/fields/services/FieldsService';
import React from 'react';
import log from 'shared/services/LogService';
import Product from '../models/Product';
import {computeLowerPrice} from '../services/computedLowerPrice';
import OfferCard, {OfferCardType} from './OfferCard';
import useStyles from './ProductStyle';

interface ProductSelectionProps {
    selectedFields: Field[];
    products: {[key: string]: Product};
    selectedProduct?: Product,
    setSelectedProduct: any;
}
export default function ProductSelectionStep({selectedFields, selectedProduct, setSelectedProduct, products}: ProductSelectionProps) {

    const classes = useStyles();
    const productListSorted = (products: {[key: string]: Product}): Product[] => {
        const productsList = Object.values(products);
        productsList.sort((a, b) => {
            if (!a.prices || !a.prices.length) {
                log.error(`Product ${a.id} does not have any price`);
                return -1;
            }
            if (!b.prices || !b.prices.length) {
                log.error(`Product ${b.id} does not have any price`);
                return 1;
            }
            return computeLowerPrice(a, totalArea) - computeLowerPrice(b, totalArea)
        })
        return productsList
    }
    const totalArea = computeTotalAreaHa(selectedFields)

    const productList = (products: {[key: string]: Product}) => {
        return (
            <Box className={classes.productCardContainer}>
                {productListSorted(products).map((product: Product, index: number) => (
                    <OfferCard key={index} type={OfferCardType.PRODUCT}
                        item={product} selected={product.id === selectedProduct?.id}
                        setSelected={setSelectedProduct}
                        totalArea={totalArea} />
                ))}
            </Box>
        )
    }

    return (
        <Box className={classes.productStep}>
            {productList(products)}
        </Box>
    )
}

