import {Reducer} from 'redux';
import {AuthActionTypes, LOAD_ALL_USERS_SUCCESS, LOAD_ROLES_CONFIG_SUCCESS, MODIFY_USER_NOTIFICATION, SAVE_PREFERRED_LANGUAGE, SET_TOKEN_SET, SET_USER_ROLE_SUCCESS, SIGNOUT_USER_SUCCESS, UPDATE_AUTH_USER} from '../actions/AuthActions';
import {AuthState} from '../models/AuthState';

const INIT_STATE: AuthState = {
  user: null,
  customer: null,
  token: null,
  tokenExpiration: null,
  rolesConfig: null,
  allCustomers: null,
};

const authReducers: Reducer<AuthState, AuthActionTypes> = (state: AuthState = INIT_STATE, action: AuthActionTypes) => {
  switch (action.type) {
    case MODIFY_USER_NOTIFICATION: {
      if (state.user !== null) {
        return {
          ...state,
          user: {
            ...state.user,
            alert_sms: action.payload.alert_sms,
            alert_mail: action.payload.alert_mail,
          }
        }
      } else {
        return state
      }
    }
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload.user,
        customer: action.payload.customer,
      };
    }

    case LOAD_ROLES_CONFIG_SUCCESS: {
      return {
        ...state,
        rolesConfig: action.payload,
      };
    }

    case LOAD_ALL_USERS_SUCCESS: {
      return {
        ...state,
        allCustomers: action.payload,
      };
    }

    case SET_USER_ROLE_SUCCESS: {
      const allUsers = {...state.allCustomers};
      allUsers[action.payload.id] = action.payload;
      return {
        ...state,
        allCustomers: allUsers,
      };
    }

    case SAVE_PREFERRED_LANGUAGE: {
      if (state.customer) {
        return {
          ...state,
          customer: {
            ...state.customer,
            preferred_locales: [action.payload]
          },
        };
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        user: null,
        token: null,
        tokenExpiration: null,
      };
    }
    case SET_TOKEN_SET: {

      if (action.payload) {
        return {
          ...state,
          token: action.payload.token,
          tokenExpiration: action.payload.tokenExpiration,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default authReducers;
