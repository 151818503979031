import React from 'react';
import {SvgIcon, SvgIconProps} from '@material-ui/core';
import {ReactComponent as ArrowOverview} from '../../../assets/images/icons/arrowOverviewMd1.svg';

const ArrowOverview1 = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      component={ArrowOverview}
      viewBox='0 0 92 52'
    />
  );
};

export default ArrowOverview1;
