/**
 * Type/family of crop (used to display icon)
 */
export enum CropType {
    CORN = "corn",
    VINE = "vine",
    WHEAT = "wheat",
    BARLEY = "barley",
    LUCERNE = "lucerne",
    SUNFLOWER = "sunflower",
    SOY = "soy",
    RAPE = "rape",
    TOMATO = "tomato",
}