import React from 'react';
import useStyles from './Informations.style';
import {Box, Typography} from '@material-ui/core';
import ArrowCropsIcon from 'shared/components/Icons/ArrowCrops';
import ArrowSoilsIcon from 'shared/components/Icons/ArrowSoils';
import {SvgIconProps} from '@material-ui/core';
import IntlMessages from '@crema/utility/IntlMessages';

interface SoilsAndCropsProps extends SvgIconProps { }


/**
 * Component for showing SoilsAndCrops in the information dialog
 * Contains two images and indication for the user to understand the application
 */
const SoilsAndCrops: React.FC<SoilsAndCropsProps> = () => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      className={classes.soilsAndCropsContainer}>
      <Typography variant='h4' className={classes.dialogSubtitle}>
        <IntlMessages id='informations.soilsAndCrops.title' />
      </Typography>
      <Box className={classes.soilsAndCropsImgContainer}>
        <Box className={classes.soilsAndCropsImg}>
          <img
            src={require('assets/images/dialogInfoImages/crops.jpg')}
            alt='crops'
          />
        </Box>
        <Box className={classes.soilsAndCropsTextBox1}>
          <Box className={classes.rotateArrow180}>
            <ArrowCropsIcon
              color='primary'
              fontSize='large'
              classes={{fontSizeLarge: classes.svgArrowMedium}}
            />
          </Box>
          <Typography variant='body2' className={classes.textBold}>
            <IntlMessages id='informations.soilsAndCrops.crops' />
          </Typography>
        </Box>
        <Box className={classes.soilsAndCropsTextBox2}>
          <Typography variant='body2' className={classes.textBold}>
            <IntlMessages id='informations.soilsAndCrops.soils' />
          </Typography>
          <Box>
            <ArrowSoilsIcon
              color='primary'
              fontSize='large'
              classes={{fontSizeLarge: classes.svgArrowMedium}}
            />
          </Box>
        </Box>

        <Box className={classes.soilsAndCropsImg}>
          <img
            src={require('assets/images/dialogInfoImages/soils.jpg')}
            alt='soils'
          />
        </Box>
        <Box className={classes.cropsDetails}>
          <Typography variant='body2' className={classes.textBold}>
            <IntlMessages id='informations.soilsAndCrops.cropsDetails1' />
          </Typography>
          <Typography variant='body2'>
            <IntlMessages id='informations.soilsAndCrops.cropsDetails2' />
          </Typography>
          <Box className={classes.arrowCropsDetails}>
            <ArrowCropsIcon
              color='primary'
              fontSize='large'
              classes={{fontSizeLarge: classes.svgArrowMedium}}
            />
          </Box>
        </Box>
        <Box className={classes.soilsDetails}>
          <Box className={classes.arrowSoilsDetails}>
            <ArrowSoilsIcon
              color='primary'
              fontSize='large'
              classes={{fontSizeLarge: classes.svgArrowMedium}}
            />
          </Box>
          <Box>
            <Typography variant='body2' display='inline' className={classes.textBold}>
              <IntlMessages id='informations.soilsAndCrops.soilsDetails.title1' />
            </Typography>
            <Typography variant='body2' display='inline'>
              <IntlMessages id='informations.soilsAndCrops.soilsDetails.content1' />
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' display='inline' className={classes.textBold}>
              <IntlMessages id='informations.soilsAndCrops.soilsDetails.title2' />
            </Typography>
            <Typography variant='body2' display='inline'>
              <IntlMessages id='informations.soilsAndCrops.soilsDetails.content2' />
            </Typography>
          </Box>
          <Box>
            <Typography variant='body2' display='inline' className={classes.textBold}>
              <IntlMessages id='informations.soilsAndCrops.soilsDetails.title3' />
            </Typography>
            <Typography variant='body2' display='inline'>
              <IntlMessages id='informations.soilsAndCrops.soilsDetails.content3' />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SoilsAndCrops;
