/* eslint-disable react-hooks/rules-of-hooks */
import SummaryFieldSelectionStep from 'modules/subscriptions/components/SummaryFieldSelectionStep';
import Field from 'modules/fields/models/Field';
import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import BaseDialog from 'shared/components/BaseDialog';
import {AppState} from 'shared/store';
import {QUERY_SUBSCRIPTION_DISPLAY} from '../configs/constant';
import {SubscriptionQuery} from '../models/SubscriptionQuery';
import {GetSubscriptionQuery} from '../services/getSubscriptionQuery';

export default function subscriptionFieldListDetail() {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const fieldsState = useSelector<AppState, AppState['fields']>(({fields}) => fields)
    const {data} = fieldsState;
    const history = useHistory();
    const subscriptionFieldList = GetSubscriptionQuery(SubscriptionQuery.FIELD_LIST)
    const fieldList = (tab: string | null): Field[] => {
        const fieldList: Field[] = [];
        if (data && tab) {
            const fieldIdsList = JSON.parse(tab);
            const campaignList = Object.values(data)
            fieldIdsList.forEach((id: string) => {
                const firstCampaign = campaignList.find(campaign => campaign.hasOwnProperty(id))
                if (firstCampaign) {
                    fieldList.push(firstCampaign[id as string])
                }
            })
        }
        return fieldList

    }

    const returnToSubscriptionTable = () => {
        history.push({pathname: history.location.pathname, search: QUERY_SUBSCRIPTION_DISPLAY})
        console.log('hello')
    }


    return (
        <BaseDialog fullWidth maxWidth={'md'} open={true} onClose={returnToSubscriptionTable} titleMessageId='subscriptions.susbscription_table.field_list.title'>
            <SummaryFieldSelectionStep selectedFields={fieldList(subscriptionFieldList)} />
        </BaseDialog>
    )


}