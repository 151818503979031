import {call, put, all, delay, takeEvery} from 'redux-saga/effects';
import {
  LOAD_WATER_BALANCE,
  LoadWaterBalanceAction,
  ADD_IRRIGATION,
  AddIrrigationAction,
  DELETE_IRRIGATION,
  DeleteIrrigationAction,
  MODIFY_IRRIGATION,
  ModifyIrrigationAction,
  ADD_RAIN,
  AddRainAction,
  DELETE_RAIN,
  DeleteRainAction,
  MODIFY_RAIN,
  ModifyRainAction,
} from '../actions/WaterBalanceActions';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../../../shared/actions/Common';
import api, {wagoApiUrl} from '../../../shared/services/ApiConfig';
import WaterBalanceModel from '../models/WaterBalanceModel';
import WaterBalanceSortedModel from '../models/WaterBalanceSortedModel';
import WaterBalanceState from '../models/WaterBalanceState';
import IrrigationDataModel from '../models/IrrigationDataModel';
import VegetationDataModel from '../models/VegetationDataModel';
import NewIrrigationModel from '../models/NewIrrigationModel';
import actions from '../actions/WaterBalanceActions';
import DeleteDataModel from '../models/DeleteDataModel';
import ModifyDataModel from '../models/ModifyDataModel';
import NewRainModel from '../models/NewRainModel';
import {timeoutDuration} from 'shared/configs/AppConst';
import {getWaterBalanceRoutePath} from '../configs/WaterBalanceRoutePath';

function getWaterBalanceRequest(fieldId: number, rotationId: number, forceCompute?: boolean) {
  // const waterBalanceUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${rotationId}/water_balance`;
  const waterBalanceUrl = wagoApiUrl + getWaterBalanceRoutePath(fieldId, rotationId, forceCompute);
  if (forceCompute) {
    return api.post(waterBalanceUrl)
  } else {
    return api.get(waterBalanceUrl);
  }
}

function* loadWaterBalance(data: LoadWaterBalanceAction) {
  try {
    const fieldId = data.payload.fieldId;
    const rotationId = data.payload.rotationId;
    const forceCompute = data.payload.forceCompute
    yield put(fetchStart("loadWaterBalance"));
    const res = yield call(getWaterBalanceRequest, fieldId, rotationId, forceCompute);
    const rawResult = res.data as WaterBalanceModel[];
    const waterBalance = {} as WaterBalanceSortedModel;
    if (rawResult) {
      rawResult.map((x) => {
        if (!waterBalance.hasOwnProperty(x.wb_type)) {
          waterBalance[x.wb_type] = [];
          waterBalance[x.wb_type].push(x as WaterBalanceModel);
          return waterBalance[x.wb_type];
        }
        return waterBalance[x.wb_type].push(x as WaterBalanceModel);
      });
    }

    //create array for vegetation chart's data
    const vegetationDataChart = [] as VegetationDataModel[];

    //create array for irrigation datas' charts
    const irrigationDataChart = [] as IrrigationDataModel[];

    // function to fill the vegetation data chart array
    function fillArrayChartVegetation(
      key: string,
      dataArray: VegetationDataModel[],
    ) {
      waterBalance[key].filter((value) => {
        const index = dataArray.findIndex((x) => x.wb_date === value.wb_date);
        if (index === -1) {
          dataArray.push({
            wb_date: value.wb_date,
            wb_dateEpoch: new Date(value.wb_date).getTime(),
            [`wb_value_${key}`]: parseFloat(value.wb_value),
          } as VegetationDataModel);
        } else {
          dataArray[index][`wb_value_${key}`] = parseFloat(value.wb_value);
        }
        return dataArray;
      });
    }

    // function to fill the irrigation data chart array
    function fillArrayChartIrrigation(
      key: string,
      dataArray: IrrigationDataModel[],
    ) {
      waterBalance[key].filter((value) => {
        const index = dataArray.findIndex(
          (x) =>
            x.wb_dateEpoch ===
            Date.parse(new Date(value.wb_date).toUTCString()),
        );
        if (index === -1) {
          dataArray.push({
            wb_dateEpoch: Date.parse(new Date(value.wb_date).toUTCString()),
            [`wb_value_${key}`]: parseFloat(value.wb_value),
          } as IrrigationDataModel);
        } else {
          dataArray[index][`wb_value_${key}`] = parseFloat(value.wb_value);
        }
        return dataArray;
      });
    }

    //fill the vegetation's chart array
    waterBalance.ndvi && fillArrayChartVegetation('ndvi', vegetationDataChart);
    waterBalance.GDD && fillArrayChartVegetation('GDD', vegetationDataChart);

    //fill the irrigation's chart array
    waterBalance.ETO && fillArrayChartIrrigation('ETO', irrigationDataChart);
    waterBalance.ET && fillArrayChartIrrigation('ET', irrigationDataChart);
    waterBalance.irrigation &&
      fillArrayChartIrrigation('irrigation', irrigationDataChart);
    waterBalance.pluie &&
      fillArrayChartIrrigation('pluie', irrigationDataChart);
    waterBalance.SWC1 && fillArrayChartIrrigation('SWC1', irrigationDataChart);
    waterBalance.SWC2 && fillArrayChartIrrigation('SWC2', irrigationDataChart);
    waterBalance.TAW && fillArrayChartIrrigation('TAW', irrigationDataChart);
    waterBalance.RAW && fillArrayChartIrrigation('RAW', irrigationDataChart);
    waterBalance.DE && fillArrayChartIrrigation('DE', irrigationDataChart);

    // Sort the tables per date DESC
    for (const waterBalanceProp in waterBalance) {
      const waterBalancePropArray = waterBalance[
        waterBalanceProp
      ] as WaterBalanceModel[];
      if (waterBalancePropArray) {
        waterBalancePropArray.sort().reverse();
      }
    }

    let selectedNdviDate: string | null = null;
    if (waterBalance.ndvi && waterBalance.ndvi.length > 0) {
      selectedNdviDate = waterBalance.ndvi[0].wb_date;
    }

    const waterBalanceState: WaterBalanceState = {
      data: waterBalance,
      selectedNdviDate: selectedNdviDate,
      vegetationDataChart: vegetationDataChart,
      irrigationDataChart: tresholdRawAndTaw(irrigationDataChart),
    };

    yield put(actions.loadWaterBalanceSuccessAction(waterBalanceState));
    yield put(fetchSuccess("loadWaterBalance"));
  } catch (error) {
    yield put(fetchError("loadWaterBalance", error.message));
  }
}

function addNewIrrigationRequest(
  dataToSend: NewIrrigationModel,
  fieldId: number,
  fieldRotation: number,
) {
  const irrigationUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/irrigate`;
  return api.post(irrigationUrl, dataToSend);
}

function* addNewIrrigation(action: AddIrrigationAction) {
  try {
    yield put(fetchStart("addNewIrrigation"));
    const data = action.payload;
    const dataToSend: NewIrrigationModel = {
      irrigation_date: data.date as string,
      dose_mm: data.dose_mm,
    };
    const fieldId: number = data.fieldId as number;
    const fieldRotation: number = data.fieldRotation as number;
    yield call(addNewIrrigationRequest, dataToSend, fieldId, fieldRotation);
    yield put(fetchSuccess("addNewIrrigation"));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
  } catch (error) {
    yield put(fetchError("addNewIrrigation", error.message));
  }
}

function deleteIrrigationRequest(selectedIrrigation: DeleteDataModel) {
  const {date, fieldId, fieldRotation} = selectedIrrigation;
  const irrigationUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/irrigate/${date}`;
  return api.delete(irrigationUrl);
}

function* deleteIrrigation(action: DeleteIrrigationAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart("deleteIrrigation"));
    yield call(deleteIrrigationRequest, action.payload);
    yield put(fetchSuccess("deleteIrrigation"));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
  } catch (error) {
    yield put(fetchError("deleteIrrigation", error.message));
  }
}

function modifyIrrigationRequest(modifiedIrrigation: ModifyDataModel) {
  const {date, dose_mm, fieldId, fieldRotation} = modifiedIrrigation;
  const dataToSend = {
    dose_mm,
    irrigation_date: date,
  };
  const irrigationUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/irrigate`;
  return api.put(irrigationUrl, dataToSend);
}

function* modifyIrrigation(action: ModifyIrrigationAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart("modifyIrrigation"));
    yield call(modifyIrrigationRequest, action.payload);
    yield put(fetchSuccess("modifyIrrigation"));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
  } catch (error) {
    yield put(fetchError("modifyIrrigation", error.message));
  }
}

function addNewRainRequest(
  dataToSend: NewRainModel,
  fieldId: number,
  fieldRotation: number,
) {
  const rainUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/rain`;
  return api.post(rainUrl, dataToSend);
}

function* addNewRain(action: AddRainAction) {
  try {
    yield put(fetchStart("addNewRain"));
    const data = action.payload;
    const dataToSend: NewRainModel = {
      rain_date: data.date as string,
      dose_mm: data.dose_mm,
    };
    const fieldId: number = data.fieldId as number;
    const fieldRotation: number = data.fieldRotation as number;
    yield call(addNewRainRequest, dataToSend, fieldId, fieldRotation);
    yield put(fetchSuccess("addNewRain"));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
  } catch (error) {
    yield put(fetchError("addNewRain", error.message));
  }
}

function deleteRainRequest(selectedRain: DeleteDataModel) {
  const {date, fieldId, fieldRotation} = selectedRain;
  const rainUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/rain/${date}`;
  return api.delete(rainUrl);
}

function* deleteRain(action: DeleteRainAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart("deleteRain"));
    yield call(deleteRainRequest, action.payload);
    yield put(fetchSuccess("deleteRain"));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
  } catch (error) {
    yield put(fetchError("deleteRain", error.message));
  }
}

function modifyRainRequest(modifiedRain: ModifyDataModel) {
  const {date, dose_mm, fieldId, fieldRotation} = modifiedRain;
  const dataToSend = {
    dose_mm,
    rain_date: date,
  };
  const rainUrl = `${wagoApiUrl}/fields/${fieldId}/rotations/${fieldRotation}/rain`;
  return api.put(rainUrl, dataToSend);
}

function* modifyRain(action: ModifyRainAction) {
  try {
    const {fieldId, fieldRotation} = action.payload;

    yield put(fetchStart("modifyRain"));
    yield call(modifyRainRequest, action.payload);
    yield put(fetchSuccess("modifyRain"));

    yield call(reloadWaterBalanceAfterDelay, 1, fieldId, fieldRotation);
  } catch (error) {
    yield put(fetchError("modifyRain", error.message));
  }
}

function* reloadWaterBalanceAfterDelay(
  delayMultiplier: number,
  fieldId: number,
  rotationId: number,
) {
  // Wait for some times
  yield delay(timeoutDuration * delayMultiplier);

  // Reload waterBalance
  yield put(actions.loadWaterBalance(fieldId, rotationId));
}

export function* waterBalanceSagas() {
  yield all([
    takeEvery(LOAD_WATER_BALANCE, loadWaterBalance),
    takeEvery(ADD_IRRIGATION, addNewIrrigation),
    takeEvery(DELETE_IRRIGATION, deleteIrrigation),
    takeEvery(MODIFY_IRRIGATION, modifyIrrigation),
    takeEvery(ADD_RAIN, addNewRain),
    takeEvery(DELETE_RAIN, deleteRain),
    takeEvery(MODIFY_RAIN, modifyRain),
  ]);
}
/**
 * Add to each item of irrigationDataChart range numbers when DE is over TAW or RAW (use to draw area in chart)
 * ex: treshold_raw : [value of RAW, value of DE] as number[]
 * @param irrigationDataChart 
 * @returns 
 */
function tresholdRawAndTaw(irrigationDataChart: IrrigationDataModel[]) {
  irrigationDataChart.forEach((data: IrrigationDataModel) => {

    if (data.wb_value_DE && data.wb_value_RAW && data.wb_value_DE >= data.wb_value_RAW) {
      data.treshold_raw = [data.wb_value_RAW, data.wb_value_DE] as number[]
    } else { // use to extend aera until intersection
      data.treshold_raw = [data.wb_value_DE, data.wb_value_DE] as number[]
    }
    if (data.wb_value_DE && data.wb_value_TAW && data.wb_value_DE >= data.wb_value_TAW) {
      data.treshold_taw = [data.wb_value_TAW, data.wb_value_DE] as number[]
    } else { // use to extend aera until intersection
      data.treshold_taw = [data.wb_value_DE, data.wb_value_DE] as number[]
    }

  })
  return irrigationDataChart
}
